<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.title}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" mat-dialog-close></ng-icon>
    </div>
    <div class="modal-body">
        <div class="flex" *ngIf="data.dataClass == dataClasses.Designs || data.dataClass == dataClasses.Displays || data.dataClass == dataClasses.Data">
            <input pInputText class="form-control w-full" [(ngModel)]="data.data.name">
        </div>

        <div *ngIf="data.dataClass == dataClasses.Data">
            <!-- <mat-form-field appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="data.data.selected_class.name">
                    <mat-option *ngFor="let type of dataClassType" [value]="type.name">
                        {{type.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <div *ngIf="data.dataClass == dataClasses.Firmware">
            <div style="display: flex;">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="data.data.status">
                        <mat-option [value]="0">None</mat-option>
                        <mat-option [value]="1">Beta</mat-option>
                        <mat-option [value]="2">Live</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <textarea style="height: 20em;" class="form-control" [(ngModel)]="data.data.post_script" placeholder="Post script"></textarea>
            </div>
            <div>
                <textarea style="height: 20em;" class="form-control" [(ngModel)]="data.data.script" placeholder="Script"></textarea>
            </div>
            <div class="flex">
                <a class="btn" href="{{data.data.firmware_url}}">Download Firmware</a>
            </div>
        </div>
    </div>
    <div class="actions p-3">
        <p-button label="Cancel" [outlined]="true" mat-dialog-close />
        <p-button label="Save"(click)="onSave()"/>
    </div>
</div>