import { Clone } from "../clone/clone";
import { Equals } from "../equals/equals";
// map pending function calls, function => params[]
class ListNode {
  constructor(value) {
    this.value = value;
    this.next = null;
  }
}
class ArgsLinkedList {
  constructor(pending) {
    this.head = null;
    this.head = new ListNode(pending);
  }
  findInList(args) {
    let node = this.head;
    while (node) {
      if (Equals(node.value.args, args)) {
        return node.value;
      }
      node = node.next;
    }
    return null;
  }
  addToList(pending) {
    const newNode = new ListNode(pending);
    newNode.next = this.head;
    this.head = newNode;
  }
  deleteFromList(args) {
    let prevNode = null;
    let node = this.head;
    while (node) {
      if (Equals(node.value.args, args)) {
        if (prevNode) {
          prevNode.next = node.next;
          return {
            itemRemoved: true,
            listIsEmpty: false
          };
        } else if (!node.next) {
          this.head = null;
          return {
            itemRemoved: true,
            listIsEmpty: true
          };
        } else {
          this.head = node.next;
          return {
            itemRemoved: true,
            listIsEmpty: false
          };
        }
      }
      prevNode = node;
      node = node.next;
    }
    return {
      itemRemoved: false,
      listIsEmpty: false
    };
  }
}
class PendingCallsContainer {
  constructor() {
    this.funcMap = new Map();
    this.size = 0;
  }
  findPendingItem(func, params) {
    const argList = this.funcMap.get(func);
    if (!argList) {
      return void 0;
    }
    const pendingArgs = argList.findInList(params);
    if (!pendingArgs) {
      return void 0;
    }
    return {
      func,
      ...pendingArgs
    };
  }
  addPendingItem(func, args, promise) {
    if (!this.funcMap.has(func)) {
      this.funcMap.set(func, new ArgsLinkedList({
        args,
        promise
      }));
    } else {
      this.funcMap.get(func).addToList({
        args,
        promise
      });
    }
    this.size++;
    if (this.size > 1000) {
      console.log("DEBUG issue");
    }
  }
  removePendingItem(func, args) {
    const funcPending = this.funcMap.get(func);
    if (!funcPending) {
      return;
    }
    const {
      itemRemoved,
      listIsEmpty
    } = funcPending.deleteFromList(args);
    if (itemRemoved) {
      this.size--;
    }
    if (listIsEmpty) {
      this.funcMap.delete(func);
    }
  }
}
const pendingCollection = new PendingCallsContainer();
export const CollectPendingInvocations = func => {
  const decorated = function (...params) {
    try {
      //@ts-ignore
      const me = this;
      const clonedParams = Clone(params);
      const pendingItem = pendingCollection.findPendingItem(func, params);
      if (pendingItem) {
        return pendingItem.promise;
      } else {
        const promise = func.apply(me, clonedParams);
        pendingCollection.addPendingItem(func, clonedParams, promise);
        promise.catch(() => {}).finally(() => {
          pendingCollection.removePendingItem(func, clonedParams);
        });
        return promise;
      }
    } catch (err) {
      console.error(`Error caught at CollectPendingInvocations`, err);
      return Promise.reject(err);
    }
  };
  return decorated;
};