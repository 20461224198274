import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { ApiService } from 'src/app/api/api.service';
import { Composite } from 'src/app/api/models/account/display/composite';
import { Playlist } from 'src/app/api/models/account/display/playlist';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-animation-editor',
  templateUrl: './animation-editor.component.html',
  styleUrl: './animation-editor.component.scss'
})
export class AnimationEditorComponent {
  animations: any[] = [
    'auto', 
    'none', 
    'fadeIn',
    'fadeOut', 
    'slide-down',
    'slide-up',
    'slide-left',
    'slide-right',
  ];

  animationIn: string = 'auto';
  animationDurationIn: number = 500;
  animationCurveIn: string = 'fastOutSlowIn';
  animationOut: string = 'auto';
  animationDurationOut: number = 500;
  animationCurveOut: string = 'fastOutSlowIn';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public editorService: EditorService, private apiService: ApiService, private authService: AuthService, private dialog: MatDialog, private messageService: MessageService) { 
    if(this.data.playlist && this.data.playlist.meta && this.data.playlist.meta.animation) {
      this.animationIn = this.data.playlist.meta.animation.in || 'auto';
      this.animationDurationIn = this.data.playlist.meta.animation.inDuration || 500;
      this.animationOut = this.data.playlist.meta.animation.out || 'auto';
      this.animationDurationOut = this.data.playlist.meta.animation.outDuration || 500;
      this.animationCurveIn = this.data.playlist.meta.animation.curveIn || 'fastOutSlowIn';
      this.animationCurveOut = this.data.playlist.meta.animation.curveOut || 'fastOutSlowIn';
    }
  }

  save() {
    if(this.animationDurationIn < 0) { 
      this.animationDurationIn = 100;
    }
    if(this.animationDurationOut < 0) { 
      this.animationDurationOut = 100;
    }
    if(this.animationDurationIn > 5000) { 
      this.animationDurationIn = 5000;
    }
    if(this.animationDurationOut > 5000) { 
      this.animationDurationOut = 5000;
    }
    this.data.playlist.meta = typeof this.data.playlist.meta == 'object' && !Array.isArray(this.data.playlist.meta) ? this.data.playlist.meta : {};
    this.data.playlist.meta.animation = {
      in: this.animationIn,
      inDuration: this.animationDurationIn,
      curveIn: this.animationCurveIn,
      out: this.animationOut,
      outDuration: this.animationDurationOut,
      curveOut: this.animationCurveOut
    };
    this.apiService.updatePlaylist(this.authService.selectedAccountId, this.data.playlist).subscribe((playlist: Playlist) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
      this.dialog.closeAll();
    });
  }
}
