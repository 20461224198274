<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Device info</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="tab-index mb-3">
            <div class="tab-item" (click)="selectedTab = 'info'" [class.tab-item-selected]="selectedTab == 'info'">Info</div>
            <div class="tab-item" (click)="selectedTab = 'settings'" [class.tab-item-selected]="selectedTab == 'settings'">Settings</div>
            <div class="tab-item" (click)="selectedTab = 'playlists'" [class.tab-item-selected]="selectedTab == 'playlists'">Playlists</div>
            <div class="tab-item" (click)="selectedTab = 'stats'" [class.tab-item-selected]="selectedTab == 'stats'">Online stats</div>
            <div class="tab-item" (click)="selectedTab = 'sensor'" [class.tab-item-selected]="selectedTab == 'sensor'">Monitoring data</div>
        </div>
        <div *ngIf="selectedTab == 'sensor' && !gotSensorData" class="graph-container">
            <div>No sensor data</div>
        </div>
        <div *ngIf="selectedTab == 'sensor' && gotSensorData" class="graph-container">
            <div class="stats-button-container" style="position: absolute;">
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'cpu_temp'" (click)="selectedSensor = 'cpu_temp'">CPU temp</button>
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'cpu_usage'" (click)="selectedSensor = 'cpu_usage'">CPU usage</button>
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'memory_available'" (click)="selectedSensor = 'memory_available'">RAM usage</button>
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'internet_speed'" (click)="selectedSensor = 'internet_speed'">Internet speed</button>
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'network_speed'" (click)="selectedSensor = 'network_speed'">Local network speed</button>
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'battery_percentage'" (click)="selectedSensor = 'battery_percentage'">Battery usage</button>
                <button class="stats-button btn" [class.btn-secondary]="selectedSensor == 'device_uptime'" (click)="selectedSensor = 'device_uptime'">Device uptime</button>
            </div>
            <div style="height: 5em;"></div>
            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'cpu_temp'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.cpu_temp]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true" [xAxisLabel]="'Date and Time'"
                [yAxisLabel]="'Temp'" [yScaleMin]="0" [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>

            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'cpu_usage'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.cpu_usage]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true" [xAxisLabel]="'Date and Time'"
                [yAxisLabel]="'Usage'" [yScaleMin]="0" [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>

            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'memory_available'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.memory_available]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true"
                [xAxisLabel]="'Date and Time'" [yAxisLabel]="'Usage %'" [yScaleMax]="100" [yScaleMin]="0" [yAxis]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>

            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'internet_speed'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.internet_speed]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true"
                [xAxisLabel]="'Date and Time'" [yAxisLabel]="'Mbps'" [yScaleMin]="0" [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>

            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'network_speed'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.network_speed]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true" [xAxisLabel]="'Date and Time'"
                [yAxisLabel]="'Mbps'" [yScaleMin]="0" [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>

            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'battery_percentage'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.battery_percentage]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true"
                [xAxisLabel]="'Date and Time'" [yAxisLabel]="'Percentage %'" [yScaleMax]="100" [yScaleMin]="0" [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>

            <ngx-charts-area-chart class="graph-sensor" *ngIf="selectedSensor == 'device_uptime'" [curve]="theCurve" [view]="[2000, 420]" [results]="[sensorData.device_uptime]" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true" [xAxisLabel]="'Date and Time'"
                [yAxisLabel]="'Uptime minutes'" [yScaleMin]="0" [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>
        </div>
        <div *ngIf="selectedTab == 'stats' && gotStats" class="graph-container">
            <div class="tab-index mb-3">
                <div class="tab-item" (click)="showOnlineStats(1)" [class.tab-item-selected]="selectedDays == 1">1 Day</div>
                <div class="tab-item" (click)="showOnlineStats(2)" [class.tab-item-selected]="selectedDays == 2">2 days</div>
                <div class="tab-item" (click)="showOnlineStats(3)" [class.tab-item-selected]="selectedDays == 3">3 days</div>
                <div class="tab-item" (click)="showOnlineStats(7)" [class.tab-item-selected]="selectedDays == 7">1 week</div>
            </div>
            <ngx-charts-area-chart [curve]="theCurve" [view]="[2000, 420]" [results]="chartData" [xAxis]="true" [yAxis]="true" [gradient]="false" [legend]="false" [timeline]="true" [xAxisLabel]="'Date and Time'" [yAxisLabel]="'Online Status'" [yScaleMin]="0" [yScaleMax]="1"
                [yAxis]="true" [autoScale]="true" [gradient]="false" [showXAxisLabel]="true" [showYAxisLabel]="true">
            </ngx-charts-area-chart>
        </div>
        <div *ngIf="selectedTab == 'stats' && !gotStats" class="graph-container">
            <div>No stats</div>
        </div>
        <table class="table float-left" style="vertical-align: middle;" *ngIf="selectedTab == 'settings'">
            <tbody>
                <tr *ngIf="display.device.info.version && display.device.info.version != ''">
                    <td class="device-key">Settings password</td>
                    <td>
                        <div style="float: left;"><input type="number" class="input" [(ngModel)]="display.device.settings_password" pInputText /></div>
                    </td>
                </tr>
                <tr>
                    <td class="device-key">Oriantation</td>
                    <td>
                        <div style="float: left;">
                            <select style="width: 15em;" class="form-select" [(ngModel)]="display.device.orientation">
                                <option [value]="'landscape'">Landscape</option>
                                <option [value]="'landscapeFlipped'">Landscape Flipped</option>
                                <option [value]="'portrait'">Portrait</option>
                                <option [value]="'portraitFlipped'">Portrait Flipped</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="device-key">Quality</td>
                    <td>
                        <div style="float: left;">
                            <select style="width: 15em;" class="form-select" [(ngModel)]="display.device.low_spec_device">
                                <option [value]="true">Low</option>
                                <option [value]="false">High</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.type && display.device.info.type == 'android' && display.device.info.rooted">
                    <td class="device-key">Auto reboot</td>
                    <td>
                        <div style="float: left;">
                            <p-checkbox  [(ngModel)]="display.device.auto_reboot" [disabled]="!(display.device.info.type && display.device.info.type == 'android' && display.device.info.rooted)"[binary]="true" />
                        </div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.type && display.device.info.type == 'android' && display.device.info.rooted">
                    <td class="device-key">Auto reboot time</td>
                    <td>
                        <div style="float: left;">
                            <input pInputText style="width: 5em;" class="input" type="number" [(ngModel)]="display.device.auto_reboot_time" [disabled]="!(display.device.info.type && display.device.info.type == 'android' && display.device.info.rooted)">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="device-key">Monitoring</td>
                    <td>
                        <div style="float: left;">
                            <div class="tab-index">
                                <div class="tab-item" (click)="display.device.sensor_log_interval = 0" [class.tab-item-selected]="display.device.sensor_log_interval == 0">Off</div>
                                <div class="tab-item" (click)="display.device.sensor_log_interval = 300" [class.tab-item-selected]="display.device.sensor_log_interval == 300">5 min</div>
                                <div class="tab-item" (click)="display.device.sensor_log_interval = 600" [class.tab-item-selected]="display.device.sensor_log_interval == 600">10 min</div>
                                <div class="tab-item" (click)="display.device.sensor_log_interval = 900" [class.tab-item-selected]="display.device.sensor_log_interval == 900">15 min</div>
                                <div class="tab-item" (click)="display.device.sensor_log_interval = 1800" [class.tab-item-selected]="display.device.sensor_log_interval == 1800">30 min</div>
                                <div class="tab-item" (click)="display.device.sensor_log_interval = 3600" [class.tab-item-selected]="display.device.sensor_log_interval == 3600">1 hour</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div style="display:flex;gap:0.5em">
                            <p-button severity="warn" *ngIf="display.device.info.type && display.device.info.type == 'android' && display.device.info.rooted" label="Reboot device" [outlined]="true" (click)="rebootDevice()" />
                            <p-button severity="warn" label="Unlink device" [outlined]="true" (click)="unlinkDevice()" />
                        </div>
                    </td>
                </tr>
                <tr *ngIf="errorMessage">
                    <td colspan="2">
                        <div class="error-message">{{errorMessage}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table float-left" style="vertical-align: middle;" *ngIf="selectedTab == 'info'">
            <tbody>
                <!-- <tr>
                    <td class="device-key">ID</td>
                    <td><div style="float: left;">{{display.device.device_uuid}}</div></td>
                </tr> -->
                <tr>
                    <td class="device-key">Status</td>
                    <td>
                        <div style="float: left;">{{ isCheckingOnline ? 'Checking...' : (isOnline ? 'Online' : 'Offline') }}</div>
                    </td>
                </tr>
                <tr *ngIf="secondsOnline > 0">
                    <td class="device-key">Uptime</td>
                    <td>
                        <div style="float: left;">{{secondsToHumanReadable(secondsOnline)}}</div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.type && display.device.info.type != ''">
                    <td class="device-key">Device Type</td>
                    <td>
                        <div style="float: left;">{{display.device.info.type}}</div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.version && display.device.info.version != ''">
                    <td class="device-key">App version</td>
                    <td>
                        <div style="float: left;">{{display.device.info.version}}</div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.width">
                    <td class="device-key">Screen size</td>
                    <td>
                        <div style="float: left;">{{display.device.info.width}}x{{display.device.info.height}}</div>
                    </td>
                </tr>

                <tr *ngIf="display.device.info.type && display.device.info.type == 'android'">
                    <td class="device-key">Device rooted</td>
                    <td>
                        <div style="float: left;">{{display.device.info.rooted ? 'Yes' : 'No'}}</div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.os && display.device.info.os != ''">
                    <td class="device-key">OS</td>
                    <td>
                        <div style="float: left; text-align: left;">{{display.device.info.os}}</div>
                    </td>
                </tr>
                <tr *ngIf="display.device.info.os_version && display.device.info.os_version != ''">
                    <td class="device-key">OS Version</td>
                    <td>
                        <div style="float: left;">{{display.device.info.os_version}}</div>
                    </td>
                </tr>
                <!-- <tr *ngIf="display.device.info.hostname && display.device.info.hostname != ''">
                    <td class="device-key">Hostname</td>
                    <td><div style="float: left;">{{display.device.info.hostname}}</div></td>
                </tr> -->
            </tbody>
        </table>
        <div *ngIf="selectedTab == 'playlists' && playlists.length == 0" class="graph-container flex">
            <div>No playlists</div>
        </div>
        <table class="table float-left" style="vertical-align: middle;" *ngIf="selectedTab == 'playlists' && playlists.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let playlist of playlists">
                    <td>{{playlist.name}}</td>
                    <td>
                        <div class="flex justify-end gap-3">
                            <button pButton [text]="true" [plain]="true" (click)="editPlaylist(playlist)">
                                <ng-icon name="saxEdit2Outline" size="20"></ng-icon>
                            </button>
                            <button pButton [text]="true" [plain]="true" (click)="removeFromPlaylist(playlist)">
                                <ng-icon name="saxTrashOutline" size="20"></ng-icon>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="actions p-3">
        <p-button label="Cancel" [outlined]="true" (click)="close()" />
        <p-button label="Save" (click)="saveDeviceInfo()" />
    </div>
</div>

<app-playlist-edit *ngIf="playlistSelected" [playlist]="selectedPlaylist" (onClose)="onPlaylistUpdated($event)"></app-playlist-edit>