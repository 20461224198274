import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { Playlist } from 'src/app/api/models/account/display/playlist';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrl: './playlist.component.scss'
})
export class PlaylistAccountComponent {
  playlist: Playlist = null;

  constructor(private apiService: ApiService,
    public authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.apiService.getPlaylist(this.authService.selectedAccountId, this.route.snapshot.params['playlistId']).subscribe((data: any) => {
      this.playlist = data;
    });
  }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/playlist/' + this.playlist.id;
  }
}