<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="10">
        {{node.item}}
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10">
        <div class="flex flex-col w-full folder-button" [class.folderactive]="selectedId == node.data.id">
            <div class="folder-item w-full">
                <button class="folder-item-more" pButton [text]="true" [plain]="true" [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                  <ng-icon *ngIf="!treeControl.isExpanded(node)"  name="saxArrowRight3Outline" size="15"></ng-icon>
                  <ng-icon *ngIf="treeControl.isExpanded(node)"  name="saxArrowDown1Outline" size="15"></ng-icon>
                </button>
                <ng-icon *ngIf="!treeControl.isExpanded(node)" name="saxFolderOutline" size="15"></ng-icon>
                <ng-icon *ngIf="treeControl.isExpanded(node)" name="saxFolderOpenOutline" size="15"></ng-icon>
                <button class="btn button-text" (click)="onSelected(node)">{{node.item}}</button>
                <div class="flex">
                  <ng-icon class="dir-edit" (click)="onOpenMenu($event, node)" *ngIf="editFolder && node.data.id != 0" style="transform: rotate(90deg);" name="saxMoreOutline" size="15"></ng-icon>
                </div>                
            </div>
            <p-progressbar *ngIf="node.isLoading" class="tree-progress-bar" mode="indeterminate" [style]="{ height: '6px' }" />
        </div>
    </mat-tree-node>
</mat-tree>
<p-popover class="popover-el" #folderdir (onHide)="onHideMenu($event)">
  <div class="popover-buttons flex flex-col">
      <button class="action-button" severity="secondary" (click)="onRenameFolder()" pButton ><ng-icon name="saxTextBlockOutline" size="20"></ng-icon>Rename</button>
      <button class="action-button" severity="secondary" (click)="onMoveFolder()" pButton ><ng-icon name="saxDocumentForwardOutline" size="20"></ng-icon>Move</button>
      <button class="action-button" severity="secondary" (click)="onDeleteFolder()" pButton ><ng-icon name="saxTrashOutline" size="20"></ng-icon>Delete</button>
  </div>
</p-popover>