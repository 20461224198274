import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { DataFolderComponent } from 'src/app/components/data-folder/data-folder.component';
import { Data } from 'src/app/api/models/account/data/data';
import { EditorService } from '../../services/editor.service';
import { LayerTypes } from '../../enum/layer-types';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-media-select',
  templateUrl: './media-select.component.html',
  styleUrls: ['./media-select.component.scss']
})
export class MediaSelectComponent {
  @ViewChild(DataFolderComponent) dataTableComponent!: DataFolderComponent;
  @Output() onCloseMedia = new EventEmitter<void>();
  @Output() onSelectMedia = new EventEmitter<Data>();
  @Input() dataType: string[] = [];
  @Input() isEditor: boolean = true;
  @Input() playlistId: number = 0;

  dataClass: DataClass = DataClass.Data;
  
  constructor(public editorService: EditorService, private apiService: ApiService, private authService: AuthService, private dialog: MatDialog, public commonService: CommonService) { }

  closeMedia() {
    this.onCloseMedia.emit();
  }

  onDataSelect(data: Data[]) {
    let selectedData = data[0];
    if(this.isEditor){
      if(this.editorService.overlay.settings && this.editorService.layersSelected){
        let dataLayer = this.editorService.formatWidget(this.editorService.selectedLayer.widget.childWidget);
          if(dataLayer.type == LayerTypes.playlist){
            for(let i = 0; i < data.length; i++){
              selectedData = data[i];
              if(selectedData.selected_class.name == 'Image'){
                dataLayer.data.items.push({
                  type: LayerTypes.image,
                  url: selectedData.meta.url,
                  data: JSON.stringify(selectedData.meta)
                });
              }else if(selectedData.selected_class.name == 'Video'){
                dataLayer.data.items.push({
                  type: LayerTypes.video,
                  url: selectedData.meta.url,
                  duration: selectedData.meta.duration,
                  data: JSON.stringify(selectedData.meta)
                });
              }
            }

            this.editorService.updatePlaylistItems(this.editorService.selectedLayer, dataLayer.data);
            this.closeMedia();
          }      
      }else{
        if(selectedData.selected_class.name == 'Image'){
          let name = selectedData.name.substring(0, (selectedData.name.length > 25 ? 25 : selectedData.name.length));
          this.editorService.createImageLayer(selectedData.meta.url, name);
          this.closeMedia();
        }

        if(selectedData.selected_class.name == 'Video'){
          let name = selectedData.name.substring(0, (selectedData.name.length > 25 ? 25 : selectedData.name.length));
          this.editorService.createVideoLayer(selectedData.meta.url, selectedData.meta.duration, name);
          this.closeMedia();
        }
      }
    } else {
      this.onSelectMedia.emit(selectedData);
    }

  }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/data';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'New Data',
          dataClass: DataClass.Data,
          data: {
            name: 'New Data '+Math.floor(Math.random() * 1000),
            selectedClassType: 'Folder',
            parentId: event.item.parentId
          },
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => { 
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete this Data?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          this.apiService.deleteData(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete all selected Data?'
        }
      }).afterClosed().subscribe(async (result: any) => {
        if (result) {
          for (let item of event.selected.selected) {
           await this.apiService.deleteData(this.authService.selectedAccountId, item.id).toPromise();
          }
          
          this.updateTable();
        }
      });
    }

    if(event.action == TableChangeDataActions.Edit){
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Data',
          dataClass: DataClass.Data,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}