import { HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/api/api.service';
import { DataClassType } from 'src/app/api/models/account/data/data-class';
import { weatherAutoComplete } from 'src/app/api/models/main/weatherAutoComplete';
import { DataClass } from 'src/app/enums/data-class';
import { NewItem } from 'src/app/models/new-item';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent {
  window: any = window;
  dataClasses = DataClass;
  dataClassType: DataClassType[] = [];
  hardwareTypes: any[] = [];

  selectedType: string = '';

  settings = {
    rss: {
      url: ''
    },
    webpage: {
      url: '',
      type: 'screenshot',
      allowedURL: '',
      allowedUrls: [
        '*'
      ],
      resetTimer: 120
    },
    weather: {
      location: ''
    },
  };

  weatherLocations: weatherAutoComplete[] = [];
  weatherLocationSearchField: any = '';
  weatherSearchTimeout: any = null;

  constructor(
    public dialogRef: MatDialogRef<NewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewItem,
    private apiService: ApiService,
    private authService: AuthService,
    private messageService: MessageService) {
    if (this.data.dataClass == DataClass.Firmware) {
      this.loadHardwareTypes();
    } else {
      this.getClassTypes();
    }
  }

  searchForLocations() {
    this.settings.weather.location = '';
    clearTimeout(this.weatherSearchTimeout);
    if (this.weatherLocationSearchField === '') {
      this.weatherLocations = [];
      return;
    }
    this.weatherSearchTimeout = setTimeout(() => {
      this.apiService.findFeedWeatherAutoComplete(this.weatherLocationSearchField).subscribe((data: weatherAutoComplete[]) => {
        this.weatherLocations = data;
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error getting weather locations', detail: error });
      });
    }, 500);
  }

  selectLocation(location: any) {
    this.settings.weather.location = location;
  }

  getClassTypes() {
    this.apiService.getClasses(this.authService.selectedAccountId).subscribe((data: DataClassType[]) => {
      this.dataClassType = data;
      //only have folder, feed and menu
      this.dataClassType = this.dataClassType.filter((item) => {
        return item.name == 'Folder' || item.name == 'Feed' || item.name == 'Table';
      });
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error getting class types', detail: error });
    });
  }

  addURL() {
    this.settings.webpage.allowedUrls.push(this.settings.webpage.allowedURL);
    this.settings.webpage.allowedURL = '';
  }

  removeURL(index: number) {
    this.settings.webpage.allowedUrls.splice(index, 1);
  }

  onSave() {
    if (this.data.dataClass == DataClass.Designs) {
      let width = 1920;
      let height = 1080;
      if (this.data.data.orientation == 'portrait') {
        width = 1080;
        height = 1920;
      }
      if (this.data.data.device == 'tablet') {
        width = 1280;
        height = 800;
        if (this.data.data.orientation == 'portrait') {
          width = 800;
          height = 1280;
        }
      }
      this.apiService.createDesign(this.authService.selectedAccountId, this.data.data.name, this.data.data.orientation, width, height).subscribe((data: any) => {
        this.dialogRef.close(true);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error creating design', detail: error });
      });
    }

    if (this.data.dataClass == DataClass.Displays) {
      this.apiService.createDisplay(this.authService.selectedAccountId, this.data.data.name).subscribe((data: any) => {
        this.dialogRef.close(true);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error creating display', detail: error });
      });
    }

    if (this.data.dataClass == DataClass.Data) {
      if (this.selectedType == 'rss') {
        if (!this.settings.rss.url.startsWith('http')) {
          this.messageService.add({ severity: 'error', summary: 'Please enter a valid URL', detail: '' });
          return;
        }
        this.apiService.createFeed(this.authService.selectedAccountId, this.data.data.name, 'rss', {
          url: this.settings.rss.url,
        }).subscribe((data: any) => {
          this.dialogRef.close(true);
        }, (error: any) => {
          this.messageService.add({ severity: 'error', summary: error, detail: error });
        });
      } else if (this.selectedType == 'website') {
        if (!this.settings.webpage.url.startsWith('http')) {
          this.messageService.add({ severity: 'error', summary: 'Please enter a valid URL', detail: '' });
          return;
        }
        if (this.settings.webpage.type == 'live') {
          this.apiService.createLiveWebview(this.authService.selectedAccountId, this.data.data.name, 'WebView', this.data.data.parentId, this.settings.webpage.url, this.settings.webpage.allowedUrls, this.settings.webpage.resetTimer).subscribe((data: any) => {
            this.dialogRef.close(true);
          }, (error: any) => {
            this.messageService.add({ severity: 'error', summary: error, detail: error });
          });
        } else {
          this.apiService.createFeed(this.authService.selectedAccountId, this.data.data.name, 'screenshot', {
            url: this.settings.webpage.url,
          }).subscribe((data: any) => {
            this.dialogRef.close(true);
          }, (error: any) => {
            this.messageService.add({ severity: 'error', summary: error, detail: error });
          });
        }
      } else if (this.selectedType == 'weather') {
        if (this.settings.weather.location === '') {
          this.messageService.add({ severity: 'error', summary: 'Please enter a location', detail: '' });
          return;
        }
        this.apiService.createFeed(this.authService.selectedAccountId, this.data.data.name, 'weather', {
          location: this.settings.weather.location,
        }).subscribe((data: any) => {
          this.dialogRef.close(true);
        }, (error: any) => {
          this.messageService.add({ severity: 'error', summary: error, detail: error });
        });
      } else {
        this.apiService.createData(this.authService.selectedAccountId, this.data.data.name, this.data.data.selectedClassType, this.data.data.parentId).subscribe((data: any) => {
          this.dialogRef.close(data);
        }, (error: any) => {
          this.messageService.add({ severity: 'error', summary: error, detail: error });
        });
      }
    }

    if (this.data.dataClass == DataClass.Playlists) {
      this.apiService.createPlaylist(this.authService.selectedAccountId, this.data.data.name).subscribe((data: any) => {
        this.dialogRef.close(data);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: error, detail: error });
      });
    }

    if (this.data.dataClass == DataClass.Composite) {
      this.apiService.createPlaylistComposite(this.authService.selectedAccountId, this.data.data.playlistId, this.data.data.name, this.data.data.order, 15).subscribe((data: any) => {
        this.dialogRef.close(true);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: error, detail: error });
      });
    }

    if (this.data.dataClass == DataClass.Firmware) {
      this.messageService.add({ severity: 'info', summary: 'Uploading firmware', detail: '' });
      this.apiService.createFirmware(this.authService.account.id,
        this.data.data.hardwareTypes,
        this.data.data.postScript,
        this.data.data.script,
        this.data.data.file).subscribe(
          event => {
            if (event.type === HttpEventType.Response) {
              this.dialogRef.close(true);
            } else {
              // this._snackBar.open('Uploading...', 'Close', {
              //   duration: 5000,
              // });
            }
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error uploading firmware', detail: error });
          });
    }

    if (this.data.dataClass == DataClass.Accounts) {
      this.apiService.partnerCreateAccount(this.authService.selectedPartnerId, this.data.data.name).subscribe((data: any) => {
        this.dialogRef.close(true);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error creating account', detail: error });
      });
    }
  }

  loadHardwareTypes() {
    this.apiService.listHardwareTypes(this.authService.selectedAccountId, {
      limit: 100,
      order: 'desc',
      orderField: 'id',
      query: '',
      start: 0
    }).subscribe((data: any) => {
      this.hardwareTypes = data.results;
    }, (error: any) => { });
  }

  onUplaodFile(event: any) {
    const file = event.target.files[0];
    this.data.data.file = file;
  }

  dataCreateFolder() {
    this.data.data.selectedClassType = 'Folder';
    this.onSave();
  }

  dataCreateTable() {
    this.data.data.selectedClassType = 'Table';
    this.onSave();
  }

  dataCreateFeed() {
    this.data.data.selectedClassType = 'Feed';
    this.onSave();
  }

  openUrl(url: string) {
    this.window.open(url, "_blank");
  }
}
