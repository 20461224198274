<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Partners</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="account-list">
            <table class="table">
                <tbody>
                    <tr *ngFor="let account of accounts">
                        <td class="align-middle">{{account.info.name}}</td>
                        <td>
                            <p-button class="float-right" label="Select" [outlined]="true" (click)="selectAccount(account)" />
                        </td>
                    </tr>
            </table>
        </div>
        <div>
            <!-- <button type="button" class="btn" (click)="openAccountCreate()">Create partner</button> -->
        </div>
    </div>
</div>