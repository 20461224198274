/**
 * Encpasulate a tree node which has:
 * : value
 * : 0-2 children / decendants
 */
export class AvlTreeNode {
  /**
   * Create a node with a nodeValue + count
   * We'll use count to allow our AVL tree
   * to have duplicate entries
   */
  constructor(allowDuplicates = true) {
    this.allowDuplicates = allowDuplicates;
    this._leftNode = null;
    this._rightNode = null;
    this._parentNode = null;
    this.height = 1;
    this.balance = 0;
    this.numDecendants = 0;
    //map of item to number of occurences
    this.nodeValues = new Map();
  }
  /**
   * Get any value we have for comparison purposes
   */
  getValueForComparison() {
    for (const [value, count] of this.nodeValues) {
      return value;
    }
    throw "No values, invalid state!";
  }
  addValue(value) {
    if (this.allowDuplicates || !this.nodeValues.has(value)) {
      this.nodeValues.set(value, 1 + (this.nodeValues.get(value) || 0));
    }
  }
  /**
   * Return true if the node is empty
   * @param value
   */
  removeValue(value) {
    const existingCount = this.nodeValues.get(value) || 1;
    if (existingCount === 1) {
      this.nodeValues.delete(value);
    } else {
      this.nodeValues.set(value, existingCount - 1);
    }
    return this.nodeValues.size === 0;
  }
  get numChildren() {
    return (this.leftNode === null ? 0 : 1) + (this.rightNode === null ? 0 : 1);
  }
  get leftNode() {
    return this._leftNode;
  }
  /**
   * Set the left node and recalculate heights
   */
  set leftNode(node) {
    this._leftNode = node;
    if (node) {
      node.parentNode = this;
    }
    this.resynchronizeChildren();
  }
  get rightNode() {
    return this._rightNode;
  }
  /**
   * Set the right node and recalculate heights
   */
  set rightNode(node) {
    this._rightNode = node;
    if (node) {
      node.parentNode = this;
    }
    this.resynchronizeChildren();
  }
  get parentNode() {
    return this._parentNode;
  }
  set parentNode(node) {
    this._parentNode = node;
  }
  /**
   * Update height and balance based on child assignments
   */
  resynchronizeChildren() {
    var _a, _b;
    const rightNodeHeight = ((_a = this._rightNode) === null || _a === void 0 ? void 0 : _a.height) || 0;
    const leftNodeHeight = ((_b = this._leftNode) === null || _b === void 0 ? void 0 : _b.height) || 0;
    this.balance = leftNodeHeight - rightNodeHeight;
    this.height = 1 + Math.max(leftNodeHeight, rightNodeHeight);
    const numDescLeft = this._leftNode ? 1 + this._leftNode.numDecendants : 0;
    const numDescRight = this._rightNode ? 1 + this._rightNode.numDecendants : 0;
    this.numDecendants = numDescLeft + numDescRight;
  }
  /**
   * If the input is a direct child of this node,
   * remove it and return true, false otherwise
   */
  removeChild(childNode) {
    let removedSide = 0;
    if (this.leftNode === childNode) {
      this.leftNode = null;
      removedSide = -1;
    } else if (this.rightNode === childNode) {
      this.rightNode = null;
      removedSide = 1;
    }
    return removedSide;
  }
  /**
   * Clone the node,
   * optionally provide a new value
   */
  clone(newValues) {
    const newNode = new AvlTreeNode(this.allowDuplicates);
    if (newValues) {
      newNode.nodeValues = newValues;
    }
    newNode.leftNode = this.leftNode;
    newNode.rightNode = this.rightNode;
    newNode.resynchronizeChildren();
    return newNode;
  }
}