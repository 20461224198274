<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Connect device</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div *ngIf="!connected" class="modal-body flex flex-col items-center gap-4">
        <div class="info-device-code">
            Please enter the code displayed on the device
        </div>
        <div class="flex">
            <p-inputotp [(ngModel)]="code" [length]="9" [integerOnly]="true" [autofocus]="true" >
                <ng-template pTemplate="input" let-token let-events="events" let-index="index">
                    <input pInputText type="text" [maxLength]="1" (input)="events.input($event)" (keydown)="events.keydown($event)" [attr.value]="token" class="custom-otp-input" />
                    <div *ngIf="index === 3">
                        <i class="pi pi-minus"></i>
                    </div>
                    <div *ngIf="index === 6">
                        <i class="pi pi-minus"></i>
                    </div>
                </ng-template>
            </p-inputotp>
        </div>
        
        <p-button label="Link device" (click)="connectDevice()" />

        <div class="error-message" *ngIf="notFound">{{errorMessage}}</div>
    </div>
    <div *ngIf="connected" class="modal-body flex flex-col">
        <div class="device-connected">
            The device is connected successfully!
        </div>
        <p-button label="Close window" [outlined]="true" (click)="close()" />
    </div>
</div>