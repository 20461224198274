import { PRIMITIVE_TYPES } from "../domain/primitive-types";
export const Clone = obj => {
  if (PRIMITIVE_TYPES.indexOf(typeof obj) > -1 || obj === null) {
    return obj;
  }
  //@ts-ignore
  if (typeof obj.clone === "function") {
    //@ts-ignore
    return obj.clone(); //use it's native clone method if it has one
  }
  if (typeof obj === "function") {
    //@ts-ignore
    return obj.bind();
  }
  if (Array.isArray(obj)) {
    return obj.map(item => Clone(item));
  }
  return Object.keys(obj).reduce((newObj, key) => {
    //@ts-ignore
    newObj[key] = Clone(obj[key]);
    return newObj;
  }, {});
};