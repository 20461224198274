<div class="onboarding-container flex items-center justify-center h-full w-full">
    <div class="step overlay flex flex-col gap-3">
        <div class="flex justify-end">
            <p-button (click)="skipOnboarding()" styleClass="!text-sm font-thin" label="Skip" [text]="true" severity="secondary" />
        </div>
        <div class="flex items-center justify-center">
            <img *ngIf="currentStep == 1" style="height: 15em;" src="assets/svgs/step1.svg" alt="Step 1" />
            <img *ngIf="currentStep == 2" style="height: 15em;" src="assets/svgs/step2.svg" alt="Step 2" />
            <img *ngIf="currentStep == 3" style="height: 15em;" src="assets/svgs/step3.svg" alt="Step 3" />
        </div>
        <div class="flex flex-col items-center justify-center h-40" *ngIf="currentStep == 1">
            <h1 class="text-center h-10 text-lg">Install the App</h1>
            <p class="text-sm opacity-40 font-thin">Download the app on Android or Web Browser.</p>
        </div>
        <div class="flex flex-col items-center justify-center h-40" *ngIf="currentStep == 2">
            <h1 class="text-center h-10 text-lg">Enter Registration Code</h1>
            <p class="text-sm opacity-40 font-thin">Launch the app and input the code shown on your screen.</p>
        </div>
        <div class="flex flex-col items-center justify-center h-40" *ngIf="currentStep == 3">
            <h1 class="text-center h-10 text-lg">Create Your Playlist</h1>
            <p class="text-sm opacity-40 font-thin">Add media and assign it to your device on the Playlist page.</p>
        </div>
        <div class="flex justify-between">
            <div class="flex gap-3">
                <p-radiobutton [(ngModel)]="currentStep" value="1" variant="filled" />
                <p-radiobutton [(ngModel)]="currentStep" value="2" variant="filled" />
                <p-radiobutton [(ngModel)]="currentStep" value="3" variant="filled" />
            </div>
            <div class="flex gap-2">
                <p-button styleClass="w-20" *ngIf="currentStep > 1" label="back" [outlined]="true" (click)="previousStep()" />
                <p-button styleClass="w-20" *ngIf="currentStep <= 2" label="Next" (click)="nextStep()" />
                <p-button styleClass="w-20" *ngIf="currentStep == 3" label="Next" (click)="finishOnboarding()" />
            </div>
        </div>
    </div>
</div>
