import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { Display } from 'src/app/api/models/account/display/display';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { curveStepAfter } from 'd3-shape';
import { Playlist } from 'src/app/api/models/account/display/playlist';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent implements AfterViewInit {
  @Output() onClose = new EventEmitter<void>();
  @Input() display: Display;

  isOnline: boolean = false;
  isCheckingOnline: boolean = true;

  errorMessage: string = '';

  selectedTab = 'info';

  chartData: any = [];
  secondsOnline: number = 0;
  gotStats: boolean = false;

  playlists: Playlist[] = [];
  playlistSelected: boolean = false;
  selectedPlaylist: Playlist;

  selectedDays: number = 1;

  selectedSensor: string = 'cpu_temp';
  gotSensorData: boolean = false;
  sensorData = {
    cpu_temp: { name: "CPU Temperature", series: [] },
    cpu_usage: { name: "CPU Usage", series: [] },
    internet_speed: { name: "Internet Speed", series: [] },
    network_speed: { name: "Network Speed", series: [] },
    memory_total: { name: "Memory Total", series: [] },
    memory_available: { name: "Memory Available", series: [] },
    memory_used: { name: "Memory Used", series: [] },
    battery_charging: { name: "Battery Charging", series: [] },
    battery_percentage: { name: "Battery Percentage", series: [] },
    device_uptime: { name: "Device Uptime", series: [] }
  };

  theCurve = curveStepAfter;

  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog, private messageService: MessageService, private router: Router) {

  }

  ngAfterViewInit(): void {
    if (this.display.device != null) {
      if (this.display.device.auto_reboot == null) {
        this.display.device.auto_reboot = false;
      }

      if (!this.display.device.commands) {
        this.display.device.commands = [];
      }

      if (!this.display.device.info) {
        this.display.device.info = [];
      }

      if (this.display.device.orientation == '') {
        this.display.device.orientation = 'landscape';
      }
      if (this.display.device.info.width) {
        this.display.device.info.width = this.display.device.info.width.toFixed(0);
        this.display.device.info.height = this.display.device.info.height.toFixed(0);
      }

      this.apiService.isDeviceOnline(this.display.device.device_uuid).subscribe((data: any) => {
        this.isOnline = data.online;
        this.isCheckingOnline = false;
      }, (error: any) => {
        this.isCheckingOnline = false;
      });

      this.showOnlineStats(1);
      this.loadSensorData();
      this.loadPlaylists();      
    }
  }

  loadPlaylists(): void {
    this.apiService.getDisplayPlaylists(this.authService.selectedAccountId, this.display.id).subscribe((data: any) => {
      this.playlists = data;
    }, (error: any) => {
      
    });
  }

  showOnlineStats(days: number) {
    this.selectedDays = days;
    this.apiService.getDisplayOnlineStats(this.authService.account.id, this.display.id).subscribe((data: any) => {
      if (Array.isArray(data)) {
        let lastStat = data[0];
        if (lastStat && lastStat.online) {
          this.secondsOnline = (new Date().getTime() - new Date(lastStat.datetime).getTime()) / 1000;
        }
        this.chartData = [{
          "name": "Display Online Status",
          "series": this.processData(data, days)
        }];
        this.gotStats = true;
      }
    }, (error: any) => {

    });
  }

  loadSensorData(): void {
    this.gotSensorData = false;
    this.sensorData = {
      cpu_temp: { name: "CPU Temperature", series: [] },
      cpu_usage: { name: "CPU Usage", series: [] },
      internet_speed: { name: "Internet Speed", series: [] },
      network_speed: { name: "Network Speed", series: [] },
      memory_total: { name: "Memory Total", series: [] },
      memory_available: { name: "Memory Available", series: [] },
      memory_used: { name: "Memory Used", series: [] },
      battery_charging: { name: "Battery Charging", series: [] },
      battery_percentage: { name: "Battery Percentage", series: [] },
      device_uptime: { name: "Device Uptime", series: [] }
    };
    this.apiService.getDisplaySensorStats(this.authService.account.id, this.display.id).subscribe((data: any) => {
      //order by unix timestamp log_date
      data.sort((a: any, b: any) => new Date(a.log_date * 1000).getTime() - new Date(b.log_date * 1000).getTime());

      for (let i = 0; i < data.length; i++) {
        let sensor = data[i];

        if (sensor.cpu_temp) {
          this.sensorData.cpu_temp.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.cpu_temp
          });
        }
        if (sensor.cpu_usage) {
          this.sensorData.cpu_usage.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.cpu_usage
          });
        }
        if (sensor.internet_speed) {
          this.sensorData.internet_speed.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.internet_speed.toFixed(2)
          });
        }
        if (sensor.network_speed) {
          this.sensorData.network_speed.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.network_speed.toFixed(2)
          });
        }
        if (sensor.memory_total) {
          this.sensorData.memory_total.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.memory_total
          });
        }
        if (sensor.memory_available) {
          this.sensorData.memory_available.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": ((sensor.memory_total - sensor.memory_available) / sensor.memory_total * 100).toFixed(2)
          });
        }
        if (sensor.memory_used) {
          this.sensorData.memory_used.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.memory_used
          });
        }
        if (typeof sensor.battery_charging === 'boolean') {
          this.sensorData.battery_charging.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.battery_charging ? 1 : 0
          });
        }
        if (sensor.battery_percentage) {
          this.sensorData.battery_percentage.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": sensor.battery_percentage
          });
        }
        if (sensor.device_uptime) {
          this.sensorData.device_uptime.series.push({
            "name": new Date(sensor.log_date * 1000),
            "value": (sensor.device_uptime / 1000 / 60)
          });
        }
      }
      if (data.length > 0) {
        this.gotSensorData = true;
      }
    }, (error: any) => {
    });
  }

  processData(logEntries: any[], amountOfDays: number): any[] {
    // Sort entries by datetime in ascending order
    logEntries.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());

    let processedData = [];
    let lastStatus = false; // Assuming initial status is offline if not specified
    let currentTime = new Date(); // Current date and time

    // Adjust currentTime to round up to the nearest 15 minutes
    if (currentTime.getMinutes() % 15 !== 0) {
      currentTime.setMinutes(currentTime.getMinutes() + (15 - currentTime.getMinutes() % 15), 0, 0);
    } else {
      currentTime.setMinutes(currentTime.getMinutes(), 0, 0); // Otherwise, set seconds and milliseconds to zero
    }

    const totalHours = 24 * amountOfDays;
    const intervalsPerHour = 4;
    const totalIntervals = totalHours * intervalsPerHour; // 672 15-minute intervals in a week

    // Loop for each 15-minute interval in the last week
    for (let interval = totalIntervals; interval > 0; interval--) {
      let intervalDate = new Date(currentTime.getTime() - interval * 15 * 60 * 1000); // Time for each 15-minute interval

      // Find the latest log entry before the current interval
      for (let i = logEntries.length - 1; i >= 0; i--) {
        if (new Date(logEntries[i].datetime).getTime() <= intervalDate.getTime()) {
          lastStatus = logEntries[i].online;
          break;
        }
      }

      processedData.push({
        "name": intervalDate,
        "value": lastStatus ? 1 : 0
      });
    }

    // Reverse the array so that the chart starts from the beginning
    processedData.reverse();

    return processedData;
  }




  saveDeviceInfo(): void {
    let displayCopy: Display = JSON.parse(JSON.stringify(this.display));
    displayCopy.device.commands = [];
    this.apiService.updateDisplayDevice(this.authService.selectedAccountId, displayCopy).subscribe((data: any) => {
      this.messageService.add({ severity: 'success', summary: 'Settings saved', detail: '' });
      this.close();
    }, (error: any) => {
      this.errorMessage = error;
    });
  }

  rebootDevice(): void {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Reboot Device',
        message: 'Are you sure you want to reboot this device?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        let displayCopy: Display = JSON.parse(JSON.stringify(this.display));
        displayCopy.device.commands.push('reboot');
        this.apiService.updateDisplayDevice(this.authService.selectedAccountId, displayCopy).subscribe((data: any) => {
          this.messageService.add({ severity: 'success', summary: 'Device rebooted', detail: '' });
          this.close();
        }, (error: any) => {
          this.errorMessage = error;
        });
      }
    });
  }

  unlinkDevice(): void {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Unlink Device',
        message: 'Are you sure you want to unlink this device?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.unlinkDevice(this.authService.selectedAccountId, this.display.id).subscribe((data: any) => {
          this.messageService.add({ severity: 'success', summary: 'Device unlinked', detail: '' });
          this.close();
        }, (error: any) => {
          this.errorMessage = error;
        });
      }
    });

  }
  close(): void {
    this.onClose.emit();
  }

  secondsToHumanReadable(seconds: number): string {
    if (seconds == 0) {
      return 'Never';
    }
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let humanReadable = '';
    if (hours > 0) {
      humanReadable += hours + ' hours ';
    }
    if (minutes > 0) {
      humanReadable += minutes + ' minutes';
    }

    if (humanReadable == '') {
      humanReadable = seconds.toFixed(2) + ' seconds';
    }

    return humanReadable;
  }

  editPlaylist(playlist): void {
    this.router.navigate([
      this.authService.getAccountBasePath() + '/playlist/' + playlist.id
    ]);
  }

  removeFromPlaylist(playlist): void {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Remove from Playlist',
        message: 'Are you sure you want to remove this display from the playlist "'+playlist.name+'"?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.removeDisplay(this.authService.selectedAccountId, playlist.id, this.display.id).subscribe((data: any) => {
          this.messageService.add({ severity: 'success', summary: 'Display removed from playlist', detail: '' });
          this.loadPlaylists();
        });
      }
    });
  }

  onPlaylistUpdated(): void {
    this.playlistSelected = false;
  }
}
