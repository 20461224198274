import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/api/api.service';
import { DataClassType } from 'src/app/api/models/account/data/data-class';
import { DataClass } from 'src/app/enums/data-class';
import { EditItem } from 'src/app/models/edit-item';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  window: any = window;
  dataClasses = DataClass;
  dataClassType:DataClassType[] = [];
  hardwareTypes: any[] = [];
  
  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditItem,
    private apiService: ApiService,
    private authService: AuthService,
    private messageService: MessageService) { 
      
      if (this.data.dataClass == DataClass.Firmware) {
        this.loadHardwareTypes();
        this.data.data.hardware_types = this.data.data.hardware_types.map((item: any) => item.id);
      }else{
        this.getClassTypes();        
      }

    }

    getClassTypes() {
      this.apiService.getClasses(this.authService.selectedAccountId).subscribe((data: DataClassType[]) => {
        this.dataClassType = data;
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error getting class types', detail: error });
      });
    }

  onSave() {
    if (this.data.dataClass == DataClass.Designs) {
      this.apiService.updateDesign(this.authService.selectedAccountId, this.data.data).subscribe((data: any) => {
        this.dialogRef.close(true);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error updating design', detail: error });
      });
    }

    if (this.data.dataClass == DataClass.Displays) {
      this.apiService.updateDisplay(this.authService.selectedAccountId, this.data.data).subscribe((data: any) => {
        this.dialogRef.close(true);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error updating display', detail: error });
      });
    }

    if (this.data.dataClass == DataClass.Data) {
      this.apiService.updateData(this.authService.selectedAccountId, this.data.data).subscribe((data: any) => {
        this.dialogRef.close(data);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error updating data', detail: error });
      });
    }

    if(this.data.dataClass == DataClass.Firmware){
      this.dialogRef.close(this.data.data);
    }    
  }

  loadHardwareTypes() {
    this.apiService.listHardwareTypes(this.authService.selectedAccountId, {
      limit: 100,
      order: 'desc',
      orderField: 'id',
      query: '',
      start: 0
    }).subscribe((data: any) => { 
      this.hardwareTypes = data.results;
    }, (error: any) => { });
  }

  onUplaodFile(event: any) {
    const file = event.target.files[0];
    this.data.data.file = file;
  }
}
