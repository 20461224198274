import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { Data } from 'src/app/api/models/account/data/data';
import { DataColumn } from 'src/app/api/models/account/data/data-column';
import { DataFields } from 'src/app/api/models/account/data/data-fields';
import { DataRow } from 'src/app/api/models/account/data/data-row';
import { DataRowData } from 'src/app/api/models/account/data/data-row-data';
import { DataChange, DataChangeEvent, DataRowChange } from 'src/app/models/data-change';
import { AuthService } from 'src/app/services/auth.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-data-feed-edit',
  templateUrl: './data-feed-edit.component.html',
  styleUrls: ['./data-feed-edit.component.scss']
})
export class DataFeedEditComponent implements AfterViewInit {
  @Input() data!: Data;
  @Output() onDataChange = new EventEmitter<DataRowChange>();

  columns:DataColumn[] = [];
  fields:DataFields[] = [];

  isLoading = true;

  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog, private messageService: MessageService) { 
    apiService.fieldTypes().subscribe((data: DataFields[]) => {
      this.fields = data;
    });
  }

  ngAfterViewInit(): void {
    this.refreshList();
  }

  refreshList(){
    this.apiService.getColumns(this.authService.selectedAccountId, this.data.id).subscribe((columnsData: DataColumn[]) => {
      for(let column of columnsData){
        column.selected = column.fieldClass+'_'+column.fieldName;
        if(column.name == ''){
          column.name = column.fieldName;
        }
      }
      this.columns = columnsData;
      this.isLoading = false;
    });
  }

  add(){
    this.apiService.createColumn(this.authService.selectedAccountId, this.data.id, {
      name: '', 
      fieldClass:  '', 
      fieldName:  '',
      order: this.columns.length,
      }).subscribe((data: DataColumn) => {
        this.refreshList();
        this.messageService.add({severity:'success', summary:'Column added', detail:'Column has been added successfully' });
    } );
  }

  remove(index) {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Delete column',
        message: 'Are you sure you want to delete this column?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.removeColumn(this.authService.selectedAccountId, this.data.id, index).subscribe((data: DataColumn) => {
          this.refreshList();
          this.messageService.add({severity:'success', summary:'Column removed', detail:'Column has been removed successfully' });
        });
      }
    });     
  }

  save() {
    
  }

  cancel() {
    this.onDataChange.emit({dataChangeEvent: DataChangeEvent.onCancel, dataRow: null});
  }

  async onChangeField(event:any, column:DataColumn, index:number){
    column.fieldClass = column.selected.split('_')[0];
    column.fieldName = column.selected.split('_')[1];
    column.name = column.fieldName;
    await this.apiService.updateColumnField(this.authService.selectedAccountId, this.data.id, index, column.fieldClass, column.fieldName, column.name).toPromise();
    this.messageService.add({severity:'success', summary:'Field changed', detail:'Field has been changed successfully' });
  }

  onNameChange(event:any, column:DataColumn, index:number){
    this.apiService.updateColumnName(this.authService.selectedAccountId, this.data.id, index, column.name).subscribe((data: DataColumn) => {
        this.messageService.add({severity:'success', summary:'Name changed', detail:'Name has been changed successfully' });
        this.refreshList();
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.columns = [...this.columns];    
    this.apiService.orderColumns(this.authService.selectedAccountId, this.data.id, this.columns).subscribe((data: DataColumn[]) => {
      this.messageService.add({severity:'success', summary:'Order changed', detail:'Order has been changed successfully'});
    });
  }
}
