import { DataSource, CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, EventEmitter, Injectable, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, merge, map } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { Data } from 'src/app/api/models/account/data/data';
import { DataResult } from 'src/app/api/models/account/data/data-result';
import { AuthService } from 'src/app/services/auth.service';
import { EditComponent } from '../edit/edit.component';
import { DataClass } from 'src/app/enums/data-class';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { Popover } from 'primeng/popover';
import { MoveDataComponent } from '../move-data/move-data.component';


/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: string,
    public data: Data,
    public dirList: Array<{ name: string, id: number }>,
    public level = 1,
    public expandable = false,
    public isLoading = false,
  ) { }
}

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }
    node.isLoading = true;

    if (expand) {
      let nodes = [];
      this.apiService.findData(this.authService.selectedAccountId, {
        query: "",
        limit: 1000,
        start: 0,
        order: 'asc',
        orderField: 'id',
        parent: node.data.id,
        filter: ['Folder']
      }).subscribe((data: DataResult) => {
        data.results.forEach((item: any, indexnum: number) => {
          nodes.push(new DynamicFlatNode(item.name, item, [...node.dirList, ...[{ name: item.name, id: item.id }]], node.level + 1, true));
        });
        this.data.splice(index + 1, 0, ...nodes);
        this.dataChange.next(this.data);
        node.isLoading = false;
      }, (error: any) => {
        node.isLoading = false;
      });
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) { }
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }
}

@Component({
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss']
})
export class FolderTreeComponent implements AfterViewInit {
  @Output() onDataSelect = new EventEmitter<{ data: Data, dirList: Array<{ name: string, id: number }> }>();
  @Output() onRename = new EventEmitter<Data>();
  @Input() selectedId: number = 0;
  @Input() editFolder: boolean = false;
  @ViewChild('folderdir') folderdir!: Popover;
  selectedData: DynamicFlatNode = null;

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  constructor(private apiService: ApiService,
    private authService: AuthService, private dialog: MatDialog) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.apiService, this.authService);

    this.dataSource.data.push(new DynamicFlatNode("Home", {
      id: 0,
      name: "Home",
      groups: null,
      meta: {},
      mutationDate: "",
      parent: null,
      selected_class: null,
    }, [{
      name: "Home",
      id: 0
    }], 1, true, false));

  }

  ngAfterViewInit(): void {
    this.treeControl.expand(this.dataSource.data[0]);
  }

  refreshDirs() {
    this.dataSource.data = [];
    this.dataSource.data.push(new DynamicFlatNode("Home", {
      id: 0,
      name: "Home",
      groups: null,
      meta: {},
      mutationDate: "",
      parent: null,
      selected_class: null,
    }, [{
      name: "Home",
      id: 0
    }], 1, true, false));
    this.treeControl.expand(this.dataSource.data[0]);
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  onSelected(data: DynamicFlatNode) {
    this.treeControl.expand(data);
    this.selectedId = data.data.id;
    this.onDataSelect.emit({ data: data.data, dirList: data.dirList });
  }

  onOpenMenu(event, data: DynamicFlatNode) {
    this.selectedData = data;
    this.folderdir.hide();
    this.folderdir.show(event);
  }

  onHideMenu(event) {

  }

  onRenameFolder() {
    this.dialog.open(EditComponent, {
      panelClass: 'custom-dialog-container',
      enterAnimationDuration: 0,
      data: {
        title: 'Edit folder',
        dataClass: DataClass.Data,
        data: JSON.parse(JSON.stringify(this.selectedData.data)),
        width: 400,
        height: 300
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.selectedData.data.name = result.name;
        this.selectedData.item = result.name;
      }
    });
  }

  onDeleteFolder() {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Delete Folder',
        message: 'Are you sure you want to delete this folder?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.selectedData.isLoading = true;
        this.apiService.deleteData(this.authService.selectedAccountId, this.selectedData.data.id).subscribe((data: any) => {
          //find data and remove from tree
          //delete the childeren first
          this.selectedData.isLoading = false;

          for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].data.id == this.selectedData.data.id) {
              this.dataSource.data.splice(i, 1);
              this.onSelected(this.dataSource.data[0]);
              this.dataSource.dataChange.next(this.dataSource.data);
              break;
            }
          }

          

        });
      }
    });
  }

  onMoveFolder() {
    this.dialog.open(MoveDataComponent, {
      panelClass: 'custom-dialog-container',
      enterAnimationDuration: 0,
      data: {
        title: 'Move directory',
        dataClass: DataClass.Data,
        data: JSON.parse(JSON.stringify([this.selectedData.data])),
        width: '30em',
        height: '40em'
      }
    }).afterClosed().subscribe((result: Array<{ name: string, id: number }>) => {
      if (result) {
        this.refreshDirs();
      }
    });
  }
}
