<div class="modal-main" (mouseover)="onHover()" (mouseout)="onLeave()" cdkDrag (cdkDragEnded)="cdkDragEnded($event)" [cdkDragFreeDragPosition]="dragPosition">
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Text Style</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="input-style-controls">
            <div class="select-container">
                <div class="tab-index">
                    <div class="tab-item" (click)="commonService.textInputValue.wrapAlignment = 0; onChange();" [class.tab-item-selected]="commonService.textInputValue.wrapAlignment == 0"><ng-icon name="saxTextalignJustifyleftOutline" size="20"></ng-icon></div>
                    <div class="tab-item" (click)="commonService.textInputValue.wrapAlignment = 2; onChange();" [class.tab-item-selected]="commonService.textInputValue.wrapAlignment == 2"><ng-icon name="saxTextalignCenterOutline" size="20"></ng-icon></div>
                    <div class="tab-item" (click)="commonService.textInputValue.wrapAlignment = 1; onChange();" [class.tab-item-selected]="commonService.textInputValue.wrapAlignment == 1"><ng-icon name="saxTextalignRightOutline" size="20"></ng-icon></div>
                </div>
            </div>

            <div class="select-container">
                <div >
                    <p-floatlabel>
                        <label for="input_fontfamily" class="form-label">Font</label>
                        <byte-this-google-font-selector-input class="form-select w-full" id="input_fontfamily" [google-api-key]="googleFontApiKey" [value]="commonService.textInputValue.fontFamily" (change)="onFontChange($event)" (hover-value)="onFontHover($event)"></byte-this-google-font-selector-input>
                    </p-floatlabel>
                </div>
                <div >
                    <p-floatlabel variant="on">
                        <label for="input_fontsize" class="form-label">Font size</label>
                        <input pInputText class="form-control w-full" id="input_fontsize" [(ngModel)]="commonService.textInputValue.fontSize" type="number" (change)="onChange()">
                    </p-floatlabel>
                </div>
            </div>

            <div class="select-container">
                <div >
                    <label for="input_fontweight" class="form-label">Font weight</label>
                    <select class="form-select select-field" id="input_fontweight" [(ngModel)]="commonService.textInputValue.fontWeight" (change)="onChange()">
                    <option [value]="1">w200</option>
                    <option [value]="2">w300</option>
                    <option [value]="3">w400</option>
                    <option [value]="4">w500</option>
                    <option [value]="5">w600</option>
                    <option [value]="6">w700</option>
                    <option [value]="7">w800</option>
                    <option [value]="8">w900</option>
                    <option [value]="9">normal</option>
                    <option [value]="10">Bold</option>
                  </select>
                </div>
                <div >
                    <label for="input_fontstyle" class="form-label">Style</label>
                    <select class="form-select select-field" id="input_fontstyle" [(ngModel)]="commonService.textInputValue.fontStyle" (change)="onChange()">
                        <option [value]="0">Normal</option>
                        <option [value]="1">Italic</option>
                      </select>
                </div>
            </div>

            <div class="select-container">
                <div >
                    <label for="input_fontoverflow" class="form-label">Overflow</label>
                    <select class="form-select select-field" id="input_fontoverflow" [(ngModel)]="commonService.textInputValue.overflow" (change)="onChange()">
                    <option [value]="0">Wrap</option>
                    <option [value]="1">Fade</option>
                    <option [value]="2">Ellipsis</option>
                    <option [value]="3">Visible</option>
                  </select>
                </div>
                <div >
                    <label for="input_fontoverflow" class="form-label">Fit</label>
                    <select class="form-select select-field" id="input_fontoverflow" [(ngModel)]="commonService.textInputValue.fit" (change)="onChange()">
                    <option [value]="0">None</option>
                    <option [value]="1">Fill</option>
                    <option [value]="2">Contain</option>
                    <option [value]="3">Cover</option>
                    <option [value]="4">Fit Width</option>
                    <option [value]="5">Fit Height</option>
                    <option [value]="6">Scale Down</option>
                  </select>
                </div>     
            </div>

            <div class="select-container">
                <div >
                    <p-floatlabel variant="on">
                        <label for="input_letterspacing" class="form-label">Letter Spacing</label>
                        <input pInputText class="form-control w-full" id="input_letterspacing" [(ngModel)]="commonService.textInputValue.letterSpacing" type="number" (change)="onChange()">
                    </p-floatlabel>
                </div>

                <div >
                    <p-floatlabel variant="on">
                        <label for="input_wordspacing" class="form-label">Word Spacing</label>
                        <input pInputText class="form-control w-full" id="input_wordspacing" [(ngModel)]="commonService.textInputValue.wordSpacing" type="number" (change)="onChange()">
                    </p-floatlabel>
                </div>
            </div>

            <div class="select-container">
                <div >
                    <p-floatlabel variant="on">
                        <label for="input_fontcolor" class="form-label">Font color</label>
                        <input pInputText id="input_fontcolor" class="form-control w-full" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="textColor" [style.background]="textColor" (colorPickerChange)="onTextColorChange($event)" />
                    </p-floatlabel>
                </div>
                <div >
                    <p-floatlabel variant="on">
                    <label for="input_fontbackroundcolor" class="form-label">Font background color</label>
                    <input pInputText id="input_fontbackroundcolor" class="form-control w-full"  [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="backgroundColor" [style.background]="backgroundColor" (colorPickerChange)="onBackgroundColorChange($event)" />
                    </p-floatlabel>
                </div>
            </div>

            <div class="select-container" *ngIf="this.commonService.textInputValue.shadows.length > 0">
                <div >
                    <p-floatlabel variant="on">
                        <label for="input_fontcolor" class="form-label">Shadow color</label>
                        <input pInputText id="input_fontcolor" class="form-control w-full" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="shadowColor" [style.background]="shadowColor" (colorPickerChange)="onShadowColorChange($event)" />
                    </p-floatlabel>
                </div>

                <div >
                    <p-floatlabel variant="on">
                        <label for="input_wordspacing" class="form-label">Shadow X</label>
                        <input pInputText class="form-control w-full" id="input_wordspacing" [(ngModel)]="this.commonService.textInputValue.shadows[0].offsetX" type="number" (change)="onChange()">
                    </p-floatlabel>
                </div>
            </div>
            <div class="select-container" *ngIf="this.commonService.textInputValue.shadows.length > 0">
                <div >
                    <p-floatlabel variant="on">
                        <label for="input_wordspacing" class="form-label">Shadow Y</label>
                        <input pInputText class="form-control w-full" id="input_wordspacing" [(ngModel)]="this.commonService.textInputValue.shadows[0].offsetY" type="number" (change)="onChange()">
                    </p-floatlabel>
                </div>
                <div >
                    <p-floatlabel variant="on">
                        <label for="input_wordspacing" class="form-label">Blur radius</label>
                        <input pInputText class="form-control w-full" id="input_wordspacing" [(ngModel)]="this.commonService.textInputValue.shadows[0].blurRadius" type="number" (change)="onChange()">
                    </p-floatlabel>
                </div>
            </div>
        </div>
    </div>
</div>