import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/api/api.service';
import { Account } from 'src/app/api/models/account/account';
import { AccountGroup } from 'src/app/api/models/account/accountGroup';
import { AccountStats } from 'src/app/api/models/account/accountStats';
import { AccountUser } from 'src/app/api/models/account/accountUser';
import { User } from 'src/app/api/models/user/user';
import { DataClass } from 'src/app/enums/data-class';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements AfterViewInit {

  @Input() account: Account = null;
  roles: AccountGroup[] = [];
  invites: any[] = [];
  email: string = '';
  stats: AccountStats = null;

  dataClass: DataClass = DataClass.AuditLogs;

  allowedMB: number = 0;
  allowedDisplays: number = 0;
  allowedFeeds: number = 0;

  deleteText: string = '';

  constructor(public authService: AuthService, private apiService: ApiService, private messageService: MessageService, private commonService: CommonService, private dialog: MatDialog) {
  }

  ngAfterViewInit(): void {
    this.apiService.listAccountRoles(this.account.id).subscribe(roles => {
      this.roles = roles;
    });
    this.account.accountUsers.forEach(user => {
      user.user.roles = this.getListOfRoles(user).map(role => { return role.id; });
      user.user.isAdmin = user.user.roles.includes(1);
    });

    this.apiService.partnerAccountStats(this.authService.selectedPartnerId, this.account.id).subscribe(stats => {
      this.stats = stats;
      this.allowedMB = stats.allowedMB;
      this.allowedDisplays = stats.allowedDisplays;
      this.allowedFeeds = stats.allowedFeeds;
    });
    this.refreshInvites();
  }

  bytesToSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i == 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }

  close() {
    this.commonService.accountSettingsOpen = false;
  }

  refreshInvites() {
    this.apiService.getAccountInvites(this.account.id).subscribe(invites => {
      this.invites = invites;
    });
  }

  currentOption: string = 'info';

  currentLocation() {
    return this.authService.getAccountBasePath() + '/settings';
  }

  saveAccountInfo() {
    this.apiService.partnerAccountUpdate(this.authService.selectedPartnerId, this.account.id, this.account.info.name, this.allowedDisplays, this.allowedFeeds, this.allowedMB).subscribe(result => {
      this.messageService.add({ severity: 'success', summary: 'Account info updated', detail: '' });

      this.apiService.partnerAccountStats(this.authService.selectedPartnerId, this.account.id).subscribe(stats => {
        this.stats = stats;
        this.allowedMB = stats.allowedMB;
        this.allowedDisplays = stats.allowedDisplays;
        this.allowedFeeds = stats.allowedFeeds;
      });
    });
  }

  getListOfRoles(user: AccountUser) {
    let roles = [];
    user.groups.forEach(group => {
      roles.push(group.group);
    });
    return roles;
  }

  getRolesAsString(user: any) {
    let roles = this.getListOfRoles(user);
    let list = [];
    roles.forEach(role => {
      if (list.indexOf(role.name) == -1)
        list.push(role.name);
    });
    return list.map(role => { return role; }).join(', ');
  }

  onRolesChange(user: User) {
    this.apiService.updateUserRoles(this.account.id, user.id, user.roles).subscribe(result => {
      this.messageService.add({ severity: 'success', summary: 'User roles updated', detail: '' });
    });
  }

  addUser() {
    this.apiService.addUserToAccount(this.account.id, this.email).subscribe(result => {
      this.messageService.add({ severity: 'success', summary: 'User invited', detail: '' });
      this.email = '';
      this.refresh();
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
    });
  }

  deleteUser(id: number) {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Remove user',
        message: 'Are you sure you want to remove this user?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.removeUserFromAccount(this.account.id, id).subscribe(result => {
          this.messageService.add({ severity: 'success', summary: 'User removed', detail: '' });
          this.refresh();
        });
      }
    }); 
  }

  deleteInvite(id: number) {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Remove user',
        message: 'Are you sure you want to remove this user?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.deleteAccountInvite(this.account.id, id).subscribe(result => {
          this.messageService.add({ severity: 'success', summary: 'Invite deleted', detail: '' });
          this.refresh();
        });
      }
    });    
  }

  refresh() {
    this.apiService.getAccount(this.account.id).subscribe(account => {
      this.account = account;
      this.account.accountUsers.forEach(user => {
        user.user.roles = this.getListOfRoles(user).map(role => { return role.id; });
        user.user.isAdmin = user.user.roles.includes(1);
      });
      this.refreshInvites();
    });
  }

  onTableDataChange(event: any) {
    
  }

  deleteAccount() {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Delete account',
        message: 'Are you sure you want to delete this account?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.partnerAccountDelete(this.authService.selectedPartnerId, this.account.id).subscribe(result => {
          this.messageService.add({ severity: 'success', summary: 'Account deleted', detail: '' });
          this.close();
        });
      }
    });
  }
}
