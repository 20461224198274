import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Playlist } from 'src/app/api/models/account/display/playlist';
import { DataClass } from 'src/app/enums/data-class';
import { NewComponent } from '../new/new.component';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Composite } from 'src/app/api/models/account/display/composite';
import { Design } from 'src/app/api/models/account/display/design';
import { Data } from 'src/app/api/models/account/data/data';
import { CompositeVariation } from 'src/app/api/models/account/display/compositeVariation';
import { CompositeDataStream } from 'src/app/api/models/account/display/compositeDataStream';
import { Display } from 'src/app/api/models/account/display/display';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { EditComponent } from '../edit/edit.component';
import { DataFolderComponent } from '../data-folder/data-folder.component';
import { Schedule } from 'src/app/api/models/account/display/schedule';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { CommonService } from 'src/app/services/common.service';
import { AnimationEditorComponent } from './animation-editor/animation-editor.component';


@Component({
  selector: 'app-playlist-edit',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataFolderComponent) dataTableComponent!: DataFolderComponent;
  @Input() playlist: Playlist;
  @Input() isPopup: boolean = false;
  @Output() onClose = new EventEmitter<void>();

  selectedComposite: Composite;
  selectedDesign: CompositeVariation;
  selectedData: CompositeDataStream;

  dataSelectOpen: boolean = false;
  designSelectOpen: boolean = false;
  displaySelectOpen: boolean = false;

  autoDesigns: Design[] = [];
  lodingTest: boolean = false;
  selectorType: string = 'drive';
  dataClassData: DataClass = DataClass.Data;
  dataClassDesigns: DataClass = DataClass.Designs;

  themeGroups: string[] = [];
  themes: string[] = [];

  loading: boolean = false;
  aspectRatios: {
    orientation: string,
    aspectRatio: number,
    aspectRatioReadable: string,
    width: number,
    height: number
  }[] = [];

  selectedTab: number = 0;
  editTitle = false;

  constructor(private dialogService: DialogService, private dialog: MatDialog,
    private apiService: ApiService,
    private authService: AuthService,
    private messageService: MessageService, public commonService: CommonService) {
    this.apiService.getDesignThemeGroups(this.authService.selectedAccountId).subscribe((data: any) => {
      this.themeGroups = data;
    });

    this.apiService.getDesignAspectRatios(this.authService.selectedAccountId).subscribe((data: any) => {
      this.aspectRatios = data;
    });
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.onKeydown.bind(this));
  }

  ngAfterViewInit(): void {
    //bind drag and drop element
    this.commonService.playlistDroplistElement = document.getElementById('playlistDropList');

    //bind keydown event
    document.addEventListener('keydown', this.onKeydown.bind(this));
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key == 'Delete') {
      if (this.selectedDesign != null) {
        this.removeDesign(this.selectedDesign);
        event.preventDefault();
      } else if (this.selectedData != null) {
        this.removeData(this.selectedData);
        event.preventDefault();
      } else if (this.selectedComposite != null) {
        this.removeComposite(this.selectedComposite);
        event.preventDefault();
      }
    } else if (event.key == 'Escape') {
      if (this.selectedDesign != null) {
        this.selectedDesign = null;
        event.preventDefault();
      } else if (this.selectedData != null) {
        this.selectedData = null;
        event.preventDefault();
      } else if (this.selectedComposite != null) {
        this.selectedComposite = null;
        event.preventDefault();
      }
    }
  }

  ngOnInit(): void {
    // if(this.playlist && this.playlist.composites.length > 0){
    //   this.selectedComposite = this.playlist.composites[0];
    // }

    // this.getPlaylist(() =>{
    //   if(this.playlist && this.playlist.composites.length > 0){
    //     this.selectedComposite = this.playlist.composites[0];
    //     this.test();
    //   }

    //   if(this.selectedComposite && this.selectedComposite.variations.length > 0){
    //     this.selectedDesign = this.selectedComposite.variations[0];
    //   }else{
    //     this.selectedDesign = null;
    //   }

    //   if(this.selectedComposite && this.selectedComposite.data_stream.length > 0){
    //     this.selectedData = this.selectedComposite.data_stream[0];
    //   }else{
    //     this.selectedData = null;
    //   }
    // });

    this.getPlaylist(() => {

    });
  }

  formatSchedules() {
    for (let i = 0; i < this.playlist.schedules.length; i++) {
      let schedule = this.playlist.schedules[i];

      // Convert start_date and end_date to 'YYYY-MM-DD' format strings
      if (schedule.start_date) {
        let startDate = new Date(schedule.start_date);
        schedule.start_date = startDate.toISOString().split('T')[0];
      }
      if (schedule.end_date) {
        let endDate = new Date(schedule.end_date);
        schedule.end_date = endDate.toISOString().split('T')[0];
      }

      // Convert start_time and end_time to 'HH:mm' format
      if (schedule.start_time) {
        let startTime = new Date(schedule.start_time);
        schedule.start_time = startTime.getHours().toString().padStart(2, '0') + ':' + startTime.getMinutes().toString().padStart(2, '0');
      }
      if (schedule.end_time) {
        let endTime = new Date(schedule.end_time);
        schedule.end_time = endTime.getHours().toString().padStart(2, '0') + ':' + endTime.getMinutes().toString().padStart(2, '0');
      }
    }
  }

  onThemeGroupChange() {
    this.selectedComposite.themeName = '';
    this.themes = [];
    if (this.selectedComposite.themeGroup == '') {
      return;
    }
    this.apiService.getDesignThemes(this.authService.selectedAccountId, this.selectedComposite.themeGroup).subscribe((data: any) => {
      this.themes = data;
      this.updateComposite();
    });
  }

  onThemeChange() {
    this.updateComposite();
  }

  updatePlaylist() {
    this.apiService.updatePlaylist(this.authService.selectedAccountId, this.playlist).subscribe((data: any) => {
      this.test();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
    });
  }

  updateComposite() {
    this.apiService.updatePlaylistComposite(this.authService.selectedAccountId, this.playlist.id, this.selectedComposite).subscribe((data: any) => {
      this.test();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Composite updated' });
    });
  }

  removeDisplay(display: Display) {
    if (display == null) { return; }
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Remove display',
        message: 'Are you sure you want to remove this display from the playlist'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.removeDisplay(this.authService.selectedAccountId, this.playlist.id, display.id).subscribe((data: any) => {
          this.getPlaylist(() => {
            this.selectedDesign = null;
          });
        });
      }
    });

  }

  selectComposite(composite: Composite) {
    this.selectedComposite = composite;
    this.test();
  }

  selectDesign(design: CompositeVariation) {
    this.selectedDesign = design;
  }

  selectData(data: CompositeDataStream) {
    this.selectedData = data;
  }

  addComposite() {
    this.dialogService.open(NewComponent, {
      data: {
        title: 'New Composite',
        dataClass: DataClass.Composite,
        data: {
          name: 'New Composite ' + Math.floor(Math.random() * 1000),
          playlistId: this.playlist.id,
          order: this.playlist.composites.length + 1
        },
        width: 400,
        height: 200
      }
    }).onClose.subscribe((result: any) => {
      if (result) {
        this.getPlaylist(() => {
          this.selectComposite(this.playlist.composites[this.playlist.composites.length - 1]);
        });
      }
    });
  }

  removeComposite(composite: Composite) {
    if (composite == null) { return; }
    let index = this.playlist.composites.indexOf(composite);
    this.apiService.deletePlaylistComposite(this.authService.selectedAccountId, this.playlist.id, composite.id).subscribe((data: any) => {
      this.getPlaylist(() => {
        if (this.playlist && this.playlist.composites.length > 0) {
          //select the one before the deleted one
          if (index > 0) {
            this.selectedComposite = this.playlist.composites[index - 1];
          } else {
            this.selectedComposite = this.playlist.composites[0];
          }
          this.test();
        } else {
          this.selectedComposite = null;
        }
        this.selectedData = null;
        this.selectedDesign = null;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
      });
    });
  }

  getPlaylist(callback?: any) {
    this.apiService.getPlaylist(this.authService.selectedAccountId, this.playlist.id).subscribe((data: any) => {
      this.playlist = data;
      this.commonService.playlistItems = this.playlist.composites;

      if (this.selectComposite) {
        for (let i = 0; i < this.playlist.composites.length; i++) {
          if (this.selectedComposite && this.playlist.composites[i].id == this.selectedComposite.id) {
            this.selectedComposite = this.playlist.composites[i];
          }
        }
      }

      this.formatSchedules();

      if (callback) {
        callback();
      }
    });
  }

  drag(event: CdkDragDrop<Composite[]>) {
    if (event.previousContainer !== event.container) {
      //document.getElementById('playlist-drag-overlay').style.display = 'block';
    }
  }

  dragEnd(event: CdkDragDrop<Composite[]>) {
    //document.getElementById('playlist-drag-overlay').style.display = 'none';
  }

  reOrderPlaylist(callback) {
    let order = 1;
    let orderIds = [];
    for (let i = 0; i < this.playlist.composites.length; i++) {
      orderIds.push({ id: this.playlist.composites[i].id, order: order });
      order++;
    }
    if (orderIds.length > 0) {
      this.apiService.reOrderPlaylistComposite(this.authService.selectedAccountId, this.playlist.id, orderIds).subscribe((data: any) => {
        callback();
      });
    }else{
      callback();
    }
  }

  drop(event: CdkDragDrop<Composite[]>) {
    //document.getElementById('playlist-drag-overlay').style.display = 'none';
    if (event.previousContainer === event.container) {
      moveItemInArray(this.playlist.composites, event.previousIndex, event.currentIndex);
      this.reOrderPlaylist(() => {
        this.getPlaylist();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
      });
    } else {
      let data: any = event.previousContainer.data[event.previousIndex];
      this.onDataFolderSelect([data], event.currentIndex);
    }
  }

  close() {
    this.onClose.emit();
  }

  removeDesign(design: CompositeVariation) {
    if (design == null) { return; }
    this.apiService.deletePlaylistCompositeDesign(this.authService.selectedAccountId, this.playlist.id, this.selectedComposite.id, design.id).subscribe((data: any) => {
      this.getPlaylist(() => {
        if (this.selectedComposite.variations.length > 0) {
          this.selectedDesign = this.selectedComposite.variations[0];
        } else {
          this.selectedDesign = null;
        }
        this.test();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
      });
    });
  }

  removeData(data: CompositeDataStream) {
    if (data == null) { return; }
    this.apiService.deletePlaylistCompositeData(this.authService.selectedAccountId, this.playlist.id, this.selectedComposite.id, data.id).subscribe((data: any) => {
      this.getPlaylist(() => {
        if (this.selectedComposite.data_stream.length > 0) {
          this.selectedData = this.selectedComposite.data_stream[0];
        } else {
          this.selectedData = null;
        }
        this.test();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
      });
    });
  }

  onCloseMedia(event: any) {
    this.dataSelectOpen = false;
    this.getPlaylist(() => {
      if (this.selectedComposite.data_stream.length > 0) {
        this.selectedData = this.selectedComposite.data_stream[0];
      } else {
        this.selectedData = null;
      }
      this.test();
    });
  }

  addData() {
    this.dataSelectOpen = true;
  }

  addDesign() {
    this.designSelectOpen = true;
  }

  addDisplay() {
    this.displaySelectOpen = true;
  }

  onCloseDesigns(event: any) {
    this.designSelectOpen = false;
    this.getPlaylist(() => {
      if (this.selectedComposite.variations.length > 0) {
        this.selectedDesign = this.selectedComposite.variations[0];
      } else {
        this.selectedDesign = null;
      }
      this.test();
    });
  }

  onCloseDisplays(event: any) {
    this.displaySelectOpen = false;
    this.getPlaylist(() => {
      this.selectedDesign = null;
    });
  }

  test() {
    if (this.selectedComposite == null) { return; }
    this.lodingTest = true;
    this.apiService.testPlaylist(this.authService.selectedAccountId, this.playlist.id, this.selectedComposite.id).subscribe((data: any) => {
      this.autoDesigns = data.designs;
      if (this.selectedComposite) {
        for (let i = 0; i < this.selectedComposite.variations.length; i++) {
          this.selectedComposite.variations[i].design.selectionError = true;
          for (let j = 0; j < this.autoDesigns.length; j++) {
            if (this.selectedComposite.variations[i].design.id == this.autoDesigns[j].id) {
              this.selectedComposite.variations[i].design.selectionError = false;
            }
          }
        }
      }
      this.lodingTest = false;
    });
  }

  createPlaylistComposite(name: string, time: number = 15): Promise<any> {
    return this.apiService.createPlaylistComposite(this.authService.selectedAccountId, this.playlist.id, name, this.playlist.composites.length + 1, time).toPromise();
  }

  async onDataSelect(data: Design[]) {
    this.loading = true;
    try {
      for (let i = 0; i < data.length; i++) {
        let design: Design = data[i];
        let time = 10;
        let items_per_composite = 1;
        for (let i = 0; i < design.attributes.length; i++) {
          if (design.attributes[i].is_table) {
            if (time < 120) {
              time = 120;
            }
          }
          if (design.attributes[i].fieldClass == 'Feed' && design.attributes[i].is_required) {
            if (time < 15) {
              time = 15;
              items_per_composite = 2;
            }
          }
        }
        let composite: Composite = await this.createPlaylistComposite(design.name, time);
        composite.auto_variation = false;
        composite.items_per_composite = items_per_composite;
        await this.apiService.updatePlaylistComposite(this.authService.selectedAccountId, this.playlist.id, composite).toPromise();
        await this.apiService.createPlaylistCompositeDesign(this.authService.selectedAccountId, this.playlist.id, composite.id, [design]).toPromise();
      }
      this.getPlaylist(() => { });
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding design' });
    }
    this.loading = false;
  }

  async onDataFolderSelect(data: Data[], indexOrder: number = 0) {
    this.loading = true;
    try {
      let compositeId = 0;
      for (let i = 0; i < data.length; i++) {
        let selectedData = data[i];
        let time = 10;
        let items_per_composite = 1;
        if (selectedData.selected_class.name == 'Feed') {
          time = 15;
          items_per_composite = 2;
        } else if (selectedData.selected_class.name == 'Table') {
          time = 120;
        } else if (selectedData.selected_class.name == 'Video') {
          //find video length
          try {
            time = Number(selectedData.meta.duration);
            //round to hole number
            time = Math.round(time);
          } catch (e) {

          }
        }
        if (indexOrder == 0) {
          indexOrder = this.playlist.composites.length + 1;
        }
        
        let composite: Composite = await this.apiService.createPlaylistComposite(this.authService.selectedAccountId, this.playlist.id, selectedData.name, indexOrder, time, [selectedData], items_per_composite).toPromise();
        compositeId = composite.id;
      }

      this.getPlaylist(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Playlist updated' });
      });
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding data' });
    }
    this.loading = false;
  }

  editAnimations() {
    this.dialog.open(AnimationEditorComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        composite: this.selectedComposite,
        playlist: this.playlist
      }
    }).afterClosed().subscribe((result: any) => {
      this.getPlaylist(() => { });
    });
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'New Data',
          dataClass: DataClass.Data,
          data: {
            name: 'New Data ' + Math.floor(Math.random() * 1000),
            selectedClassType: 'Folder',
            parentId: event.item.parentId
          },
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete this Data?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          this.apiService.deleteData(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete all selected Data?'
        }
      }).afterClosed().subscribe(async (result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          for (let item of event.selected.selected) {
            await this.apiService.deleteData(this.authService.selectedAccountId, item.id).toPromise();
          }
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Edit) {
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Data',
          dataClass: DataClass.Data,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }

  addSchedule() {
    this.apiService.createPlaylistSchedule(this.authService.selectedAccountId, this.playlist.id, "New schedule").subscribe((data: any) => {
      this.getPlaylist(() => { });
    });
  }

  removeSchedule(schedule: Schedule) {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Delete Schedule',
        message: 'Are you sure you want to delete this schedule?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.deletePlaylistSchedule(this.authService.selectedAccountId, this.playlist.id, schedule.id).subscribe((data: any) => {
          this.getPlaylist(() => { });
        });
      }
    });
  }

  onCheckboxClick(event: MouseEvent) {
    event.stopPropagation(); // Prevent the event from bubbling up to the drag container
  }

  onChangeSchedule(schedule: Schedule) {
    this.apiService.updatePlaylistSchedule(this.authService.selectedAccountId, this.playlist.id, schedule).subscribe((data: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule updated' });
    });
  }
}
