<h1 class="typeTitle" *ngIf="dataClass == dataClassType.Designs">Designs</h1>
<h1 class="typeTitle" *ngIf="dataClass == dataClassType.Playlists">Playlists</h1>
<h1 class="typeTitle" *ngIf="dataClass == dataClassType.Displays">Displays</h1>

<div class="table-top-buttons">
    <div class="flex gap-4 flex-col">
        <p-iconfield iconPosition="left" *ngIf="dataClass != dataClassType.AuditLogs">
            <span class="custom-icon">
                <ng-icon name="saxSearchNormal1Outline" size="20"></ng-icon>
            </span>
            <input type="search" (keyup)="applyFilter($event)" fluid="false" styleClass="input-field search-field" class="input-field search-field" #input pInputText placeholder="Search" [(ngModel)]="searchQuery" (ngModelChange)="onSearch()" />
        </p-iconfield>

        <div class="tab-index" *ngIf="dataClass == dataClassType.Designs">
            <div class="tab-item" (click)="globalDesign = false; updateWatch.emit({});" [class.tab-item-selected]="!globalDesign">My Designs</div>
            <div class="tab-item" (click)="globalDesign = true; updateWatch.emit({});" [class.tab-item-selected]="globalDesign">Design library</div>
        </div>
    </div>

    <div style="display: flex;gap:0.5em" *ngIf="dataClass != dataClassType.AuditLogs">
        <div class="theme-selector" *ngIf="dataClass == dataClassType.Designs">
            <select class="form-select" [(ngModel)]="selectedThemeGroup" (change)="onThemeGroupChange()">
                <option [value]="''">All categories</option>
                <option *ngFor="let themeGroup of themeGroups" [value]="themeGroup">{{themeGroup}}</option>
            </select>

            <select *ngIf="this.selectedThemeGroup != ''" class="form-select" [(ngModel)]="selectedTheme" (change)="onThemeChange()">
                <option [value]="''">All themes</option>
                <option *ngFor="let theme of themes" [value]="theme">{{theme}}</option>
            </select>
        </div>
        <button class="action-button" pButton *ngIf="dataClass == dataClassType.Displays" severity="secondary" (click)="startdevice()">
            start device</button>

        <button class="action-button" pButton *ngIf="!dataSelect && !globalDesign || dataClass == dataClassType.Displays" severity="secondary" (click)="create()">
            <ng-icon name="saxAddOutline" size="20"></ng-icon>Create {{getDataClassSingle()}}</button>
        <button class="action-button" pButton *ngIf="dataSelect" (click)="selectData()">
                <ng-icon name="saxAddOutline" size="20"></ng-icon>Add {{selection.selected.length}} items</button>
        <div *ngIf="!dataSelect && !globalDesign && dataClass != dataClassType.Accounts">
            <p-popover class="popover-el" #op>
                <div class="popover-buttons flex flex-col">
                    <button class="action-button" severity="secondary" pButton *ngIf="dataClass == dataClassType.Designs" (click)="copySelected()"><ng-icon name="saxCopyOutline" size="20"></ng-icon>Copy</button>
                    <button class="action-button" severity="secondary" pButton *ngIf="dataClass == dataClassType.Displays" (click)="moveSelected()"><ng-icon name="saxDocumentForwardOutline" size="20"></ng-icon>Move</button>
                    <button class="action-button" severity="secondary" pButton (click)="deleteSelected()"><ng-icon name="saxTrashOutline" size="20"></ng-icon>Delete selected</button>
                </div>
            </p-popover>
            <button class="more-button" styleClass="more-button" pButton (click)="op.toggle($event)" severity="secondary">
                <ng-icon style="transform: rotate(90deg);" name="saxMoreOutline" size="25"></ng-icon>
            </button>

        </div>
    </div>
</div>

<p-progressbar mode="indeterminate" [style]="{ height: '6px' }" [style.opacity]="isLoadingResults ? 1 : 0" />
<div class="mat-elevation-z8 table-view" ngClass="{{getDataClass()}}">
    <table class="table table-data" mat-table [dataSource]="dataSource" matSort>

        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns" class="">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column == 'select' || column == 'actions'">
                <p-checkbox class="checkbox" styleClass="checkbox" *ngIf="column == 'select'" (change)="$event ? toggleAllRows() : null" [ngModel]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                    [binary]="true" /> {{column == 'select' ? '' : columnNames(column)}}
            </th>
            <td mat-cell *matCellDef="let element">
                <p-checkbox class="checkbox checkbox-td" styleClass="checkbox" *ngIf="column == 'select'" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [ngModel]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)"
                    [binary]="true" />

                <div *ngIf="column !='select'">
                    <span *ngIf="column == 'name' && dataClass == dataClassType.Designs">
                        <div class="screenshot {{element.data.orientation}}" *ngIf="!dataSelect" (click)="loadEdit(element.id)">
                            <img crossorigin="anonymous" *ngIf="element.data.screenshot != null" [src]="element.data.screenshot">
                            <div class="image-placeholder" *ngIf="element.data.screenshot == null"></div>
                            <div class="theme-name">{{element[column]}}</div>
                        </div>
                        <div class="screenshot {{element.data.orientation}}" *ngIf="dataSelect" (click)="onSelectData(element)">
                            <img crossorigin="anonymous" *ngIf="element.data.screenshot != null" [src]="element.data.screenshot">
                            <div class="image-placeholder" *ngIf="element.data.screenshot == null"></div>
                            <div class="theme-name">{{element[column]}}</div>
                        </div>
                    </span>

                    <span *ngIf="dataClass == dataClassType.Designs && column != 'mutationDate' && column != 'name' && column != 'data' && column != 'user'">{{element[column]}}</span>
                    <span *ngIf="column == 'user'">{{element.user.name}}</span>
                    <div *ngIf="column == 'data'">
                        <button pButton [outlined]="true" class="btn" (click)="element.moreinfo = !element.moreinfo">More info</button>
                        <div class="log-info" *ngIf="element.moreinfo">
                            {{element.data | json}}
                        </div>
                    </div>

                    <span *ngIf="column == 'info' && dataClass == dataClassType.Accounts">{{element.info.name}}</span>
                    <span *ngIf="column != 'data' && column != 'user' && column != 'mutationDate' && column != 'status' && dataClass != dataClassType.Designs && dataClass != dataClassType.Accounts">{{element[column]}}</span>
                    <span *ngIf="column == 'status' && dataClass == dataClassType.Firmware">
                    <span *ngIf="element[column] == 0">None</span>
                    <span *ngIf="element[column] == 1">Beta</span>
                    <span *ngIf="element[column] == 2">Live</span>
                    </span>

                    <span *ngIf="column == 'mutationDate'">{{element[column] | date }}</span>

                </div>
                <div *ngIf="column == 'actions' && dataClass != dataClassType.AuditLogs" class="actions-list flex gap-1">
                    <div *ngIf="dataClass == dataClassType.Displays">
                        <button pButton [text]="true" [plain]="true" (click)="openLinkDisplay(element)" *ngIf="element.device == null">
                            Link device
                        </button>
                        <button pButton [text]="true" [plain]="true" (click)="openDeviceInfo(element)" *ngIf="element.device != null">
                            Device info
                        </button>
                    </div>


                    <button pButton [text]="true" [plain]="true" (click)="clone(element)" *ngIf="dataClass == dataClassType.Designs && globalDesign">
                        Copy
                    </button>
                    <button pButton [text]="true" [plain]="true" (click)="loadEdit(element.id)" *ngIf="dataClass == dataClassType.Designs && !dataSelect && !globalDesign && dataClass != dataClassType.Accounts">
                        <ng-icon name="saxEdit2Outline" size="20"></ng-icon>
                    </button>
                    <button pButton [text]="true" [plain]="true" (click)="editPlaylist(element)" *ngIf="dataClass == dataClassType.Playlists && !dataSelect">
                        <ng-icon name="saxEdit2Outline" size="20"></ng-icon>
                    </button>
                    <button pButton [text]="true" [plain]="true" (click)="edit(element)" *ngIf="dataClass != dataClassType.Playlists && dataClass != dataClassType.Designs && !dataSelect">
                        <ng-icon name="saxEdit2Outline" size="20"></ng-icon>
                    </button>
                    <button pButton [text]="true" [plain]="true" *ngIf="!dataSelect && !globalDesign && dataClass != dataClassType.Accounts" (click)="delete(element)">
                        <ng-icon name="saxTrashOutline" size="20"></ng-icon>
                    </button>
                    <button pButton [outlined]="true" *ngIf="dataSelect" (click)="onSelectData(element)">
                        select
                    </button>
                    <button pButton [outlined]="true" *ngIf="dataClass == dataClassType.Accounts && element.id != 1" (click)="openAccount(element.id)">
                        Open
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10">
                <div class="noresults">
                    <span *ngIf="searchQuery">No results {{getDataClass()}} found. Please try a different search.</span>
                    <span *ngIf="searchQuery == '' && !dataSelect && dataClass == dataClassType.AuditLogs">No Logs</span>
                    <span *ngIf="searchQuery == '' && !dataSelect && dataClass != dataClassType.AuditLogs">
                       No {{getDataClass()}} found. Click <button pButton style="padding: 0.1em;" [text]="true"  (click)="create()">Create {{getDataClassSingle()}}</button> to get started!
                    </span>
                </div>
            </td>
        </tr>
    </table>
    <div class="row flex justify-end mt-4" *ngIf="resultsLength > 0">
        <p-paginator class="paginator" styleClass="paginator" [rows]="pageSize" [totalRecords]="resultsLength" (onPageChange)="onPageChange($event)">
        </p-paginator>
    </div>

</div>

<div>
    <app-connect-device *ngIf="linkDisplay" [display]="selectedDisplay" (onClose)="onSelectedDisplayClose($event)"></app-connect-device>
    <app-device-info *ngIf="deviceInfo" [display]="selectedDisplay" (onClose)="onDeviceInfoClose($event)"></app-device-info>
</div>