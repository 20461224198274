import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/api/api.service';
import { Display } from 'src/app/api/models/account/display/display';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-connect-device',
  templateUrl: './connect-device.component.html',
  styleUrls: ['./connect-device.component.scss']
})
export class ConnectDeviceComponent {
  @Output() onClose = new EventEmitter<void>();
  @Input() display: Display;

  myInput = new FormControl('');

  code: string = '';
  connected: boolean = false;
  notFound: boolean = false;
  errorMessage: string = '';

  constructor(private apiService: ApiService, private authService: AuthService, ) {
    this.myInput.valueChanges.subscribe(value => {
      this.formatInput(value);
    });
   }

  connectDevice(): void {
    this.apiService.linkDevice(this.authService.selectedAccountId, this.display.id, this.code).subscribe((data: any) => {
      this.connected = true;
    }, (error: any) => {
      this.errorMessage = error;
      this.notFound = true;
    });
  }

  close(): void {
    this.onClose.emit();
  }
  
  private formatInput(value: string): void {
    let newValue = value.replace(/[^0-9-]/g, ''); // Remove all non-digit and non-hyphen characters

    // Enforce maximum length (including hyphens)
    newValue = newValue.slice(0, 11);

    if (newValue.length > 4 && newValue[3] !== '-') {
      // Insert first hyphen after the fourth character if it's not already there
      newValue = newValue.slice(0, 3) + '-' + newValue.slice(3);
    }
    // Replace subsequent hyphens and insert new ones every 3 characters after the first hyphen
    newValue = newValue.replace(/-(?=\d{4})/g, '').replace(/(\d{3})(?=\d)/g, '$1-');
    this.myInput.setValue(newValue, { emitEvent: false });
  }
}
