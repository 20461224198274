<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Edit columns</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="cancel()"></ng-icon>
    </div>
    <div class="modal-body">
        <p-progressbar mode="indeterminate" [style]="{ height: '6px' }" [style.opacity]="isLoading ? 1 : 0" />
        
        <table cdkDropList (cdkDropListDropped)="drop($event)">
            <tr cdkDrag *ngFor="let column of columns; let i = index">
                <td>
                    <mat-icon class="drag-icon" cdkDragHandle *ngIf="!isFeed">drag_indicator</mat-icon>
                </td>
                <td>
                    <div>
                        <input type="text" pInputText class="form-control" [(ngModel)]="column.name" (change)="onNameChange($event, column, i)">
                    </div>
                </td>
                <td>
                    <select class="form-select" [(ngModel)]="column.selected" (change)="onChangeField($event, column, i)">
                        <option *ngFor="let field of fields" [value]="field.field_class+'_'+field.name">{{field.field_class}} - {{field.name}}</option>
                    </select>

                    <!-- <mat-form-field>
                        <input matInput [(ngModel)]="column.fieldClass">
                    </mat-form-field> -->
                </td>
                <!-- <td>
                    <mat-form-field>
                        <input matInput [(ngModel)]="column.fieldName">
                    </mat-form-field>
                </td> -->
                <td>
                    <p-button tabindex="-1" label="Delete" [outlined]="true" (click)="remove(i)" />
                </td>
            </tr>
        </table>
        <div>
            <p-button label="Add a new column" [outlined]="true" (click)="add()" />
        </div>
    </div>
    <!-- <div class="actions">
        <button mat-button (click)="cancel()">Close</button>
    </div> -->
</div>