import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from './models/user/user';
import { LoginResponse } from './models/user/login-response';
import { LoginStatus } from './models/user/login-status';
import { Account } from './models/account/account';
import { DesignResult } from './models/account/display/designResult';
import { SearchInput } from './models/main/search-input';
import { Design } from './models/account/display/design';
import { Display } from './models/account/display/display';
import { DisplayResult } from './models/account/display/displayResult';
import { DataResult } from './models/account/data/data-result';
import { Data } from './models/account/data/data';
import { DataRow } from './models/account/data/data-row';
import { DataRowData } from './models/account/data/data-row-data';
import { DataRowResult } from './models/account/data/data-row-result';
import { DataClassType } from './models/account/data/data-class';
import { Playlist } from './models/account/display/playlist';
import { PlaylistResult } from './models/account/display/playlistResult';
import { Composite } from './models/account/display/composite';
import { DataColumn } from './models/account/data/data-column';
import { DataFields } from './models/account/data/data-fields';
import { PartnerResult } from './models/partner/partnerResult';
import { AccountResult } from './models/account/accountResult';
import { AccountGroup } from './models/account/accountGroup';
import { Schedule } from './models/account/display/schedule';
import { ScheduleResult } from './models/account/display/scheduleResult';
import { PixabayResult } from './models/account/data/pixabay-result';
import { weatherAutoComplete } from './models/main/weatherAutoComplete';
import { PartnerGroup } from './models/partner/partnerGroup';
import { AccountStats } from './models/account/accountStats';
import { Settings } from './models/user/settings';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiURL = 'https://api.display-link.nl';
  private webDavURL = 'https://api.display-link.nl';
  public authToken = '';
  private retries = 0;

  public onSessionStop = function () { };

  constructor(private http: HttpClient) {
    if (window.location.hostname == "localhost") {
      this.apiURL = 'http://locapi.display-link.nl';
      this.webDavURL = 'http://locapi.display-link.nl';
    }
  }

  getAuthHeaders(): HttpHeaders {
    return new HttpHeaders({ "Authorization": this.authToken });
  }

  setToken(token: string) {
    if (token == null) { return; }
    this.authToken = token;
  }

  fieldTypes(): Observable<DataFields[]> {
    return this.http.get<DataFields[]>(this.apiURL + "/api/fieldTypes", { responseType: 'json' })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  login(type: any, value: any): Observable<LoginResponse> {
    let data = {};
    if (type === "email") {
      data = {
        type: 'email',
        email: value
      };
    }
    return this.http.post<LoginResponse>(this.apiURL + "/login", data, { responseType: 'json' })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  saveSettings(type: any, value: any): Observable<Settings> {
    let data = {
      type: type,
      data: value
    };
    return this.http.post<Settings>(this.apiURL + "/user/settings", data, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getLoginStatus(checkToken: string): Observable<LoginStatus> {
    return this.http.get<LoginStatus>(this.apiURL + "/magiclink/status/" + checkToken, { responseType: 'json' })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateUser(user: any) {
    return this.http.post(this.apiURL + "/user/update", user, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteUserAccessToken(tokenId: number) {
    return this.http.delete(this.apiURL + "/user/token/" + tokenId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createOath2Token(scope: string, client_id: string): Observable<any> {
    return this.http.post<any>(this.apiURL + "/oauth2/token", {
      scope: scope,
      client_id: client_id
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createWebDavToken(): Observable<any> {
    return this.http.post<any>(this.apiURL + "/webdav/token", {
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getUserStatus(): Observable<User> {
    return this.http.get<User>(this.apiURL + "/user/status", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  //Account apis
  createAccount(name: string): Observable<Account> {
    return this.http.post<Account>(this.apiURL + "/account", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this))// then handle the error
      );
  }

  createDesign(accountId: number | null, name: string, orientation: any | null, width: number, height: number): Observable<Design> {
    return this.http.post<Design>(this.apiURL + "/" + accountId + "/account/design/create", {
      name: name,
      data: {
        orientation: orientation,
        width: width,
        height: height
      }
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateDesign(accountId: number | null, design: Design) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/design/" + design.id, design, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteDesign(accountId: number | null, designId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/design/" + designId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDesign(accountId: number | null, designId: number): Observable<Design> {
    return this.http.get<Design>(this.apiURL + "/" + accountId + "/account/design/" + designId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  //designs
  findDesigns(query: SearchInput, accountId: number | null): Observable<DesignResult> {
    return this.http.post<DesignResult>(this.apiURL + "/" + accountId + "/account/designs", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  setDesignGlobal(accountId: number | null, designId: number, enabled: boolean) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/design/" + designId + "/global", {
      enabled: enabled
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDesignThemeGroups(accountId: number | null): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + "/" + accountId + "/account/design/theme/groups", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDesignThemes(accountId: number | null, group: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + "/" + accountId + "/account/design/theme/" + group + "/themes", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDesignAspectRatios(accountId: number | null): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + "/" + accountId + "/account/design/aspectRatios", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  copyDesign(accountId: number | null, designId: number, selectedId: number) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/design/" + designId + "/copy/" + selectedId, {
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  //displays
  createDisplay(accountId: number | null, name: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/display/create", {
      name: name,
      data: []
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateDisplay(accountId: number | null, display: Display) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/display/" + display.id, display, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateDisplayDevice(accountId: number | null, display: Display) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/display/device/" + display.id, display.device, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteDisplay(accountId: number | null, displayId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/display/" + displayId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findDisplays(query: SearchInput, accountId: number | null): Observable<DisplayResult> {
    return this.http.post<DisplayResult>(this.apiURL + "/" + accountId + "/account/displays", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  moveDisplay(accountId: number | null, displayId: number, selectedId: number) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/display/" + displayId + "/copy/" + selectedId, {
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDisplayPlaylists(accountId: number | null, displayId: number): Observable<Playlist[]> {
    return this.http.get<Playlist[]>(this.apiURL + "/" + accountId + "/account/display/" + displayId + "/playlists", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getClasses(accountId: number | null): Observable<DataClassType[]> {
    return this.http.get<DataClassType[]>(this.apiURL + "/" + accountId + "/account/classes", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findData(accountId: number | null, query: SearchInput): Observable<DataResult> {
    return this.http.post<DataResult>(this.apiURL + "/" + accountId + "/account/data", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createData(accountId: number | null, name: string, className: string, parentId: number): Observable<Data> {
    return this.http.post<Data>(this.apiURL + "/" + accountId + "/account/data/create", {
      name: name,
      class: className,
      parent: parentId
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createLiveWebview(accountId: number | null, name: string, className: string, parentId: number, url: string, allowedUrls: Array<any>, resetTimer: number): Observable<Data> {
    return this.http.post<Data>(this.apiURL + "/" + accountId + "/account/data/webview/create", {
      name: name,
      class: className,
      parent: parentId,
      url: url,
      allowedUrls: allowedUrls,
      resetTimer: resetTimer
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createDataByURL(accountId: number | null, name: string, className: string, parentId: number, url: string): Observable<Data> {
    return this.http.post<Data>(this.apiURL + "/" + accountId + "/account/data/addImageByURL", {
      name: name,
      class: className,
      parent: parentId,
      url: url
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  uploadData(accountId: number | null, file: File, className: string, parentId: number, playlistItemId?: number, statusCallback?: (progress: number, message: string) => void): Promise<any> {

    //upload the file in chunks, this is to prevent the server from timing out
    //use chunkSession, chunkNumber, chunkTotal to keep track of the chunks
    //use chunkSize to set the size of the chunks

    //split the file into chunks
    const chunkSize = 1024 * 1024 * 25; // 25MB
    const chunks = Math.ceil(file.size / chunkSize);
    //random number to identify the session
    const chunkSession = Math.floor(Math.random() * 1000000000);
    let chunkNumber = 0;
    let bytesUploaded = 0;

    //create a promise to handle the chunk uploads

    const uploadChunk = (chunk: Blob) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('name', file.name);
        formData.append('class', className);
        formData.append('playlistItemId', playlistItemId > 0 ? playlistItemId.toString() : '0');
        formData.append('parent', parentId.toString());
        formData.append('chunkSession', chunkSession.toString());
        formData.append('chunkNumber', (chunkNumber + 1).toString());
        formData.append('chunkTotal', chunks.toString());

        if (chunkNumber == chunks - 1) {
          statusCallback(100, "Processing file...");
        }

        this.http.post(this.apiURL + "/" + accountId + "/account/data/create", formData, {
          observe: 'events',
          reportProgress: true,
          responseType: 'json',
          headers: this.getAuthHeaders()
        }).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              //this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event.type === HttpEventType.Response) {
              //this.message = 'Upload success.';
              //this.onUploadFinished.emit(event.body);
              //bytesUploaded = (chunkNumber/chunks) * file.size;
              const progress = Math.round((chunkNumber / chunks) * 100);
              statusCallback(progress, "Uploading " + file.name + " (" + progress + "%)");
              resolve(event);
            }
          },
          error => {
            //this.message = 'Upload error.';
            //this.fileName = null;
            reject(error);
          }
        );
      });
    };

    //loop through the chunks and upload them
    const chunksArray = [];
    for (let index = 0; index < chunks; index++) {
      const start = index * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);
      chunksArray.push(chunk);
    }

    //return the promise
    return new Promise<void>((resolve, reject) => {
      try {
        const uploadChunks = async () => {
          try {
            if (chunkNumber < chunksArray.length) {
              await uploadChunk(chunksArray[chunkNumber]);
              chunkNumber++;
              uploadChunks();
            } else {
              resolve();
            }
          } catch (err) {
            reject(err);
          }
        };
        uploadChunks();
      } catch (err) {
        reject(err);
      }
    });





    // const formData = new FormData();
    // formData.append('file', file);
    // formData.append('name', file.name);
    // formData.append('class', className);
    // formData.append('playlistItemId', playlistItemId > 0 ? playlistItemId.toString() : '0');
    // formData.append('parent', parentId.toString());

    // return this.http.post(this.apiURL + "/" + accountId + "/account/data/create", formData, {
    //   observe: 'events',
    //   reportProgress: true,
    //   responseType: 'json',
    //   headers: this.getAuthHeaders()
    // }).pipe(
    //   //retry(this.retries), // retry a failed request up to 3 times
    //   catchError(this.handleError.bind(this)) // then handle the error
    // );
  }

  updateData(accountId: number | null, data: Data): Observable<Data> {
    return this.http.post<Data>(this.apiURL + "/" + accountId + "/account/data/" + data.id, {
      name: data.name,
      class: data.selected_class?.name,
      parent: data.parent?.id,
      meta: data.meta
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteData(accountId: number | null, dataId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/data/" + dataId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  setDataGlobal(accountId: number | null, dataId: number, enabled: boolean) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/data/" + dataId + "/global", {
      enabled: enabled
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  copyData(accountId: number | null, dataId: number, selectedId: number) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/data/" + dataId + "/copy/" + selectedId, {
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getData(accountId: number | null, dataId: number): Observable<Data> {
    return this.http.get<Data>(this.apiURL + "/" + accountId + "/account/data/" + dataId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createFeed(accountId: number | null, name: string, adapter: string, meta: any): Observable<Data> {
    return this.http.post<Data>(this.apiURL + "/" + accountId + "/account/feed/create", {
      name: name,
      adapter: adapter,
      meta: meta
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateFeed(accountId: number | null, data: Data): Observable<Data> {
    return this.http.post<Data>(this.apiURL + "/" + accountId + "/account/feed/update/" + data.id, {
      name: data.name,
      meta: data.feed != null ? data.feed.meta : [],
      dataMeta: data.meta
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createColumn(accountId: number | null, dataId: number, column: DataColumn): Observable<DataColumn> {
    return this.http.post<DataColumn>(this.apiURL + "/" + accountId + "/account/data/column/" + dataId, column, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  removeColumn(accountId: number | null, dataId: number, columnId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/data/column/" + dataId + "/" + columnId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateColumnName(accountId: number | null, dataId: number, columnId: number, name: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/data/column/" + dataId + "/" + columnId + "/name", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateColumnField(accountId: number | null, dataId: number, columnId: number, fieldClass: string, fieldName: string, name: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/data/column/" + dataId + "/" + columnId + "/field", {
      fieldClass: fieldClass,
      fieldName: fieldName,
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  orderColumns(accountId: number | null, dataId: number, columns: DataColumn[]) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/data/column/" + dataId + "/order", {
      columns: columns
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getColumns(accountId: number | null, dataId: number): Observable<DataColumn[]> {
    return this.http.get<DataColumn[]>(this.apiURL + "/" + accountId + "/account/data/column/" + dataId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createRow(accountId: number | null, dataId: number, row: DataRowData): Observable<DataRow> {
    return this.http.post<DataRow>(this.apiURL + "/" + accountId + "/account/data/row/" + dataId, row, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateRow(accountId: number | null, dataId: number, row: DataRow): Observable<DataRow> {
    return this.http.post<DataRow>(this.apiURL + "/" + accountId + "/account/data/row/" + dataId + "/" + row._id, {
      data: row.data
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findRows(accountId: number | null, dataId: number, query: SearchInput): Observable<DataRowResult> {
    return this.http.post<DataRowResult>(this.apiURL + "/" + accountId + "/account/data/rows/" + dataId, query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteRow(accountId: number | null, dataId: number, rowId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/data/row/" + dataId + "/" + rowId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateRowOrder(accountId: number | null, dataId: number, rows: DataRow[]) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/data/row/" + dataId + "/order", {
      rows: rows
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createPlaylist(accountId: number | null, name: string): Observable<Playlist> {
    return this.http.post<Playlist>(this.apiURL + "/" + accountId + "/account/playlist", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findPlaylists(accountId: number | null, query: SearchInput): Observable<PlaylistResult> {
    return this.http.post<PlaylistResult>(this.apiURL + "/" + accountId + "/account/playlists", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getPlaylist(accountId: number | null, playlistId: number): Observable<Playlist> {
    return this.http.get<Playlist>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deletePlaylist(accountId: number | null, playlistId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updatePlaylist(accountId: number | null, playlist: Playlist): Observable<Playlist> {
    
    return this.http.post<Playlist>(this.apiURL + "/" + accountId + "/account/playlist/" + playlist.id + "/update", {
      name: playlist.name,
      meta: playlist.meta
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createPlaylistComposite(accountId: number | null, playlistId: number, name: string, order: number, time: number, data: Data[] = [], items_per_composite: number = 1): Observable<Composite> {
    let dataIds = data.map(d => d.id);
    return this.http.post<Composite>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/composite", {
      name: name,
      order: order,
      time: time,
      dataIds: dataIds,
      items_per_composite: items_per_composite
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deletePlaylistComposite(accountId: number | null, playlistId: number, compositeId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + compositeId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updatePlaylistComposite(accountId: number | null, playlistId: number, composite: Composite): Observable<Composite> {
    return this.http.post<Composite>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + composite.id + "/update", {
      name: composite.name,
      order: composite.order,
      autoVariation: composite.auto_variation,
      displayTime: composite.display_time,
      crossfade: composite.cross_fade,
      themeGroup: composite.themeGroup,
      themeName: composite.themeName,
      aspectRatio: composite.aspect_ratio,
      itemsPerComposite: composite.items_per_composite,
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createPlaylistCompositeDesign(accountId: number | null, playlistId: number, compositeId: number, designs: Design[]): Observable<Composite> {
    return this.http.post<Composite>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + compositeId + "/design", {
      designList: designs.map(design => design.id)
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deletePlaylistCompositeDesign(accountId: number | null, playlistId: number, compositeId: number, designId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + compositeId + "/" + designId + "/design", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createPlaylistSchedule(accountId: number | null, playlistId: number, name: string): Observable<Schedule> {
    return this.http.post(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/schedule", {
      name: name,
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findPlaylistSchedules(accountId: number | null, playlistId: number, query: SearchInput): Observable<ScheduleResult> {
    return this.http.post<ScheduleResult>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/schedules", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updatePlaylistSchedule(accountId: number | null, playlistId: number, schedule: Schedule): Observable<Schedule> {
    return this.http.post<Schedule>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/schedule/" + schedule.id, {
      name: schedule.name,
      startDate: schedule.start_date,
      endDate: schedule.end_date,
      startTime: schedule.start_time,
      endTime: schedule.end_time,
      allDay: schedule.all_day,
      monday: schedule.monday,
      tuesday: schedule.tuesday,
      wednesday: schedule.wednesday,
      thursday: schedule.thursday,
      friday: schedule.friday,
      saturday: schedule.saturday,
      sunday: schedule.sunday,
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deletePlaylistSchedule(accountId: number | null, playlistId: number, scheduleId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/schedule/" + scheduleId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createPlaylistCompositeData(accountId: number | null, playlistId: number, compositeId: number, data: Data[]): Observable<Composite> {
    return this.http.post<Composite>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + compositeId + "/data", {
      dataList: data.map(row => row.id)
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deletePlaylistCompositeData(accountId: number | null, playlistId: number, compositeId: number, dataId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + compositeId + "/" + dataId + "/data", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  reOrderPlaylistComposite(accountId: number | null, playlistId: number, compositeIds: number[]): Observable<Composite> {
    return this.http.post<Composite>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/order", {
      order: compositeIds
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 3 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addDisplayToPlaylistComposite(accountId: number | null, playlistId: number, displayId: number): Observable<Composite> {
    return this.http.post<Composite>(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/display", {
      display: displayId
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  removeDisplay(accountId: number | null, playlistId: number, displayId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/" + displayId + "/display", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDisplayStatus(accountId: number | null, uuid: string): Observable<any> {
    return this.http.get(this.apiURL + "/" + accountId + "/account/display/" + uuid + "/status", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDisplayOnlineStats(accountId: number | null, displayId: number): Observable<any> {
    return this.http.get(this.apiURL + "/" + accountId + "/account/display/" + displayId + '/stats/online', { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDisplaySensorStats(accountId: number | null, displayId: number): Observable<any> {
    return this.http.get(this.apiURL + "/" + accountId + "/account/display/" + displayId + '/stats/sensors', { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }


  testPlaylist(accountId: number | null, playlistId: number, compositeId: number) {
    return this.http.get(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/composite/" + compositeId + "/test", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  playPlayList(accountId: number | null, playlistId: number) {
    return this.http.get(this.apiURL + "/" + accountId + "/account/playlist/" + playlistId + "/play", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  linkDevice(accountId: number | null, displayId: number, code: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/display/" + displayId + "/link", {
      code: code
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  unlinkDevice(accountId: number | null, displayId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/display/" + displayId + "/unlink", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  listAccounts(query: SearchInput): Observable<AccountResult> {
    return this.http.post<AccountResult>(this.apiURL + "/accounts/list", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  listAccountRoles(accountId: number | null): Observable<AccountGroup[]> {
    return this.http.get<AccountGroup[]>(this.apiURL + "/" + accountId + "/account/roles", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      )
  }

  getAccount(accountId: number | null): Observable<Account> {
    return this.http.get<Account>(this.apiURL + "/" + accountId + "/account/info", { responseType: 'json', headers: this.getAuthHeaders() });
  }

  getAccountStats(accountId: number | null): Observable<AccountStats> {
    return this.http.get<AccountStats>(this.apiURL + "/" + accountId + "/account/stats", { responseType: 'json', headers: this.getAuthHeaders() });
  }

  updateAccountInfo(accountId: number | null, name: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/info", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addUserToAccount(accountId: number | null, email: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/user/add", {
      email: email
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  removeUserFromAccount(accountId: number | null, userId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/user/" + userId + "/remove", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getAccountInvites(accountId: number | null): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + "/" + accountId + "/account/invites", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteAccountInvite(accountId: number | null, inviteId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/invite/" + inviteId, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addRoleToUser(accountId: number | null, userId: number, roleId: number) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/user/" + userId + "/role/" + roleId + "/add", {}, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  removeRoleFromUser(accountId: number | null, userId: number, roleId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/user/" + userId + "/role/" + roleId + "/remove", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addAccountRole(accountId: number | null, name: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/role/add", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  removeAccountRole(accountId: number | null, roleId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/role/" + roleId + "/remove",
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateAccountRole(accountId: number | null, roleId: number, name: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/role/" + roleId + "/update", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  listRoles(accountId: number | null): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + "/" + accountId + "/account/roles/list",
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateUserRoles(accountId: number | null, userId: number, roles: number[]) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/user/" + userId + "/roles/update", {
      roles: roles
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  listPartnerAccounts(query: SearchInput): Observable<PartnerResult> {
    return this.http.post<PartnerResult>(this.apiURL + "/partners/list", query,
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createPartnerAccount(name: string) {
    return this.http.post(this.apiURL + "/admin/create", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  addAccountToPartnerAccount(partnerId: number, accountId: number) {
    return this.http.post(this.apiURL + "/" + partnerId + "/admin/account/" + accountId + "/add", {}, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  addUserToPartnerAccount(partnerId: number, email: string) {
    return this.http.post(this.apiURL + "/" + partnerId + "/admin/user/add", {
      email: email
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  removeUserFromPartnerAccount(partnerId: number, userId: number) {
    return this.http.delete(this.apiURL + "/" + partnerId + "/admin/user/" + userId + "/remove", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  addRoleToPartnerUser(partnerId: number, userId: number, roleId: number) {
    return this.http.post(this.apiURL + "/" + partnerId + "/admin/user/" + userId + "/role/" + roleId + "/add", {}, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  removeRoleFromPartnerUser(partnerId: number, userId: number, roleId: number) {
    return this.http.delete(this.apiURL + "/" + partnerId + "/admin/user/" + userId + "/role/" + roleId + "/remove", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  addPartnerRole(partnerId: number, name: string) {
    return this.http.post(this.apiURL + "/" + partnerId + "/admin/role/add", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  removePartnerRole(partnerId: number, roleId: number) {
    return this.http.delete(this.apiURL + "/" + partnerId + "/admin/role/" + roleId + "/remove",
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerListAccounts(partnerId: number, query: SearchInput): Observable<AccountResult> {
    return this.http.post<AccountResult>(this.apiURL + "/" + partnerId + "/admin/account/list", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  partnerCreateAccount(partnerId: number, name: string) {
    return this.http.post(this.apiURL + "/" + partnerId + "/admin/account/create", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerAccountInfo(partnerId: number, accountId: number): Observable<Account> {
    return this.http.get<Account>(this.apiURL + "/" + partnerId + "/admin/account/" + accountId + "/info", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerAccountStats(partnerId: number, accountId: number): Observable<AccountStats> {
    return this.http.get<AccountStats>(this.apiURL + "/" + partnerId + "/admin/account/" + accountId + "/stats", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerAccountAuditLogs(partnerId: number, accountId: number, query: SearchInput): Observable<any[]> {
    return this.http.post<any[]>(this.apiURL + "/" + partnerId + "/admin/account/" + accountId + "/audit/logs", query, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerAccountUpdate(partnerId: number, accountId: number, name: string, allowedDisplays: number, allowedFeeds: number, allowedMB: number) {
    return this.http.post(this.apiURL + "/" + partnerId + "/admin/account/" + accountId + "/update", {
      name: name,
      allowedDisplays: allowedDisplays,
      allowedFeeds: allowedFeeds,
      allowedMB: allowedMB
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerAccountDelete(partnerId: number, accountId: number) {
    return this.http.delete(this.apiURL + "/" + partnerId + "/admin/account/" + accountId + "/delete",
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this))
      );
  }

  partnerListAccountRoles(parentId: number | null): Observable<PartnerGroup[]> {
    return this.http.get<PartnerGroup[]>(this.apiURL + "/" + parentId + "/admin/roles", { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      )
  }

  partnerUpdateUserRoles(parentId: number | null, userId: number, roles: number[]) {
    return this.http.post(this.apiURL + "/" + parentId + "/admin/user/" + userId + "/roles/update", {
      roles: roles
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updatePartnerInfo(parentId: number | null, name: string) {
    return this.http.post(this.apiURL + "/" + parentId + "/admin/info", {
      name: name
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  createFirmware(accountId: number | null, hardwareTypes: number[], postScript: string, script: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('hardwareTypes', JSON.stringify(hardwareTypes));
    formData.append('postScript', postScript);
    formData.append('script', script);

    return this.http.post(this.apiURL + "/" + accountId + "/account/hardware/firmware/create", formData, {
      observe: 'events',
      reportProgress: true,
      responseType: 'json',
      headers: this.getAuthHeaders()
    }).pipe(
      //retry(this.retries), // retry a failed request up to 3 times
      catchError(this.handleError.bind(this)) // then handle the error
    );
  }

  updateFirmwareStatus(accountId: number | null, firmwareId: number, status: number) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/hardware/firmware/" + firmwareId + "/update/status", {
      status: status
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateFirmwareScripts(accountId: number | null, firmwareId: number, postScript: string, script: string) {
    return this.http.post(this.apiURL + "/" + accountId + "/account/hardware/firmware/" + firmwareId + "/update/scripts", {
      postScript: postScript,
      script: script
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  removeFirmware(accountId: number | null, firmwareId: number) {
    return this.http.delete(this.apiURL + "/" + accountId + "/account/hardware/firmware/" + firmwareId + "/remove",
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  listFirmware(accountId: number | null, query: SearchInput): Observable<any[]> {
    return this.http.post<any[]>(this.apiURL + "/" + accountId + "/account/hardware/firmware/list", query,
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  listHardwareTypes(accountId: number | null, query: SearchInput): Observable<any[]> {
    return this.http.post<any[]>(this.apiURL + "/" + accountId + "/account/hardware/list/types", query,
      { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findPixabayImages(accountId: number, query: string, imageType: string, orientation: string, category: string, colors: string, page: number, limit: number): Observable<PixabayResult> {
    return this.http.post<PixabayResult>(this.apiURL + "/" + accountId + "/account/pixabay/search", {
      query: query,
      imageType: imageType,
      orientation: orientation,
      category: category,
      colors: colors,
      page: page,
      limit: limit
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  findFeedWeatherAutoComplete(query: string): Observable<weatherAutoComplete[]> {
    return this.http.post<weatherAutoComplete[]>(this.apiURL + "/feed/weather/autocomplete", {
      query: query
    }, { responseType: 'json', headers: this.getAuthHeaders() })
      .pipe(
        //retry(this.retries), // retry a failed request up to 2 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  isDeviceOnline(id: string) {
    return this.http.get('https://live.display-link.nl/device/' + id + '/status', { responseType: 'json' })
      .pipe(
        //retry(this.retries), // retry a failed request up to 1 times
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {

    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);

      if (error.status === 401) {
        let thisIs = this;
        thisIs.onSessionStop();
      } else if (error.status === 422) {
        return throwError(error.error[Object.keys(error.error)[0]][0]);
      } else if (typeof error.error == 'string') {
        return throwError(error.error);
      }
    }
    let errorMessage = 'Something bad happened; please try again later.';
    try {
      errorMessage = Array.isArray(error.error) ? error.error[0] : 'Something bad happened; please try again later.';
    } catch (e) {
    }
    // Return an observable with a user-facing error message.
    return throwError(errorMessage);
  }
}
