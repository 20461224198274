<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="cancel()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="flex flex-col gap-2">
              <input pInputText style="width: 25em; margin-top: 1em;" [(ngModel)]="data.name" placeholder="Name">
              <input pInputText style="width: 25em; margin-top: 1em;" [(ngModel)]="data.meta.url" placeholder="Website URL">

              <div>
                <div style="display: flex;">
                    <div style="align-self: center;">Reset timeout (seconds):</div> 
                    <input pInputText style="width: 5em" type="number" [(ngModel)]="data.meta.resetTimer" >
                </div>
                <div class="flex flex-col gap-3">
                    <div>Allowed filter:</div>
                    <div class="flex flex-col gap-3">
                        <div class="flex gap-3" *ngFor="let urlVal of data.meta.allowedUrls; let i = index">
                            <input pInputText class="w-full" [value]="urlVal" (change)="onUpdate(i, $event.target.value)" placeholder="Allow urls *google.com* or * for any">
                            <p-button class="w-24" styleClass="w-24" label="Remove" [outlined]="true" (click)="removeURL(i)" />
                        </div>
                        <div class="flex gap-3">
                            <input pInputText class="w-full" [(ngModel)]="newUrl" placeholder="Allow urls *google.com* or * for any">
                        
                            <p-button class="w-24" styleClass="w-24" [outlined]="true" (click)="addURL()">
                                <ng-icon name="saxAddOutline" size="20"></ng-icon>Add
                            </p-button>
                        </div>
                    </div>
                </div>
              </div>            
        </div>
        <div class="actions pt-3">
            <p-button label="Cancel" [outlined]="true" (click)="cancel()" />
            <p-button label="Save" (click)="save()" />
        </div>
    </div>
</div>