<div class="modal-head">
    <div class="modal-title">{{data.title}}</div>
    <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" mat-dialog-close></ng-icon>
</div>
<div mat-dialog-content class="dialog-content">
    <div>
        <ng-icon name="saxTrashOutline" size="70"></ng-icon>
    </div>
    {{data.message}}
</div>
<div mat-dialog-actions class="flex gap-3 w-full !flex-nowrap">
    <p-button class="w-full" styleClass="w-full" label="No" [outlined]="true" mat-dialog-close />
    <p-button class="w-full" styleClass="w-full" label="Yes" mat-dialog-close [mat-dialog-close]="true" />
</div>