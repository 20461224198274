<div class="modal-main feed-view" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="table-top-buttons">
            <div>
                <div class="tab-header">
                    <div class="tab-index">
                        <div class="tab-item" (click)="isTab = 'feed'" [class.tab-item-selected]="isTab == 'feed'">Feed</div>
                        <div class="tab-item" (click)="isTab = 'settings'" [class.tab-item-selected]="isTab == 'settings'">Settings</div>
                    </div>
                </div>
            </div>
            <div class="feed-buttons" *ngIf="isTab == 'feed' && !isFeedLink">
                <button class="action-button" pButton *ngIf="!isFeed"severity="secondary" (click)="edit()">
                    <ng-icon name="saxFatrowsOutline" size="20"></ng-icon>Edit columns</button>
                <button class="action-button" pButton *ngIf="!isFeed"severity="secondary" (click)="create()">
                    <ng-icon name="saxAddOutline" size="20"></ng-icon>Add item</button>

                <p-popover class="popover-el" #opFeed>
                    <div class="popover-buttons flex flex-col">
                        <button class="action-button" severity="secondary" pButton (click)="deleteSelected()"><ng-icon name="saxTrashOutline" size="20"></ng-icon>Delete</button>
                    </div>
                </p-popover>
                <button class="more-button" styleClass="more-button" pButton (click)="opFeed.toggle($event)" severity="secondary" *ngIf="!isFeed">
                    <ng-icon style="transform: rotate(90deg);" name="saxMoreOutline" size="25"></ng-icon>
                </button>
            </div>
        </div>

        <p-progressbar mode="indeterminate" [style]="{ height: '6px' }" [style.opacity]="isLoadingResults ? 1 : 0" />
        
        <div class="mat-elevation-z8 table-view" *ngIf="isTab == 'feed'">
            <table class="table table-data" cdkDropList mat-table [dataSource]="dataSource" [trackBy]="trackByData" (cdkDropListDropped)="drop($event)">

                <ng-container [matColumnDef]="column.index" *ngFor="let column of columns; let i = index">
                    <th mat-header-cell *matHeaderCellDef [disabled]="column.index == 'select' || column.index == 'actions'">
                        <p-checkbox  tabindex="-1"  class="checkbox" styleClass="checkbox" [ngClass]="{'select-all-checkbox': !isFeed}" *ngIf="column.index == 'select' && !isFeed" (change)="$event ? toggleAllRows() : null" [ngModel]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                    [binary]="true" />
                        {{column.index == 'select' || column.index == 'actions' && isFeed ? '' : column.name}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="select-drag" *ngIf="column.index == 'select' && !isFeed">
                            <mat-icon class="drag-icon" cdkDragHandle *ngIf="!isFeed">drag_indicator</mat-icon>
                            <p-checkbox tabindex="-1" class="checkbox checkbox-td" styleClass="checkbox" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [ngModel]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)"
                            [binary]="true" />
                        </div>

                        <div *ngIf="column.index !='select' && column.index !='actions'" (mousedown)="$event.stopPropagation()">
                            <div class="edit-media" *ngIf="column.fieldName == 'Video' || column.fieldName == 'Image'" (click)="selectMedia(column.fieldName, element, i-1)">
                                <div *ngIf="element.data[i-1].selected_class && element.data[i-1].selected_class.name =='Image'">
                                    <img crossorigin="anonymous" class="thumb-image" [src]="element.data[i-1].meta.url_thumb" />
                                </div>
                                <div *ngIf="element.data[i-1].selected_class && element.data[i-1].selected_class.name =='Video'">
                                    <img crossorigin="anonymous" class="thumb-image" [src]="element.data[i-1].meta.url_thumb" />
                                </div>
                                <ng-icon *ngIf="!isFeed && !element.data[i-1].selected_class"  name="saxEdit2Outline" size="25" tabindex="-1" ></ng-icon>
                            </div>
                            <div class="mr-2" *ngIf="column.index != 'mutationDate' && column.fieldName != 'Video' && column.fieldName != 'Image'" (input)="onItemChange($event, element, i-1)" pInputText [textContent]="element.data[i-1]" [attr.contenteditable]="!isFeed ? 'true' : 'false'" style="display: block;"></div>
                            <span *ngIf="column.index == 'mutationDate'">{{element.data[i] | date }}</span>
                        </div>

                        <div *ngIf="column.index == 'actions' && !isFeed" class="actions-list flex gap-1 justify-end mr-4">
                            <ng-icon class="cursor-pointer"  name="saxTrashOutline" size="25" tabindex="-1" (click)="delete(element)"></ng-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr cdkDrag [cdkDragDisabled]="isFeed" mat-row *matRowDef="let row; columns: columnsToDisplay;" class="feed-row"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="50" *ngIf="!isLoadingResults">
                        <div class="noresults">
                            <span *ngIf="searchQuery">No results found. Please try a different search.</span>
                            <span *ngIf="searchQuery == '' && !dataSelect">
                            Click <button *ngIf="columns.length <= 2"  pButton (click)="edit()">Create columns</button> <button *ngIf="columns.length > 2"  pButton (click)="create()">Create New item</button> to get started!
                            </span>
                        </div>
                    </td>
                </tr>
            </table>

            <div class="row flex justify-end mt-4">
                <p-paginator class="paginator" styleClass="paginator" [rows]="pageSize" [totalRecords]="resultsLength" (onPageChange)="onPageChange($event)">
                </p-paginator>
            </div>
        </div>
        <div class="mat-elevation-z8 table-parent" *ngIf="isTab != 'feed'">
            <table class="table">
                <tr>
                    <td style="width: 10em;">Name:</td>
                    <td style="float: left;"><input pInputText class="form-control feed-name" placeholder="Name" [(ngModel)]="data.name" /></td>
                </tr>
                <tr *ngIf="data.feed">
                    <td style="width: 10em;">Last update:</td>
                    <td><span style="float: left;">{{getLastUpdateHuman(data.feed.mutationDate)}}</span></td>
                </tr>
                <tr *ngIf="data.feed_link">
                    <td style="width: 10em;">Last update:</td>
                    <td><span style="float: left;">{{getLastUpdateHuman(data.feed_link.mutationDate)}}</span></td>
                </tr>
                <tr>
                    <td style="width: 10em;">Tags:</td>
                    <td >
                        <div class="tags-list">
                            <div *ngFor="let tag of tags; let i2 = index" >
                                <span class="tag flex items-center"><input pInputText class="form-control" [(ngModel)]="tag.value" /><ng-icon class="cursor-pointer"  name="saxTrashOutline" size="25" tabindex="-1" (click)="tags.splice(i2,1)"></ng-icon></span>
                            </div>
                            <p-button [outlined]="true" label="Add tag" (click)="addTag()" />
                        </div>
                    </td>
                </tr>
                <tr *ngIf="data.feed && data.feed.meta.lastErrors">
                    <td>Last error:</td>
                    <td style="float: left;">
                        <span *ngIf="data.feed.meta.lastErrors.length == 0">No errors</span>
                        <span *ngIf="data.feed.meta.lastErrors.length > 0">{{data.feed.meta.lastErrors[0].message}}</span>
                    </td>
                </tr>
            </table>
            <div>
                <p-button label="Save" (click)="save()" />
            </div>
        </div>
    </div>
    <!-- <div class="actions">
        <button mat-button (click)="close()">Close</button>
    </div> -->
</div>

<div class="edit-container">
    <app-data-feed-edit (onDataChange)="onEdit($event)" *ngIf="selected.selected" [data]="data"></app-data-feed-edit>
    <app-media-select *ngIf="editorService.overlay.drive" [playlistId]="data.id" [isEditor]="false" (onCloseMedia)="onCloseMedia($event)" (onSelectMedia)="onSelectMedia($event)" [dataType]="editorService.overlay.driveTypes"></app-media-select>
</div>