<div class="playlist-main">
    <div class="typeTitle">
        <input pAutoFocus [autofocus]="true" (blur)="editTitle = false" *ngIf="editTitle" type="text" pInputText id="playlistName" [(ngModel)]="playlist.name" (change)="updatePlaylist()">
        <div *ngIf="!editTitle">{{playlist.name}}
            <ng-icon (click)="editTitle = true" name="saxEdit2Outline" size="20" class="cursor-pointer"></ng-icon>
        </div>
    </div>
    <div class="playlist-body">
        <div class="tab-header">
            <div class="tab-index">
                <div class="tab-item" (click)="selectedTab = 0;" [class.tab-item-selected]="selectedTab === 0">Playlist</div>
                <div class="tab-item" (click)="selectedTab = 1;" [class.tab-item-selected]="selectedTab === 1">Schedule</div>
                <div class="tab-item" (click)="selectedTab = 2;" [class.tab-item-selected]="selectedTab === 2">Displays</div>
            </div>
        </div>
        <!-- Playlist -->
        <div class="tab-1" *ngIf="selectedTab === 0">
            <!-- Playlist -->
            <div class="list-block">
                <div class="playlist-action-bar">
                    <div class="flex gap-3 items-center">
                        <div class="title">Playlist</div>
                        <button pButton [outlined]="true" *ngIf="this.selectedComposite" (click)="this.selectedComposite = null">Deselect</button>
                    </div>
                    <div>
                        <div class="flex gap-3 items-center">
                            <input pInputText style="width: 5em;" *ngIf="this.selectedComposite" type="number" class="form-control" id="compositeTime" [(ngModel)]="this.selectedComposite.display_time" (change)="updateComposite()">
                            <select class="form-select" *ngIf="this.selectedComposite" [(ngModel)]="this.selectedComposite.cross_fade" (change)="updateComposite()">
                                <option [ngValue]="true">Synchronous animation</option>
                                <option [ngValue]="false">Asynchronous animation</option>
                            </select>
                            <button pButton severity="contrast" [outlined]="true" (click)="editAnimations()">Animations</button>
                            <button *ngIf="this.selectedComposite" pButton [outlined]="true" severity="contrast" (click)="removeComposite(selectedComposite)"><ng-icon name="saxMinusOutline" size="20"></ng-icon>Remove</button>
                        </div>
                    </div>
                </div>
                <div class="composite-container-scroll">
                    <div class="composite-container" cdkDropList cdkDropListOrientation="mixed" id="playlistDropList" [cdkDropListData]="this.commonService.playlistItems" [cdkDropListConnectedTo]="['driveDropList']" (cdkDropListDropped)="drop($event)" (cdkDropListEntered)="drag($event)" (cdkDropListExited)="dragEnd($event)">
                        <div class="composite-item" [ngStyle]="{'background-image': composite.meta.images ? 'url('+composite.meta.images[0]+')' : 'unset'}" (click)="selectComposite(composite)" [class.selected]="selectedComposite && selectedComposite.id == composite.id" *ngFor="let composite of playlist.composites"
                            cdkDrag>
                            <div title="{{composite.name}}">
                                {{composite.name}}
                            </div>
                        </div>
                        <div class="playlist-add-items-tip" *ngIf="playlist.composites.length == 0">Add media from your drive/design library by double clicking on it or selecting multiple and adding it.</div>
                        <!-- <div class="composite-item-new" (click)="addComposite()">
                            <div>Add</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- drive -->
            <div class="list-block" *ngIf="selectedComposite">
                <div class="playlist-action-bar">
                    <div class="title">Drive</div>
                    <div class="flex gap-3 items-center">
                        <div>Items per slide:</div>
                        <input pInputText style="width: 5em;" *ngIf="this.selectedComposite" type="number" class="form-control" [(ngModel)]="this.selectedComposite.items_per_composite" (change)="updateComposite()">
                        <button pButton severity="contrast" [outlined]="true" (click)="addData()"><ng-icon name="saxAddOutline" size="20"></ng-icon>Add</button>
                        <button pButton severity="contrast" [outlined]="true" (click)="removeData(selectedData)"><ng-icon name="saxMinusOutline" size="20"></ng-icon>Remove</button>
                    </div>
                </div>
                <div class="drive-container-scroll">
                    <div class="drive-container">
                        <div [title]="item.data.name" [style.background-image]="item.data.selected_class.name == 'Image' || item.data.selected_class.name == 'Video' ? 'url(' + item.data.meta.url_thumb + ')' : ''" class="folder-file" *ngFor="let item of selectedComposite.data_stream"
                            [class.selected]="selectedData && selectedData.id == item.id" (click)="selectData(item)">
                            <div class="data-icon" *ngIf="item.data.selected_class.name != 'Video' && item.data.selected_class.name != 'Image'">
                                <ng-icon *ngIf="item.data.selected_class.name == 'Folder'" name="saxFolderOutline" size="40"></ng-icon>
                                <ng-icon *ngIf="item.data.selected_class.name != 'Folder'" name="sax3squareOutline" size="40"></ng-icon>
                            </div>
                            <div class="data-icon" *ngIf="item.data.selected_class.name == 'Video' || item.data.selected_class.name == 'Image'"></div>
                            <div class="creating-screenshot-status" *ngIf="item.data.selected_class.name == 'Image' && !item.data.meta.url_thumb && item.data.feed">
                                Generating screenshot
                            </div>
                            <div class="optimizing-error" *ngIf="item.data.meta?.needToTranscode && item.data.meta?.transcodeStatus == 'job.failed'">Error optimizing video</div>
                            <div class="optimizing-status" *ngIf="item.data.meta?.needToTranscode && item.data.meta?.transcodeStatus != 'job.failed' && item.data.meta?.transcodeStatus != 'job.completed'">Optimizing video...</div>
                            <div class="name">
                                <div class="name-value">{{item.data.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- designs -->
            <div class="list-block" *ngIf="selectedComposite">
                <div class="playlist-action-bar">
                    <div class="flex gap-3 items-center">
                        <div class="title">Designs</div>
                        <div>
                            <select class="form-select" *ngIf="this.selectedComposite" [(ngModel)]="this.selectedComposite.auto_variation" (change)="updateComposite()">
                                <option [ngValue]="true">Auto Design Selection</option>
                                <option [ngValue]="false">Custom Design Selection</option>
                            </select>
                        </div>
                        <div>
                            <select class="form-select" *ngIf="this.selectedComposite && this.selectedComposite.auto_variation" [(ngModel)]="this.selectedComposite.aspect_ratio" (change)="updateComposite()">
                                <option [ngValue]="0">Auto</option>
                                <option class="upperCaseFirstLetter" *ngFor="let aspectRatio of aspectRatios" [ngValue]="aspectRatio.aspectRatio">{{aspectRatio.orientation}} designs ({{aspectRatio.aspectRatioReadable}})</option>
                            </select>
                        </div>
                        <div class="theme-selector" *ngIf="this.selectedComposite && this.selectedComposite.auto_variation">
                            <select class="form-select" [(ngModel)]="this.selectedComposite.themeGroup" (change)="onThemeGroupChange()">
                                <option [value]="''">All categories</option>
                                <option *ngFor="let themeGroup of themeGroups" [value]="themeGroup">{{themeGroup}}</option>
                            </select>

                            <select *ngIf="this.selectedComposite.themeGroup != ''" class="form-select" [(ngModel)]="this.selectedComposite.themeName" (change)="onThemeChange()">
                                <option [value]="''">All themes</option>
                                <option *ngFor="let theme of themes" [value]="theme">{{theme}}</option>
                            </select>
                        </div>
                        <div class="spinner-border" role="status" *ngIf="lodingTest">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div class="flex gap-3">
                        <button [outlined]="true" severity="contrast" *ngIf="this.selectedComposite && !this.selectedComposite.auto_variation" pButton (click)="addDesign()"><ng-icon name="saxAddOutline" size="20"></ng-icon>Add</button>
                        <button [outlined]="true" severity="contrast" *ngIf="this.selectedComposite && !this.selectedComposite.auto_variation" pButton (click)="removeDesign(selectedDesign)"><ng-icon name="saxMinusOutline" size="20"></ng-icon>Remove</button>
                        <!-- <button pButton (click)="test()">Test</button> -->
                    </div>
                </div>
                <div class="drive-container-scroll">
                    <div class="drive-container" *ngIf="!this.selectedComposite.auto_variation">
                        <div class="folder-file playlistDesign" title="{{variation.design.selectionError ? 'This is a dynamic design. Please add something from your drive.' : ''}}" [class.selectionError]="variation.design.selectionError" [class.selected]="selectedDesign && selectedDesign.id == variation.id"
                            (click)="selectDesign(variation)" *ngFor="let variation of selectedComposite.variations">
                            <img crossorigin="anonymous" class="{{variation.design.data.orientation}}" *ngIf="variation.design.data.screenshot != null" [src]="variation.design.data.screenshot">
                            <div class="name">
                                {{variation.design.name}}
                            </div>
                        </div>
                    </div>
                    <div class="drive-container" *ngIf="this.selectedComposite.auto_variation">
                        <div class="folder-file playlistDesign" style="cursor: unset;" *ngFor="let design of autoDesigns">
                            <img crossorigin="anonymous" class="{{design.data.orientation}}" *ngIf="design.data.screenshot != null" [src]="design.data.screenshot">
                            <div class="name">
                                {{design.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Inline selector -->
            <div class="list-block" *ngIf="!selectedComposite">
                <div class="tab-index">
                    <div class="tab-item" (click)="selectorType = 'drive'" [class.tab-item-selected]="selectorType === 'drive'">Drive</div>
                    <div class="tab-item" (click)="selectorType = 'design'" [class.tab-item-selected]="selectorType === 'design'">Designs</div>
                </div>
                <div class="inline-selector-container">
                    <app-data-table *ngIf="selectorType == 'design'" [dataSelect]="true" (onDataSelect)="onDataSelect($event)" [dataClass]="dataClassDesigns" (onTableDataChange)="onTableDataChange($event)"></app-data-table>
                    <app-data-folder *ngIf="selectorType == 'drive'" [dataSelect]="true" (onDataSelect)="onDataFolderSelect($event)" [dataClass]="dataClassData" (onTableDataChange)="onTableDataChange($event)"></app-data-folder>
                </div>
            </div>
        </div>
        <!-- Schedule -->
        <div class="tab-2" *ngIf="selectedTab === 1">
            <div class="playlist-schedule-container">
                <div class="playlist-schedule-list">
                    <table class="table schedule-table" *ngFor="let schedule of playlist.schedules">
                        <tbody>
                            <tr>
                                <td>Schedule Name</td>
                                <td>
                                    <input pInputText ngDefaultControl (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" [(ngModel)]="schedule.name">
                                </td>
                            </tr>
                            <tr>
                                <td>Start Date</td>
                                <td>
                                    <input pInputText ngDefaultControl (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="date" [(ngModel)]="schedule.start_date" />
                                </td>
                            </tr>
                            <tr>
                                <td>End Date</td>
                                <td>
                                    <input pInputText ngDefaultControl (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="date" [(ngModel)]="schedule.end_date" />
                                </td>
                            </tr>
                            <tr>
                                <td>All Day</td>
                                <td>
                                    <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.all_day" />
                                </td>
                            </tr>
                            <tr>
                                <td>Start Time</td>
                                <td>
                                    <input pInputText ngDefaultControl [disabled]="schedule.all_day" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="time" [(ngModel)]="schedule.start_time" />
                                </td>
                            </tr>
                            <tr>
                                <td>End Time</td>
                                <td>
                                    <input pInputText ngDefaultControl [disabled]="schedule.all_day" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="time" [(ngModel)]="schedule.end_time" />
                                </td>
                            </tr>
                            <tr>
                                <td>Days of the Week</td>
                                <td>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.monday" /> Monday
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.tuesday" /> Tuesday
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.wednesday" /> Wednesday
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.thursday" /> Thursday
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.friday" /> Friday
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.saturday" /> Saturday
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p-checkbox [binary]="true" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.sunday" /> Sunday
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <button pButton [outlined]="true" (click)="removeSchedule(schedule)">Remove schedule</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="add-schedule-button">
                        <button pButton (click)="addSchedule()">Add schedule</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Displays -->
        <div class="tab-3" *ngIf="selectedTab === 2">
            <div class="playlist-device-container">
                <div class="playlist-device-list flex flex-col items-center">
                    <table class="table playlist-device-table" *ngIf="playlist.displays.length > 0">
                        <thead>
                            <tr>
                                <td>Display name</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let display of playlist.displays">
                                <td style="width: 75%;">{{display.name}}</td>
                                <td><button pButton [outlined]="true" severity="warn" (click)="removeDisplay(display)">Remove display</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="w-32 mt-4" pButton (click)="addDisplay()">Add display</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-selector *ngIf="dataSelectOpen" (onCloseMedia)="onCloseMedia($event)" [playlist]="playlist" [composite]="selectedComposite"></app-data-selector>
<app-design-selector *ngIf="designSelectOpen" (onCloseDesigns)="onCloseDesigns($event)" [playlist]="playlist" [composite]="selectedComposite"></app-design-selector>
<app-display-selector *ngIf="displaySelectOpen" (onCloseDisplays)="onCloseDisplays($event)" [playlist]="playlist" [composite]="selectedComposite"></app-display-selector>

<div class="loading-adding" *ngIf="loading">
    <div>
        <div>Adding item(s) to playlist</div>
        <p-progress-spinner animationDuration=".5s" [style]="{ width: '50px', height: '50px' }" />
    </div>
</div>