import { Component } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent {
  currentStep = 1; //max 3 steps

  constructor(private apiService: ApiService, private authService: AuthService) {
    authService.oAuthCheck();
  }

  nextStep() {
    this.currentStep++;
  }

  previousStep() {
    if (this.currentStep === 1) {
      return;
    }
    this.currentStep--;
  }

  skipOnboarding() {
    this.apiService.saveSettings("onboarding", {
      skip: true
    }).subscribe(() => {
      window.location.href = '/login';
    });
  }

  // This function is called when the user clicks the "Finish" button or skips the onboarding process
  finishOnboarding() {
    this.apiService.saveSettings("onboarding", {
      skip: false
    }).subscribe(() => {
      window.location.href = '/login';
    });
  }
}
