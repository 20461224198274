export class TrieNode {
  constructor(char, data) {
    this.char = char;
    this.data = data;
    //store children using char => trieNode map
    //the char "*" indicates this node represents the end of a word
    this.nextChars = new Map();
  }
  getNodeChar() {
    return this.char;
  }
  /**
   * Get the data this node represents
   */
  getNodeData() {
    return this.data;
  }
  setNodeData(data) {
    this.data = data;
  }
  getNumNextChars() {
    return this.nextChars.size - (this.isEndOfWord() ? 1 : 0);
  }
  /**
   * Check if this node points to "char" as the next char
   */
  hasNextCharNode(char) {
    return this.nextChars.has(char);
  }
  /**
   * Get the node reference for "char"
   */
  getNextCharNode(char) {
    return this.nextChars.get(char);
  }
  /**
   * Get all next char nodes
   */
  getAllNextCharNodes() {
    const nodes = [];
    for (const [char, node] of this.nextChars) {
      if (char !== "*") {
        nodes.push(node);
      }
    }
    return nodes;
  }
  /**
   * Add a char reference to this node
   */
  addNextChar(char) {
    this.nextChars.set(char, new TrieNode(char));
  }
  /**
   * Delete a reference to a next character
   */
  removeNextChar(char) {
    this.nextChars.delete(char);
  }
  /**
   * Check if this corresponds to the end of a word
   */
  isEndOfWord() {
    return this.nextChars.has("*");
  }
  /**
   * Mark the current node as the ending of a word
   */
  markAsEndOfWord() {
    this.nextChars.set("*", null);
  }
  /**
   * Unmark the current node as the ending of a word
   */
  unmarkAsEndOfWord() {
    this.nextChars.delete("*");
  }
  /**
   * Check if this node is the end of a word
   * or has outgoing connections
   */
  isEmpty() {
    return this.nextChars.size === (this.isEndOfWord() ? 1 : 0);
  }
}