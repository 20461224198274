<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Animations</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" mat-dialog-close></ng-icon>
    </div>
    <div class="modal-body">
        <div class="animation-container">
            <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-4">
                    <label class="form-label">Animation In</label>
                    <select [(ngModel)]="animationIn" class="form-select">
                        <option *ngFor="let animation of animations" [value]="animation">{{animation}}</option>
                    </select>
                    <select *ngIf="animationIn != 'auto' && animationIn != 'none'" class="form-select" [(ngModel)]="animationCurveIn">
                        <option [value]="'linear'">Smooth</option>
                        <option [value]="'fastOutSlowIn'">Quick Start, Gentle Finish</option>
                        <option [value]="'slowMiddle'">Slow in the Middle</option>
                        <option [value]="'bounceIn'">Bounce In</option>
                        <option [value]="'bounceOut'">Bounce Out</option>
                        <option [value]="'bounceInOut'">Bounce In and Out</option>
                        <option [value]="'elasticIn'">Elastic In</option>
                        <option [value]="'elasticOut'">Elastic Out</option>
                        <option [value]="'elasticInOut'">Elastic In and Out</option>
                        <option [value]="'ease'">Smooth Ease</option>
                        <option [value]="'easeIn'">Ease In (Slow Start)</option>
                        <option [value]="'easeInToLinear'">Ease In to Smooth</option>
                        <option [value]="'easeInSine'">Ease In (Soft Sine)</option>
                        <option [value]="'easeInQuad'">Ease In (Gradual Quad)</option>
                        <option [value]="'easeInCubic'">Ease In (Slightly Faster)</option>
                        <option [value]="'easeInQuart'">Ease In (Faster Start)</option>
                        <option [value]="'easeInQuint'">Ease In (Quick Start)</option>
                        <option [value]="'easeInExpo'">Ease In (Accelerated Start)</option>
                        <option [value]="'easeInCirc'">Ease In (Circular)</option>
                        <option [value]="'easeInBack'">Ease In (Overshoot)</option>
                        <option [value]="'easeOut'">Ease Out (Smooth Finish)</option>
                        <option [value]="'linearToEaseOut'">Smooth to Ease Out</option>
                        <option [value]="'easeOutSine'">Ease Out (Soft Sine)</option>
                        <option [value]="'easeOutQuad'">Ease Out (Gradual Quad)</option>
                        <option [value]="'easeOutCubic'">Ease Out (Slightly Faster)</option>
                        <option [value]="'easeOutQuart'">Ease Out (Faster Finish)</option>
                        <option [value]="'easeOutQuint'">Ease Out (Quick Finish)</option>
                        <option [value]="'easeOutExpo'">Ease Out (Accelerated Finish)</option>
                        <option [value]="'easeOutCirc'">Ease Out (Circular)</option>
                        <option [value]="'easeOutBack'">Ease Out (Overshoot)</option>
                        <option [value]="'easeInOut'">Ease In and Out</option>
                        <option [value]="'easeInOutSine'">Ease In and Out (Soft Sine)</option>
                        <option [value]="'easeInOutQuad'">Ease In and Out (Gradual)</option>
                        <option [value]="'easeInOutCubic'">Ease In and Out (Slightly Faster)</option>
                        <option [value]="'easeInOutQuart'">Ease In and Out (Faster)</option>
                        <option [value]="'easeInOutQuint'">Ease In and Out (Quick)</option>
                        <option [value]="'easeInOutExpo'">Ease In and Out (Accelerated)</option>
                        <option [value]="'easeInOutCirc'">Ease In and Out (Circular)</option>
                        <option [value]="'easeInOutBack'">Ease In and Out (Overshoot)</option>
                    </select>
                    
                    <p-floatlabel variant="on" *ngIf="animationIn != 'auto' && animationIn != 'none'">
                        <label for="animation-in-duration" class="form-label">Animation duration (ms)</label>
                        <input pInputText class="form-control w-full" id="animation-in-duration" [(ngModel)]="animationDurationIn" type="number">
                    </p-floatlabel>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="form-label">Animation Out</label>
                    <select [(ngModel)]="animationOut" class="form-select">
                        <option *ngFor="let animation of animations" [value]="animation">{{animation}}</option>
                    </select>
                    <select *ngIf="animationOut != 'auto' && animationOut != 'none'" class="form-select" [(ngModel)]="animationCurveOut">
                        <option [value]="'linear'">Smooth</option>
                        <option [value]="'fastOutSlowIn'">Quick Start, Gentle Finish</option>
                        <option [value]="'slowMiddle'">Slow in the Middle</option>
                        <option [value]="'bounceIn'">Bounce In</option>
                        <option [value]="'bounceOut'">Bounce Out</option>
                        <option [value]="'bounceInOut'">Bounce In and Out</option>
                        <option [value]="'elasticIn'">Elastic In</option>
                        <option [value]="'elasticOut'">Elastic Out</option>
                        <option [value]="'elasticInOut'">Elastic In and Out</option>
                        <option [value]="'ease'">Smooth Ease</option>
                        <option [value]="'easeIn'">Ease In (Slow Start)</option>
                        <option [value]="'easeInToLinear'">Ease In to Smooth</option>
                        <option [value]="'easeInSine'">Ease In (Soft Sine)</option>
                        <option [value]="'easeInQuad'">Ease In (Gradual Quad)</option>
                        <option [value]="'easeInCubic'">Ease In (Slightly Faster)</option>
                        <option [value]="'easeInQuart'">Ease In (Faster Start)</option>
                        <option [value]="'easeInQuint'">Ease In (Quick Start)</option>
                        <option [value]="'easeInExpo'">Ease In (Accelerated Start)</option>
                        <option [value]="'easeInCirc'">Ease In (Circular)</option>
                        <option [value]="'easeInBack'">Ease In (Overshoot)</option>
                        <option [value]="'easeOut'">Ease Out (Smooth Finish)</option>
                        <option [value]="'linearToEaseOut'">Smooth to Ease Out</option>
                        <option [value]="'easeOutSine'">Ease Out (Soft Sine)</option>
                        <option [value]="'easeOutQuad'">Ease Out (Gradual Quad)</option>
                        <option [value]="'easeOutCubic'">Ease Out (Slightly Faster)</option>
                        <option [value]="'easeOutQuart'">Ease Out (Faster Finish)</option>
                        <option [value]="'easeOutQuint'">Ease Out (Quick Finish)</option>
                        <option [value]="'easeOutExpo'">Ease Out (Accelerated Finish)</option>
                        <option [value]="'easeOutCirc'">Ease Out (Circular)</option>
                        <option [value]="'easeOutBack'">Ease Out (Overshoot)</option>
                        <option [value]="'easeInOut'">Ease In and Out</option>
                        <option [value]="'easeInOutSine'">Ease In and Out (Soft Sine)</option>
                        <option [value]="'easeInOutQuad'">Ease In and Out (Gradual)</option>
                        <option [value]="'easeInOutCubic'">Ease In and Out (Slightly Faster)</option>
                        <option [value]="'easeInOutQuart'">Ease In and Out (Faster)</option>
                        <option [value]="'easeInOutQuint'">Ease In and Out (Quick)</option>
                        <option [value]="'easeInOutExpo'">Ease In and Out (Accelerated)</option>
                        <option [value]="'easeInOutCirc'">Ease In and Out (Circular)</option>
                        <option [value]="'easeInOutBack'">Ease In and Out (Overshoot)</option>
                    </select>
                    
                    <p-floatlabel variant="on" *ngIf="animationOut != 'auto' && animationOut != 'none'">
                        <label for="animation-out-duration" class="form-label">Animation duration (ms)</label>
                        <input pInputText class="form-control w-full" id="animation-out-duration" [(ngModel)]="animationDurationOut" type="number">
                    </p-floatlabel>
                </div>
            </div>
        </div>
    </div>
    <div class="actions pb-4">
        <p-button label="Cancel" [outlined]="true" mat-dialog-close />
        <p-button label="save" (click)="save()"></p-button>
    </div>
</div>