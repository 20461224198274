<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Accounts</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="w-60 pl-5 pt-5 flex items-center gap-2">
            <p-iconfield iconPosition="left">
                <span class="custom-icon">
                    <ng-icon name="saxSearchNormal1Outline" size="20"></ng-icon>
                </span>
                <input #accountSearchField pInputText type="search" fluid="false" styleClass="input-field search-field" class="input-field search-field" placeholder="Search" [(ngModel)]="searchQuery" (ngModelChange)="onSearch()" />
            </p-iconfield>
            <p-progress-spinner *ngIf="isLoading" class="flex" [style]="{ width: '25px', height: '25px' }" ariaLabel="loading" />
        </div>
       
        <div class="account-list" *ngIf="!isLoading">
            <div class="accounts">
                <table class="table">
                    <tbody>
                        <tr *ngFor="let account of accounts">
                            <td class="align-middle">{{account.info.name}}</td>
                            <td>
                                <p-button class="float-right" label="Select" [outlined]="true" (click)="selectAccount(account)" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            <!-- <button type="button" class="btn" (click)="openAccountCreate()">Create account</button> -->
        </div>
    </div>
</div>