<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Account</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="close()"></ng-icon>
    </div>
    <div class="modal-body" *ngIf="account">
        <div class="settings-main flex gap-4">
            <div class="v-menu-container">
                <div class="v-menu-button" (click)="currentOption = 'info'" [class.v-menu-button-selected]="currentOption == 'info'">Account info</div>
                <div class="v-menu-button" (click)="currentOption = 'settings'" [class.v-menu-button-selected]="currentOption == 'settings'">Settings</div>
                <div class="v-menu-button" (click)="currentOption = 'users'" [class.v-menu-button-selected]="currentOption == 'users'">Users</div>
                <div class="v-menu-button" (click)="currentOption = 'log'" [class.v-menu-button-selected]="currentOption == 'log'">Log</div>
                <div class="v-menu-button" *ngIf="account.id != 1" (click)="currentOption = 'delete'" [class.v-menu-button-selected]="currentOption == 'delete'">Delete</div>                    
            </div>
            <div class="settings-edit w-full">
                <div class="settings w-full pt-3" *ngIf="currentOption == 'info'">
                    <table class="table table-dark table-bordered">
                        <tr>
                            <td style="width: 10em;">Account name</td>
                            <td style="float: left;">{{account.info.name}}</td>
                        </tr>
                        <tr *ngIf="stats">
                            <td style="width: 10em;">Displays</td>
                            <td style="float: left;">{{stats.totalDisplays}}/{{stats.allowedDisplays == -1 ? 'unlimited' : stats.allowedDisplays}}</td>
                        </tr>
                        <tr *ngIf="stats">
                            <td style="width: 10em;">Drive storage</td>
                            <td style="float: left;">{{bytesToSize(stats.totalStorage)}}/{{stats.allowedMB == -1 ? 'unlimited' : stats.allowedMB+'MB'}}</td>
                        </tr>
                        <tr *ngIf="stats">
                            <td style="width: 10em;">Drive items</td>
                            <td style="float: left;">{{stats.totalData}}</td>
                        </tr>
                        <tr *ngIf="stats">
                            <td style="width: 10em;">Allowed feeds</td>
                            <td style="float: left;">{{stats.allowedFeeds == -1 ? 'unlimited' : stats.allowedFeeds}}</td>
                        </tr>
                        <tr *ngIf="stats">
                            <td style="width: 10em;">Playlists</td>
                            <td style="float: left;">{{stats.totalPlaylists}}</td>
                        </tr>                            
                    </table>
                </div>
                <div class="settings w-full pt-3" *ngIf="currentOption == 'settings'">
                    <table class="table">
                        <tr>
                            <td style="width: 15em">Account name</td>
                            <td style="float: left;"><input pInputText style="width: 25em;" class="input" type="text" placeholder="account name" [(ngModel)]="account.info.name" /></td>
                        </tr>
                        <tr>
                            <td style="width: 15em">Allowed storage (MB)</td>
                            <td style="float: left;"><input pInputText class="input" type="number"  [(ngModel)]="allowedMB" /></td>
                        </tr>
                        <tr>
                            <td style="width: 15em">Allowed displays</td>
                            <td style="float: left;"><input pInputText class="input" type="number"  [(ngModel)]="allowedDisplays" /></td>
                        </tr>
                        <tr>
                            <td style="width: 15em">Allowed feeds</td>
                            <td style="float: left;"><input pInputText class="input" type="number"  [(ngModel)]="allowedFeeds" /></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="mt-3">
                                    <p-button label="Save" (click)="saveAccountInfo()" />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="settings w-full pt-3 pr-2" *ngIf="currentOption == 'users'">
                    <table class="table text-center w-full table-view" *ngIf=" account.accountUsers.length > 0">
                        <thead>
                            <tr>
                                <th><div class="pl-3">User</div></th>
                                <th>Permissions</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let accountUser of account.accountUsers">
                                <td>
                                    {{accountUser.user.alias == '' ? 'Pending setup' : accountUser.user.alias}} <br/>
                                    <p-chip  label="{{accountUser.user.authProviders[0].providerUUID}}" />
                                </td>
                                <td>
                                    <p-multiselect (onChange)="onRolesChange(accountUser.user)" [options]="roles" showToggleAll="false" showHeader="false" [(ngModel)]="accountUser.user.roles" optionLabel="name" optionValue="id"  styleClass="w-full md:w-80" />
                                </td>
                                <td>
                                    <p-button label="Delete" [outlined]="true" (click)="deleteUser(accountUser.id)" />
                                </td>
                            </tr>
                            <tr *ngFor="let invite of invites">
                                <td>
                                    Invation sent <br/>
                                    <p-chip label="{{invite.authProvider.providerUUID}}" />
                                </td>
                                <td>
    
                                </td>
                                <td>
                                    <p-button label="Delete" [outlined]="true" (click)="deleteInvite(invite.id)" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex gap-2 mt-4">
                        <input pInputText style="width: 25em;" class="input" type="email" placeholder="email" [(ngModel)]="email" />
                        <p-button label="Add user" (click)="addUser()" />
                    </div>
                </div>
                <div class="settings w-full pt-3 pr-2" *ngIf="currentOption == 'log'">
                    <app-data-table class="w-full" [accountId]="account.id" [dataClass]="dataClass" (onTableDataChange)="onTableDataChange($event)"></app-data-table>
                </div>
                <div class="settings" *ngIf="currentOption == 'delete'">
                    <div style="display: flex;flex-direction: column;align-items: flex-start; color: aliceblue;gap: 0.5em;">
                        <div>Type 'DELETE' to delete this account</div>
                        <input pInputText style="width: 25em;" class="input" type="text" placeholder="DELETE" [(ngModel)]="deleteText" />
                        <p-button *ngIf="deleteText == 'delete' || deleteText == 'DELETE'"  label="Delete account"  (click)="deleteAccount()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>