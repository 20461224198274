import { Injectable, NgZone } from '@angular/core';
import { LayerText } from '../account/editor/models/layer-text';

interface FileToUpload {
  file: File;
  className: string;
  uploadProgress: number;
  error: boolean;
  errorMessage: string;
  transCoding: boolean;
  finalizing: boolean;
  progressTime: string;
  started: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  selectedInputFocusChange: Function = () => { };
  textEditorOpen: boolean = false;
  textInputValue: LayerText = null;
  textInputValueChange: Function = (layerText: LayerText) => { };
  textInputValueChangeGlobal: Function = (layerText: LayerText) => { };
  textInputIsGlobal: boolean = false;

  accountSelectOpen: boolean = false;
  partnerSelectOpen: boolean = false;
  accountSettingsOpen: boolean = false;
  userProfileOpen: boolean = false;

  uploadOpen: boolean = false;
  uploadChange: Function = () => { };
  uploadPlayListId: number = 0;
  uploadDirId: number = 0;
  filesToUpload: FileToUpload[] = [];
  isDragOver = false;

  folderItems: any[] = [];
  playlistItems: any[] = [];
  folderDroplistElement: any = null;
  playlistDroplistElement: any = null;

  tableInputValue: any = { row: null, column: null, isColumn: false, cursorPosition: 0 };
  constructor(private ngZone: NgZone) { }

  uploadParseFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let className = null;
      if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/gif' || file.type == 'image/webp') {
        className = 'Image';
      } else if (file.type == 'video/mp4') {
        className = 'Video';
      }
      if (className == null) {
        continue;
      };

      this.filesToUpload.push({ file, uploadProgress: 0, error: false, errorMessage: '', className: className, transCoding: false, progressTime: '', finalizing: false, started: false});
      this.uploadOpen = true;
    }
  }

  //drag drop upload
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.ngZone.run(() => {
      this.isDragOver = true;
    });
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.ngZone.run(() => {
      this.isDragOver = false;
    });
  }

  onFilesSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.ngZone.run(() => {
        this.uploadParseFiles(input.files);
      });
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.ngZone.run(() => {
      this.isDragOver = false;
      if (this.uploadOpen == false) {
        if (event.dataTransfer == null) return;
        const files = event.dataTransfer.files;
        this.uploadParseFiles(event.dataTransfer.files);
      }
    });
  }
}
