<!-- upload status-->
<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Uploading files</div>
        <div></div>
    </div>
    <!-- <div class="upload-done" *ngIf="showUploadDone && !didGetError">
        <div>
            <span class="material-symbols-outlined" style="font-size: 3em;">
                    cloud_done
                    </span>
        </div>
        <div>Upload done</div>
    </div> -->
    <div class="modal-body flex flex-col gap-2">
        <div class="w-full">
            <p-progressbar [value]="uploadProgress" [style]="{ height: '15px' }" />
        </div>
        <div class="upload-files flex flex-col gap-3" #uploadScrollContainer>
            <div class="upload-file flex w-full gap-4" *ngFor="let file of commonService.filesToUpload; let i = index" [id]="'upload_'+i">
                <div class="flex items-center">
                    <ng-icon *ngIf="file.className == 'Video'" name="saxVideoPlayOutline" size="45"></ng-icon>
                    <ng-icon *ngIf="file.className == 'Image'" name="saxImageOutline" size="45"></ng-icon>
                </div>
                <div class="flex flex-col gap-1 w-full justify-center">
                    <div class="title-line">{{ file.file.name }}</div>
                    <div *ngIf="file.started && !file.error && file.uploadProgress < 100">Uploading...</div>
                    <div *ngIf="file.started && !file.error && file.finalizing">Processing uploaded file...</div>
                    <div class="file-error" *ngIf="file.error">{{file.errorMessage}}</div>
                </div>
                <div>
                    <ng-icon class="done-icon" *ngIf="file.started && file.uploadProgress >= 100 && !file.error && !file.finalizing" name="saxTickCircleOutline" size="45"></ng-icon>
                    <p-progress-spinner *ngIf="file.started && !file.error && file.uploadProgress < 100 || file.started && !file.error && file.finalizing" strokeWidth="4" fill="transparent" animationDuration=".5s" [style]="{ width: '50px', height: '50px' }" />
                </div>
            </div>
        </div>
    </div>
    <div style="padding: 1em;" *ngIf="showUploadDone">
        <button pButton [outlined]="true" (click)="closeUploadWindow()">Close window</button>
    </div>
</div>