<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Profile</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="commonService.userProfileOpen = false"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="settings-main flex gap-4">
            <div class="v-menu-container">
                <div class="v-menu-button" (click)="currentOption = 'info'" [class.v-menu-button-selected]="currentOption == 'info'">General</div>
                <div class="v-menu-button" (click)="currentOption = 'Security'" [class.v-menu-button-selected]="currentOption == 'Security'">Security</div>
                <div class="v-menu-button" (click)="currentOption = 'WebDav'" [class.v-menu-button-selected]="currentOption == 'WebDav'">WebDav</div>
            </div>
            <div class="settings-edit w-full">
                <div class="general-info" *ngIf="currentOption == 'info'">
                    <div class="flex flex-col gap-2 pt-2">
                        <div class="flex flex-col gap-2 items-start">
                            <label for="user_alias" class="font-thin text-sm">Screen name</label>
                            <input style="width: 22em;" placeholder="Enter screen name" pInputText id="user_alias" [(ngModel)]="user.alias" />
                        </div>
                        <div class="flex flex-col gap-2 items-start">
                            <label for="user_firstname" class="font-thin text-sm">First name</label>
                            <input style="width: 22em;" placeholder="Enter first name" pInputText id="user_firstname" [(ngModel)]="user.firstname" />
                        </div>
                        <div class="flex flex-col gap-2 items-start">
                            <label for="user_lastname" class="font-thin text-sm">Last name</label>
                            <input style="width: 22em;" placeholder="Enter last name" pInputText id="user_lastname" [(ngModel)]="user.lastname" />
                        </div>
                        <div class="flex justify-end">
                            <p-button class="w-24"  styleClass="w-24" label="Save" (click)="saveProfile()" />
                        </div>
                    </div>
                </div>

                <div class="general-info w-full" *ngIf="currentOption == 'Security'">
                    <div class="authProviders w-full pr-2" *ngFor="let authProvider of user.authProviders; let i = index;">
                        <div class="authentication-tokens">Authentication provider #{{i+1}}</div>

                        <div class="provider-uuid">
                            <table class="table table-auto">
                                <thead>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Creation date</td>
                                        <td>{{authProvider.date | date:'dd-MM-yyyy HH:mm' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">Authentication type</td>
                                        <td>{{authProvider.type}}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">UUID</td>
                                        <td>{{authProvider.providerUUID}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="authentication-tokens">Authentication tokens</div>

                        <table class="table table-dark">
                            <thead>
                                <tr>
                                    <td class="align-middle">Status</td>
                                    <td class="align-middle">IP</td>
                                    <td class="align-middle">Creation date</td>
                                    <td class="align-middle">Expire date</td>
                                    <td class="align-middle"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let authenticationToken of authProvider.authenticationTokens">
                                    <td class="align-middle">
                                        <div class="authtoken approved" *ngIf="authenticationToken.active">Approved</div>
                                        <div class="authtoken" *ngIf="!authenticationToken.active">Pending</div>
                                    </td>
                                    <td class="align-middle">{{authenticationToken.ip_address}}</td>
                                    <td class="align-middle">{{authenticationToken.date | date:'dd-MM-yyyy HH:mm' }}</td>
                                    <td class="align-middle">{{authenticationToken.expireDate | date:'dd-MM-yyyy HH:mm' }}</td>
                                    <td class="align-middle">
                                        <p-button (click)="deleteToken(authenticationToken.id)" label="Revoke access" severity="danger" [outlined]="true" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="general-info w-full pr-3" *ngIf="currentOption == 'WebDav'">

                    <div class="authProviders" *ngFor="let authProvider of user.authProviders; let i = index;">
                        <div class="webdav-logins" *ngIf="authProvider.type == 'webdav'">
                            <table class="table table-dark">
                                <thead>
                                    <tr>
                                        <td class="align-middle">URL</td>
                                        <td class="align-middle">IP</td>
                                        <td class="align-middle">Creation date</td>
                                        <td class="align-middle">Expire date</td>
                                        <td class="align-middle"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let authenticationToken of authProvider.authenticationTokens">
                                        <td class="align-middle">
                                            <input type="text" pInputText value="{{apiService.webDavURL}}/webdav-{{authService.user.id}}-{{authenticationToken.token}}" />
                                        </td>
                                        <td class="align-middle">{{authenticationToken.ip_address}}</td>
                                        <td class="align-middle">{{authenticationToken.date | date:'dd-MM-yyyy HH:mm' }}</td>
                                        <td class="align-middle">{{authenticationToken.expireDate | date:'dd-MM-yyyy HH:mm' }}</td>
                                        <td class="align-middle">
                                            <p-button label="Revoke access" [outlined]="true" severity="warn" (click)="deleteToken(authenticationToken.id)" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p-button label="Generate WebDav URL" [outlined]="true" (click)="generateWebDavToken()" />
                </div>
            </div>
        </div>
    </div>
    <div class="actions" *ngIf="currentOption == 'info'">

    </div>
</div>