<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="cancel()"></ng-icon>
    </div>
    <div class="modal-body">
        <div class="media-info flex gap-3">            
            <div class="media-preview flex flex-col w-96 gap-3">
                <video class="video-el" style="max-height: 35em;" [src]="data.meta.url" controls></video>
                <a href="{{data.meta.url}}" target="_blank" pButton class="w-full" styleClass="w-full" [outlined]="true" severity="secondary" >
                    <ng-icon name="saxArrowDown2Outline" size="20"></ng-icon>Download
                </a>
            </div>
            <div class="media-config flex flex-col gap-3">
                <div class="media-title">
                    <p-floatLabel variant="on">
                        <label for="name">Name</label>
                        <input data-lpignore="true" class="w-full" id="name" pInputText placeholder="Name" [(ngModel)]="data.name">
                    </p-floatLabel>
                </div>
                <div class="media-tags">
                    <div class="tags-ldt flex flex-col gap-2">
                        <div *ngFor="let tag of tags; let i2 = index" class="flex gap-2">
                            <input data-lpignore="true" pInputText [(ngModel)]="tag.value" /><p-button label="Remove" [outlined]="true" (click)="tags.splice(i2,1)" />
                        </div>
                        <p-button class="w-full" styleClass="w-full" [outlined]="true" (click)="addTag()">
                            <ng-icon name="saxAddOutline" size="20"></ng-icon>Add tag
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions pt-3">
            <p-button label="Cancel" [outlined]="true" (click)="cancel()" />
            <p-button label="Save" (click)="save()" />
        </div>
    </div>
</div>