import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LayerText } from 'src/app/account/editor/models/layer-text';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { CommonService } from 'src/app/services/common.service';

interface fontSelect {
  value: string;
  name: string;
}

@Component({
  selector: 'inputfield',
  templateUrl: './inputfield.component.html',
  styleUrls: ['./inputfield.component.scss'],
})
export class InputfieldComponent implements OnInit, AfterViewInit  {
  @ViewChild('inputItem') inputElement: ElementRef;
  @Input() inputValue: LayerText;
  @Output() onChange = new EventEmitter<LayerText>();
  @Output() onChangeGlobal = new EventEmitter<LayerText>();

  @Input() row: number|null = null;
  @Input() column: number|null = null;
  @Input() isColumn: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() global: boolean = false;

  hover: boolean = false;
  focus: boolean = true;

  editorOpen: boolean = false;

  constructor(private commonService: CommonService, private editorService: EditorService,) {

  }
  ngAfterViewInit(): void {
    if(this.autoFocus && !this.global) {
      this.inputElement.nativeElement.focus();
      setTimeout(() => {
        this.inputElement.nativeElement.select();
      }, 10);
    }
  }

  openEditor(): void {
    this.commonService.textInputValue = this.inputValue;
    this.commonService.textEditorOpen = true;
    this.commonService.textInputIsGlobal = this.global;
    this.selectTableField();
    this.onChange.emit(this.inputValue);
  }

  onFocus(): void {
    this.focus = true;
    this.commonService.textInputValue = this.inputValue;
    this.commonService.textInputIsGlobal = this.global;
    this.selectTableField();
  }

  onBlur(): void {
    this.focus = false;    
  }

  onHover(): void {
    this.hover = true;
  }

  onLeave(): void {
    this.hover = false;
    this.commonService.textEditorOpen = false;
  }

  ngOnInit(): void {

  }

  onInputChange(value: string): void {
      this.inputValue.text = value;
      this.onChange.emit(this.inputValue);  
  }

  selectTableField(): void {
    if(this.row === null || this.column === null) return;
    this.commonService.tableInputValue = { row: this.row, column: this.column, isColumn: this.isColumn, cursorPosition: 0};
    this.onChange.emit(this.inputValue); 
  }
}