import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, ViewChild, Input, Output } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/api/api.service';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { Router } from '@angular/router';
import { Display } from 'src/app/api/models/account/display/display';
import { Design } from 'src/app/api/models/account/display/design';
import { CopyDesignComponent } from '../copy-design/copy-design.component';
import { MoveDisplayComponent } from '../move-display/move-display.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from 'src/app/popups/confirm/dialog';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements AfterViewInit {
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  searchQuery: string = '';
  searchTimeout: any = null;
  dataClassType = DataClass;

  selectedDisplay: Display;
  linkDisplay: boolean = false;
  deviceInfo: boolean = false;
  globalDesign: boolean = false;

  themeGroups: any[] = [];
  selectedThemeGroup: any = '';

  themes: any[] = [];
  selectedTheme: any = '';
  pageIndex: number = 0;
  pageSize: number = 25;

  public updateWatch: EventEmitter<any>;


  @ViewChild(MatSort) sort!: MatSort;

  @Output() onTableDataChange = new EventEmitter<TableChangeData>();

  @Input() dataClass: DataClass = DataClass.Designs;

  @Output() onDataSelect = new EventEmitter<any[]>();
  @Input() dataSelect: boolean = false;
  @Input() accountId: number = 0;

  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, private dialog: MatDialog) {
    this.updateWatch = new EventEmitter<any>();

    if (this.dataClass == DataClass.Designs) {
      this.apiService.getDesignThemeGroups(this.authService.selectedAccountId).subscribe((data: any) => {
        this.themeGroups = data;
      });
    }
  }

  openAccount(id: any) {
    this.router.navigate([
      'account/' + id + '/dashboard'
    ]);
  }

  onThemeGroupChange() {
    this.selectedTheme = '';
    this.themes = [];
    if (this.selectedThemeGroup == '') { this.updateWatch.emit({}); return; }
    this.apiService.getDesignThemes(this.authService.selectedAccountId, this.selectedThemeGroup).subscribe((data: any) => {
      this.themes = data;
      this.updateWatch.emit({});
    });
  }

  onThemeChange() {
    this.updateWatch.emit({});
  }

  onDeviceInfoClose() {
    this.deviceInfo = false;
    this.selectedDisplay = null;
    this.updateWatch.emit({});
  }

  openDeviceInfo(display: Display) {
    this.selectedDisplay = display;
    this.deviceInfo = true;
  }

  onSelectedDisplayClose() {
    this.linkDisplay = false;
    this.selectedDisplay = null;
    this.updateWatch.emit({});
  }

  openLinkDisplay(display: Display) {
    this.selectedDisplay = display;
    this.linkDisplay = true;
  }

  selectData() {
    let selected: any[] = [];
    this.selection.selected.forEach((item: any) => {
      selected.push(item);
    });

    if (selected.length > 0) {
      this.onDataSelect.emit(selected);
    }
  }

  onSelectData(data: any) {
    this.onDataSelect.emit([data]);
  }

  getDataClassSingle(): string {
    switch (this.dataClass) {
      case DataClass.Designs:
        return 'design';
      case DataClass.Accounts:
        return 'account';
      case DataClass.AuditLogs:
        return 'auditlog';
      case DataClass.Users:
        return 'users';
      case DataClass.Data:
        return 'data';
      case DataClass.Displays:
        return 'display';
      case DataClass.Images:
        return 'images';
      case DataClass.Playlists:
        return 'playlist';
      case DataClass.Videos:
        return 'videos';
      case DataClass.Partners:
        return 'partner';
      case DataClass.Firmware:
        return 'firmware';
      default:
        return 'designs';
    }
  }

  getDataClass(): string {
    switch (this.dataClass) {
      case DataClass.Designs:
        return 'designs';
      case DataClass.Accounts:
        return 'accounts';
      case DataClass.AuditLogs:
        return 'auditlogs';
      case DataClass.Users:
        return 'users';
      case DataClass.Data:
        return 'data';
      case DataClass.Displays:
        return 'displays';
      case DataClass.Images:
        return 'images';
      case DataClass.Playlists:
        return 'playlists';
      case DataClass.Videos:
        return 'videos';
      case DataClass.Partners:
        return 'partners';
      case DataClass.Firmware:
        return 'firmware';
      default:
        return 'designs';
    }
  }

  loadEdit(id: any) {
    this.router.navigate([
      this.authService.getAccountBasePath() + '/editor/' + id
    ]);
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      this.pageIndex = 0;
      this.updateWatch.emit({});
    });

    merge(this.sort.sortChange, this.updateWatch)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.selection.clear();
          this.isLoadingResults = true;
          return this.doRequest().pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.

          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;

          data.results.map((item: any, index: number) => {
            item = this.modifyData(item);
          });

          if (this.displayedColumns.length == 0 && data.results.length > 0) {
            this.displayedColumns = Object.keys(data.results[0]);
            if (this.dataClass != DataClass.AuditLogs && this.dataClass != DataClass.Accounts) {
              this.displayedColumns.unshift('select');
            }
            // if(this.dataClass == DataClass.Displays){
            //   this.displayedColumns.push('status');
            // }
            if (this.dataClass != DataClass.AuditLogs) {
              this.displayedColumns.push('actions');
            }
            this.displayedColumns = this.columnOrder(this.displayedColumns);
            this.displayedColumns = this.columnFilter(this.displayedColumns);
            this.columnsToDisplay = this.displayedColumns.slice();
          }

          data.results.map(async (item: any, index: number) => {
            item.select = false;
            if (this.dataClass == DataClass.Displays) {
              item.status = 'Offline';
            }
          });

          this.isLoadingResults = false;

          return data.results;
        }),
      )
      .subscribe(data => (this.dataSource = data));
  }

  onPageChange(event: any) {
    this.pageIndex = event.page;
    this.pageSize = event.rows;
    this.updateWatch.emit({});
  }

  doRequest(): Observable<any> {
    const pageSize = this.pageSize;
    const pageIndex = this.pageIndex;

    switch (this.dataClass) {
      case DataClass.Firmware:
        return this.apiService.listFirmware(this.authService.selectedAccountId, {
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : 'id'
        });
      case DataClass.Data:
        return this.apiService.findData(this.authService.selectedAccountId, {
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active
        });
      case DataClass.Displays:
        return this.apiService.findDisplays({
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active
        }, this.authService.selectedAccountId);
      case DataClass.Playlists:
        return this.apiService.findPlaylists(
          this.authService.selectedAccountId, {
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active
        });
      case DataClass.Accounts:
        return this.apiService.partnerListAccounts(
          this.authService.selectedPartnerId, {
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: 'id'
        });
      case DataClass.AuditLogs:
        return this.apiService.partnerAccountAuditLogs(
          this.authService.selectedPartnerId, this.accountId, {
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: 'created_at'
        });
      default:
        return this.apiService.findDesigns({
          query: this.searchQuery,
          limit: pageSize,
          start: pageIndex * pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active,
          global: this.globalDesign,
          themeGroup: this.selectedThemeGroup,
          themeName: this.selectedTheme
        }, this.authService.selectedAccountId);
    }
  }

  applyFilter(event: Event) {
    this.searchTimeout && clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const filterValue = (event.target as HTMLInputElement).value;
      this.searchQuery = filterValue.trim().toLowerCase();
      this.updateWatch.emit({});
    }, 500);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  columnNames(name: string) {
    if (this.dataClass == DataClass.Designs) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
      if (name == 'themeGroup') return 'Category';
      if (name == 'themeName') return 'Theme';
    }

    if (this.dataClass == DataClass.Displays) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
    }

    if (this.dataClass == DataClass.Data) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
      if (name == 'className') return 'Type';
    }

    if (this.dataClass == DataClass.Playlists) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
    }

    if (this.dataClass == DataClass.Accounts) {
      if (name == 'id') return 'ID';
      if (name == 'info') return 'Name';
      if (name == 'mutationDate') return 'Date';
    }

    if (this.dataClass == DataClass.AuditLogs) {
      if (name == 'created_at') return 'Date';
    }

    if (name == 'actions') return 'Actions';

    return name;
  }

  columnFilter(columns: string[]): string[] {
    for (var i = columns.length - 1; i >= 0; i--) {
      if (this.dataClass == DataClass.Designs) {
        if (columns[i] == 'groups') columns.splice(i, 1);
        if (columns[i] == 'attributes') columns.splice(i, 1);
        if (columns[i] == 'categories') columns.splice(i, 1);
        if (columns[i] == 'data') columns.splice(i, 1);
        if (columns[i] == 'id') columns.splice(i, 1);
        if (columns[i] == 'global_design') columns.splice(i, 1);
        if (columns[i] == 'aspect_ratio') columns.splice(i, 1);
        if (columns[i] == 'has_attributes') columns.splice(i, 1);
      }

      if (this.dataClass == DataClass.Displays) {
        if (columns[i] == 'groups') columns.splice(i, 1);
        if (columns[i] == 'attributes') columns.splice(i, 1);
        if (columns[i] == 'categories') columns.splice(i, 1);
        if (columns[i] == 'id') columns.splice(i, 1);
        if (columns[i] == 'ID') columns.splice(i, 1);
        if (columns[i] == 'device') columns.splice(i, 1);
      }

      if (this.dataClass == DataClass.Data) {
        if (columns[i] == 'groups') columns.splice(i, 1);
        if (columns[i] == 'parent') columns.splice(i, 1);
        if (columns[i] == 'id') columns.splice(i, 1);
        if (columns[i] == 'selected_class') columns.splice(i, 1);
      }

      if (this.dataClass == DataClass.Playlists) {
        if (columns[i] == 'schedules') columns.splice(i, 1);
        if (columns[i] == 'composites') columns.splice(i, 1);
        if (columns[i] == 'displays') columns.splice(i, 1);
        if (columns[i] == 'meta') columns.splice(i, 1);
        if (columns[i] == 'id') columns.splice(i, 1);
      }

      if (this.dataClass == DataClass.Firmware) {
        if (columns[i] == 'firmware_url') columns.splice(i, 1);
        if (columns[i] == 'firmware_file') columns.splice(i, 1);
        if (columns[i] == 'post_script') columns.splice(i, 1);
        if (columns[i] == 'script') columns.splice(i, 1);
        if (columns[i] == 'hash') columns.splice(i, 1);
        if (columns[i] == 'hardware_types') columns.splice(i, 1);
      }

      if (this.dataClass == DataClass.Accounts) {
        if (columns[i] == 'accountUsers') columns.splice(i, 1);
        if (columns[i] == 'firmware_admin') columns.splice(i, 1);
        if (columns[i] == 'global_design') columns.splice(i, 1);
        if (columns[i] == 'global_feed') columns.splice(i, 1);
        if (columns[i] == 'groups') columns.splice(i, 1);
        if (columns[i] == 'partner') columns.splice(i, 1);
        if (columns[i] == 'settings') columns.splice(i, 1);
        if (columns[i] == 'id') columns.splice(i, 1);
      }

      if (this.dataClass == DataClass.AuditLogs) {
        if (columns[i] == '_id') columns.splice(i, 1);
        if (columns[i] == 'updated_at') columns.splice(i, 1);
        if (columns[i] == 'accountId') columns.splice(i, 1);
      }
    }

    return columns;
  }

  modifyData(data: any) {
    if (this.dataClass == DataClass.Data) {
      data.className = data.selected_class.name;
    }
  }

  columnOrder(columns: string[]): string[] {
    if (this.dataClass == DataClass.Designs) {
      columns = this.sortByOrder(columns, ['select', 'name', 'themeGroup', 'themeName', 'mutationDate', 'actions']);
    }

    if (this.dataClass == DataClass.Data) {
      columns = this.sortByOrder(columns, ['select', 'className', 'name', 'mutationDate', 'actions']);
    }

    return columns;
  }

  sortByOrder(arrayToSort: string[], orderArray: string[]) {
    // Create a dictionary of the order array to get the index of each element
    const orderDict: any = {};
    for (let i = 0; i < orderArray.length; i++) {
      orderDict[orderArray[i]] = i;
    }

    // Sort the array based on the order array using a custom sorting function
    arrayToSort.sort((a, b) => {
      // Get the index of each element in the order array
      const indexA = orderDict[a];
      const indexB = orderDict[b];

      // If both elements are in the order array, compare their indices
      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      }

      // If only one element is in the order array, it comes first
      if (indexA !== undefined) {
        return -1;
      }
      if (indexB !== undefined) {
        return 1;
      }

      // If neither element is in the order array, sort alphabetically
      return a.localeCompare(b);
    });

    return arrayToSort;
  }

  /*
  * Actions
  */
  create() {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.New,
      selected: this.selection,
    });
  }

  edit(row: any) {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.Edit,
      selected: this.selection,
      item: row,
    });
  }

  delete(row: any) {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.Delete,
      selected: this.selection,
      item: row,
    });
  }

  deleteSelected() {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.DeleteAll,
      selected: this.selection,
    });
  }

  startdevice() {
    //start device in new tab
    window.open(window.location.origin + '/device', '_blank');
  }


  clone(design: Design) {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Copy design',
        message: 'Are you sure you want to copy this design?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.createDesign(this.authService.selectedAccountId, design.name + " copy", design.orientation, design.data.width, design.data.height).subscribe((data: Design) => {
          data.data = design.data;
          data.themeGroup = design.themeGroup;
          data.themeName = design.themeName;
          data.tags = design.categories.map((item: any) => item.name).join(',');
          this.apiService.updateDesign(this.authService.selectedAccountId, data).subscribe((data: Design) => {
            this.loadEdit(data.id);
          });
        });
      }
    });
  }

  copySelected() {
    if (this.selection.selected.length == 0) return;
    this.dialog.open(CopyDesignComponent, {
      panelClass: 'custom-dialog-container',
      enterAnimationDuration: 0,
      data: {
        title: 'Copy design(s)',
        data: JSON.parse(JSON.stringify(this.selection.selected)),
        width: 600,
        height: 700
      }
    }).afterClosed().subscribe((result: any) => {
      this.updateWatch.emit({});
    });
  }

  moveSelected() {
    if (this.selection.selected.length == 0) return;
    this.dialog.open(MoveDisplayComponent, {
      panelClass: 'custom-dialog-container',
      enterAnimationDuration: 0,
      data: {
        title: 'Move display(s)',
        data: JSON.parse(JSON.stringify(this.selection.selected)),
        width: 400,
        height: 500
      }
    }).afterClosed().subscribe((result: any) => {
      this.updateWatch.emit({});
    });
  }

  editPlaylist(row: any) {
    this.router.navigate([
      this.authService.getAccountBasePath() + '/playlist/' + row.id
    ]);
  }

}