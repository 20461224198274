<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.title}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" mat-dialog-close></ng-icon>
    </div>
    <div class="modal-body">
        <p-progressbar mode="indeterminate" [style]="{ height: '6px' }" [style.opacity]="isLoading? 1 : 0" />

        <div *ngIf="isCopying" class="center moving-stuff">Copying contents please wait.</div>
        <table *ngIf="!isCopying" class="table table-dark" style="width: 100%;">
            <thead>
                <tr>
                    <th>Account name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of accounts">
                    <td class="center">{{account.info.name}}</td>
                    <td><button pButton [outlined]="true"  class="btn" (click)="onSelectAccount(account)">Select</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>