<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" (click)="cancel()"></ng-icon>
    </div>
    <div class="modal-body">

        <div class="media-info flex gap-3">
            <div class="media-preview flex flex-col w-96 gap-3">
                <div class="waiting-for-image" *ngIf="!data.meta.url">
                    <span>Generating image</span>
                </div>
                <div class="w-full min-h-80 image-preview" *ngIf="data.meta.url" style="background-image: url({{data.meta.url}});"></div>
                <a href="{{data.meta.url}}" target="_blank" pButton class="w-full" styleClass="w-full" [outlined]="true" severity="secondary">
                    <ng-icon name="saxArrowDown2Outline" size="20"></ng-icon>Download
                </a>
            </div>
            <div class="media-config flex flex-col gap-3">
                <div class="media-title">
                    <p-floatLabel variant="on">
                        <label for="name">Name</label>
                        <input data-lpignore="true" class="w-full" id="name" pInputText placeholder="Name" [(ngModel)]="data.name">
                    </p-floatLabel>
                </div>
                <div class="media-tags">
                    <div class="tags-ldt flex flex-col gap-2">
                        <div *ngFor="let tag of tags; let i2 = index" class="flex gap-2">
                            <input data-lpignore="true" pInputText [(ngModel)]="tag.value" />
                            <p-button label="Remove" [outlined]="true" (click)="tags.splice(i2,1)" />
                        </div>
                        <p-button class="w-full" styleClass="w-full" [outlined]="true" (click)="addTag()">
                            <ng-icon name="saxAddOutline" size="20"></ng-icon>Add tag
                        </p-button>
                    </div>
                </div>
                <div *ngIf="data.feed" class="media-feed-info">
                    <div class="flex flex-col gap-2">
                        <div class="feed-url">
                            <p-floatlabel variant="on">
                                <label for="meta-url">URL</label>
                                <input class="w-full" id="meta-url" pInputText placeholder="URL" [(ngModel)]="data.feed.meta.url">
                            </p-floatlabel>
                        </div>
                        <div class="feed-width">
                            <p-floatLabel variant="on">
                                <label for="meta-width">Width</label>
                                <input class="w-full" id="meta-width" pInputText placeholder="Width" [(ngModel)]="data.feed.meta.width">
                            </p-floatLabel>
                        </div>
                        <div class="feed-height">
                            <p-floatLabel variant="on">
                                <label for="meta-height">Height</label>
                                <input class="w-full" id="meta-height" pInputText placeholder="Height" [(ngModel)]="data.feed.meta.height">
                            </p-floatLabel>
                        </div>
                        <div class="feed-screenshot-delay">
                            <p-floatLabel variant="on">
                                <label for="meta-screenshot-delay">Screenshot Delay (ms)</label>
                                <input class="w-full" id="meta-screenshot-delay" pInputText placeholder="Screenshot Delay (ms)" [(ngModel)]="data.feed.meta.screenshotDelay">
                            </p-floatLabel>
                        </div>
                        <div class="feed-check">
                            <p-checkbox inputId="meta-js-error" [(ngModel)]="data.feed.meta.checkJsErrors" [binary]="true" />
                            <label for="meta-js-error">Check Js Errors</label>
                        </div>
                        <div class="feed-check flex">
                            <p-checkbox inputId="meta-check-assets" [(ngModel)]="data.feed.meta.checkAssetLoading" [binary]="true" />
                            <label for="meta-check-assets">Check assets loaded</label>
                        </div>
                        <div class="feed-check flex">
                            <p-checkbox inputId="meta-check-blank" [(ngModel)]="data.feed.meta.blankScreenCheck" [binary]="true" />
                            <label for="meta-check-blank">Check for blank screen</label>
                        </div>
                        <div class="feed-last-update">
                            <span>Last update: {{getLastUpdateHuman(data.feed.mutationDate)}}</span>
                        </div>
                        <div *ngIf="data.feed.meta.lastErrors" class="feed-last-error">
                            <span>Last error: </span>
                            <span *ngIf="data.feed.meta.lastErrors.length == 0">No errors</span>
                            <span *ngIf="data.feed.meta.lastErrors.length > 0">{{data.feed.meta.lastErrors[0].message}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions pt-3">
            <p-button label="Cancel" [outlined]="true" (click)="cancel()" />
            <p-button label="Save" (click)="save()" />
        </div>
    </div>
</div>