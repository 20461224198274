<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.title}}</div>
        <ng-icon name="saxAddOutline" size="25" class="action-icons close-action" mat-dialog-close></ng-icon>
    </div>
    <div class="modal-body">
        <!-- new design -->
        <div *ngIf="data.dataClass == dataClasses.Designs || 
        data.dataClass == dataClasses.Displays  || 
        data.dataClass == dataClasses.Playlists  || 
        data.dataClass == dataClasses.Composite" class="flex flex-col gap-2 w-80">
            <input pInputText class="w-full" [(ngModel)]="data.data.name" (keyup.enter)="onSave()">
            <select *ngIf="data.dataClass == dataClasses.Designs" class="form-select w-full" [(ngModel)]="data.data.device">
                <option [value]="'tv'">TV</option>
                <option [value]="'tablet'">Tablet</option>
            </select>
            <select *ngIf="data.dataClass == dataClasses.Designs" class="form-select w-full" [(ngModel)]="data.data.orientation">
                <option [value]="'landscape'">Landscape</option>
                <option [value]="'portrait'">Portrait</option>
            </select>
        </div>
        <div *ngIf="data.dataClass == dataClasses.Accounts">
            <input pInputText class="form-control" [(ngModel)]="data.data.name" (keyup.enter)="onSave()">
        </div>
        <!-- new firmware -->
        <div *ngIf="data.dataClass == dataClasses.Firmware">
            <div style="display: flex;">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="data.data.hardwareTypes" multiple>
                        <mat-option *ngFor="let type of hardwareTypes" [value]="type.id">
                            {{type.hardware_type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <textarea class="form-control" [(ngModel)]="data.data.postScript" placeholder="Post script"></textarea>
            </div>
            <div>
                <textarea class="form-control" [(ngModel)]="data.data.script" placeholder="Script"></textarea>
            </div>
            <div>
                <input pInputText class="form-control" type="file" (change)="onUplaodFile($event)">
            </div>
        </div>
        <!-- new data -->
        <div *ngIf="data.dataClass == dataClasses.Data">

            <div class="new-data-container">
                <div *ngIf="selectedType != ''" class="flex gap-2 items-center">
                    <ng-icon style="cursor: pointer;" name="saxArrowLeft2Outline" size="25" (click)="selectedType = ''" *ngIf="selectedType != ''"></ng-icon>

                    <div style="font-size: 1.5em;">{{selectedType}}</div>
                </div>
                <div class="new-title-container">
                    <input pInputText class="form-control" [(ngModel)]="data.data.name" (keyup.enter)="onSave()">
                </div>
                <div class="new-data-list" *ngIf="selectedType == ''">
                    <div (click)="dataCreateFolder()" class="data-items">
                        Folder
                    </div>
                    <div (click)="dataCreateTable()" class="data-items">
                        Table
                    </div>
                    <div (click)="selectedType = 'rss'" class="data-items">
                        RSS
                    </div>
                    <div (click)="selectedType = 'website'" class="data-items">
                        Website
                    </div>
                    <!-- <div (click)="selectedType = 'weather'" class="data-items">
                        Weather
                    </div> -->
                    <div (click)="dataCreateFeed()" class="data-items">
                        Custom feed
                    </div>
                    <!-- <div (click)="openUrl('https://ifttt.com/display_link')" class="data-items">
                        IFTTT
                    </div> -->
                </div>
                <div class="new-data-container" *ngIf="selectedType != ''">
                    <div>Settings:</div>
                    <div *ngIf="selectedType == 'rss'">
                        <input pInputText style="width: 25em" class="form-control" [(ngModel)]="settings.rss.url" placeholder="RSS URL">
                    </div>
                    <div *ngIf="selectedType == 'website'" style="display: flex; align-items: flex-start;flex-direction: column;">

                        <div class="tab-index">
                            <div class="tab-item" (click)="settings.webpage.type = 'screenshot'" [class.tab-item-selected]="settings.webpage.type === 'screenshot'">Webpage screenshot</div>
                            <div class="tab-item" (click)="settings.webpage.type = 'live'" [class.tab-item-selected]="settings.webpage.type === 'live'">Live webpage (Android only)</div>
                        </div>

                        <input pInputText style="width: 25em; margin-top: 1em;" class="form-control" [(ngModel)]="settings.webpage.url" placeholder="Website URL">

                        <div *ngIf="settings.webpage.type == 'live'">
                            <div class="url-list">
                                <div class="flex flex-col gap-2 url-settings">
                                    <div>
                                        <div style="align-self: center;">Reset timeout (seconds):</div>
                                        <input pInputText style="width: 5em" type="number" class="form-control" [(ngModel)]="settings.webpage.resetTimer">
                                    </div>
                                    <div class="flex flex-col">
                                        <div>Allowed URLs</div>
                                        <div class="flex flex-col gap-2">
                                            <div class="flex " *ngFor="let urlVal of settings.webpage.allowedUrls; let i = index">
                                                <input pInputText style="width: 25em" class="form-control" [value]="urlVal" placeholder="Allow urls *google.com* or * for any">
                                                <button pButton [text]="true" [plain]="true" (click)="removeURL(i)">
                                                <ng-icon name="saxTrashOutline" size="20"></ng-icon>
                                            </button>
                                            </div>
                                            <div class="flex gap-2">
                                                <input pInputText style="width: 25em" class="form-control" [(ngModel)]="settings.webpage.allowedURL" placeholder="Allow urls *google.com* or * for any">
                                                <p-button label="+ add" [outlined]="true" (click)="addURL()" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="selectedType == 'weather'" style="width: 100%;">
                        <input pInputText style="width: 25em" class="form-control" (keyup)="searchForLocations()" [(ngModel)]="weatherLocationSearchField" placeholder="Find location">
                        <table class="table table-dark mt-3 align-middle">
                            <thead>
                                <tr>
                                    <th scope="col">Location</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let location of weatherLocations">
                                    <td>{{location.country}} - {{location.name}} - {{location.region}}</td>
                                    <td>
                                        <div class="flex justify-end">
                                            <p-button *ngIf="location != settings.weather.location" label="select" (click)="selectLocation(location)" [outlined]="true" />
                                            <p-button *ngIf="location == settings.weather.location" label="selected" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="actions" *ngIf="data.dataClass == dataClasses.Data && selectedType !='' || data.dataClass != dataClasses.Data">
        <p-button label="Cancel" [outlined]="true" mat-dialog-close />
        <p-button label="Save" (click)="onSave()" />
    </div>
</div>