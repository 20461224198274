

<div *ngIf="getDataClass() == 'pixabay'" class="powered-pixabay" style="display: none;">
    <div>Powered by</div>
    <a href="https://pixabay.com/" style="margin: 3px 15px 5px 0;
    color: #555;
    display: block;
    float: left;
    padding: 9px 12px 6px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0.4em;" target="_blank" (click)="$event.stopPropagation()">
        <i style="display:block;width:68px;height:18px;overflow:hidden"><img crossorigin="anonymous" src="/assets/images/logo.svg" style="width:94px"></i> Free Images
    </a>
</div>

<p-progressbar mode="indeterminate" [style]="{ height: '6px' }" [style.opacity]="isLoadingResults ? 1 : 0" />
<div class="mat-elevation-z8" ngClass="{{getDataClass()}}">
    <div class="drive-container flex">
        <div class="drive-dir" *ngIf="getDataClass() != 'pixabay'">
            <div class="drive-header">
                <div><h2 class="typeTitle" *ngIf="getDataClass() != 'pixabay'">Drive</h2></div>
                <div>
                    <button class="create-button" *ngIf="getDataClass() != 'pixabay'" [text]="true" pButton (click)="create()">Create new<ng-icon name="saxAddOutline" size="20"></ng-icon></button>
                </div>
            </div>
            <app-folder-tree [editFolder]="true" [selectedId]="selectedFolderId" class="folder-tree" (onDataSelect)="onSelectFolder($event)"></app-folder-tree>
        </div>
        <div class="w-full">
            <div class="table-top-buttons" ngClass="{{getDataClass() == 'pixabay' ? 'pixabay-table-top-buttons' : ''}}">
                <p-iconfield iconPosition="left">
                    <span class="custom-icon">
                        <ng-icon name="saxSearchNormal1Outline" size="20"></ng-icon>
                    </span>
                    <input type="search" (focus)="searchFieldFocused = true" (blur)="searchFieldFocused = false" (keyup)="applyFilter($event)" fluid="false" styleClass="input-field search-field" class="input-field search-field" #input pInputText placeholder="Search" />
                </p-iconfield>
                <div class="buttons-right">
                    <div *ngIf="getDataClass() == 'pixabay'" class="pixabay-settings">
                        <select class="form-select" [(ngModel)]="pixabayFilter.imageType" (change)="applyFilter($event)">
                                <option *ngFor="let pixabaySetting of pixabaySettings.imageType" [value]="pixabaySetting">{{pixabaySetting == 'all' ? 'All types' : pixabaySetting}}</option>
                            </select>
                        <select class="form-select" [(ngModel)]="pixabayFilter.orientation" (change)="applyFilter($event)">
                                <option *ngFor="let pixabaySetting of pixabaySettings.orientation" [value]="pixabaySetting">{{pixabaySetting == 'all' ? 'All orientations' : pixabaySetting}}</option>
                            </select>
                        <select class="form-select" [(ngModel)]="pixabayFilter.category" (change)="applyFilter($event)">
                                <option *ngFor="let pixabaySetting of pixabaySettings.category" [value]="pixabaySetting">{{pixabaySetting ? pixabaySetting : 'Any categories'}}</option>
                            </select>
                        <select class="form-select" [(ngModel)]="pixabayFilter.colors" (change)="applyFilter($event)">
                                <option *ngFor="let pixabaySetting of pixabaySettings.colors" [value]="pixabaySetting">{{pixabaySetting ? pixabaySetting : 'Any colors'}}</option>
                            </select>
                    </div>
                    <div *ngIf="getDataClass() == 'data'" class="flex">
                        <button (click)="fileInput.click()" severity="secondary" pButton><ng-icon name="saxDocumentUploadOutline" size="20"></ng-icon>Upload Files</button>
                        <input type="file" #fileInput style="display: none;" (change)="commonService.onFilesSelected($event)" multiple />
                    </div>
                    <button *ngIf="getDataClass() == 'data'" (click)="showPixaBayImport = true;" severity="secondary" pButton><ng-icon name="saxGlobalSearchOutline" size="20"></ng-icon>Search PixaBay</button>
                    
                    <button *ngIf="dataSelect" pButton (click)="selectData()"><ng-icon name="saxAddOutline" size="20"></ng-icon>Add {{selection.selected.length}} items</button>


                    <p-popover class="popover-el" #op>
                        <div class="popover-buttons flex flex-col">
                            <button class="action-button" severity="secondary" pButton (click)="editSelected()"><ng-icon name="saxTextBlockOutline" size="20"></ng-icon>Rename</button>
                            <button class="action-button" severity="secondary" pButton (click)="moveSelected()"><ng-icon name="saxDocumentForwardOutline" size="20"></ng-icon>Move</button>
                            <button class="action-button" severity="secondary" pButton (click)="copySelected()"><ng-icon name="saxCopyOutline" size="20"></ng-icon>Copy</button>
                            <button class="action-button" severity="secondary" pButton (click)="deleteSelected()"><ng-icon name="saxTrashOutline" size="20"></ng-icon>Delete all</button>
                        </div>
                    </p-popover>
                    <button class="more-button" styleClass="more-button" pButton (click)="op.toggle($event)" severity="secondary" *ngIf="!dataSelect">
                        <ng-icon style="transform: rotate(90deg);" name="saxMoreOutline" size="25"></ng-icon>
                    </button>
                </div>
            </div>

            <div class="folder-file-container mt-4" cdkDropList cdkDropListOrientation="mixed" id="{{getDataClass() == 'pixabay' ? 'pixabayDropList' : 'driveDropList'}}" [cdkDropListData]="this.folderItems" [cdkDropListConnectedTo]="['playlistDropList']">
                <div cdkDrag [title]="item.name" [style.background-image]="item.selected_class.name == 'Image' || item.selected_class.name == 'Video' ? 'url(' + item.meta.url_thumb + ')' : ''" class="folder-file" *ngFor="let item of this.folderItems" [ngClass]="{'item-selected': selection.isSelected(item), 'isDir': item.selected_class.name == 'Folder'}"
                    (click)="onSelectItem(item)" (dblclick)="doubbleClick(item)">
                    <div class="data-icon" *ngIf="item.selected_class.name != 'Video' && item.selected_class.name != 'Image'">
                        <ng-icon *ngIf="item.selected_class.name == 'Folder'" name="saxFolderOutline" size="40"></ng-icon>
                        <ng-icon *ngIf="item.selected_class.name != 'Folder'" name="sax3squareOutline" size="40"></ng-icon>
                    </div>
                    <div class="data-icon" *ngIf="item.selected_class.name == 'Video' || item.selected_class.name == 'Image'"></div>
                    <div class="creating-screenshot-status" *ngIf="item.selected_class.name == 'Image' && !item.meta.url_thumb && item.feed">
                        Generating screenshot
                    </div>
                    <div class="optimizing-error" *ngIf="item.meta?.needToTranscode && item.meta?.transcodeStatus == 'job.failed'">Error optimizing video</div>
                    <div class="optimizing-status" *ngIf="item.meta?.needToTranscode && item.meta?.transcodeStatus != 'job.failed' && item.meta?.transcodeStatus != 'job.completed'">Optimizing video...</div>
                    <div class="name">
                        <div class="name-value">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex justify-end  mt-4">
        <p-paginator class="paginator" styleClass="paginator" [rows]="pageSize" [totalRecords]="resultsLength" (onPageChange)="onPageChange($event)">
        </p-paginator>
    </div>
</div>

<!-- edit coms-->
<div class="edit-container">
    <app-data-image (onDataChange)="onImageChange($event)" *ngIf="selected.image.isSelected" [data]="selected.image.item"></app-data-image>
    <app-data-video (onDataChange)="onVideoChange($event)" *ngIf="selected.video.isSelected" [data]="selected.video.item"></app-data-video>
    <app-data-feed (onDataChange)="onFeedChange($event)" *ngIf="selected.feed.isSelected" [data]="selected.feed.item"></app-data-feed>
    <app-data-webview (onDataChange)="onWebviewChange($event)" *ngIf="selected.webview.isSelected" [data]="selected.webview.item"></app-data-webview>
    <app-pixabay *ngIf="showPixaBayImport" [dataId]="selectedFolderId" (onCloseMedia)="onclosePixaBayImport($event)" [dataType]="[]"></app-pixabay>
</div>