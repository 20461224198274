import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Data } from 'src/app/api/models/account/data/data';
import { ApiService } from 'src/app/api/api.service';
import { DataChange, DataChangeEvent, DataRowChange } from 'src/app/models/data-change';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, startWith, switchMap, catchError, map, Observable, of as observableOf } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataColumn } from 'src/app/api/models/account/data/data-column';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-data-feed',
  templateUrl: './data-feed.component.html',
  styleUrls: ['./data-feed.component.scss']
})
export class DataFeedComponent implements AfterViewInit {
  columnsToDisplay: string[] = [];
  dataSource: any = [];
  selection = new SelectionModel<any>(true, []);

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  searchQuery: string = '';
  searchTimeout: any = null;
  columns: DataColumn[] = [];

  updateTimeout: any = null;

  tags: { value: string }[] = [];

  selected: { selected: boolean, data: any } = { selected: false, data: null };

  row: { element: any, index: number } = { element: null, index: 0 };

  isFeed: boolean = false;
  isFeedLink: boolean = false;
  isTab = 'feed';

  pageIndex: number = 0;
  pageSize: number = 25;
  gloableIndex: number = 0;

  public updateWatch: EventEmitter<any>;

  @Input() data!: Data;
  @Output() onDataChange = new EventEmitter<DataChange>();

  constructor(private apiService: ApiService, private authService: AuthService, public editorService: EditorService, private dialog: MatDialog, private messageService: MessageService) {
    this.updateWatch = new EventEmitter<any>();
  }

  selectMedia(type: string, element: any, id: number) {
    if (this.isFeed) return;
    if (type == 'Image') {
      this.editorService.overlay.driveTypes = ['Image'];
    } else if (type == 'Video') {
      this.editorService.overlay.driveTypes = ['Video'];
    }
    this.editorService.overlay.drive = true;
    this.row.element = element;
    this.row.index = id;
  }

  refreshColums() {
    this.apiService.getColumns(this.authService.selectedAccountId, this.data.id).subscribe((dataColums: DataColumn[]) => {
      this.columns = [];
      for (let i = 0; i < dataColums.length; i++) {
        dataColums[i].index = 'column' + i+this.gloableIndex;
        this.columns.push(dataColums[i]);
      }
      this.gloableIndex++;
      this.columns.unshift({
        index: 'select',
        name: 'Select',
        fieldClass: '',
        fieldName: '',
        order: 0,
      });
      this.columns.push({
        index: 'actions',
        name: 'Actions',
        fieldClass: '',
        fieldName: '',
        order: 0,
      });

      this.columnsToDisplay = this.columns.map((column: DataColumn) => column.index).slice();

    });
  }

  onItemChange(event: any, element: any, i: any) {

    element.data[i] = event.target.innerText;
    //remove the last 2 items
    // element.data.pop();
    // element.data.pop();

    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      this.apiService.updateRow(this.authService.selectedAccountId, this.data.id, element).subscribe((data: any) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated' });
      });
    }, 500);
  }

  trackByData(index: number, item: any): any {
    return item._id;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    let orderNumbers: number[] = [];
    this.dataSource.forEach((element: any, index: number) => {
      orderNumbers.push(element.order);
    });
    orderNumbers = orderNumbers.sort((a: number, b: number) => b - a);
    this.dataSource.forEach((element: any, index: number) => {
      element.order = orderNumbers[index];
    });
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      this.apiService.updateRowOrder(this.authService.selectedAccountId, this.data.id, this.dataSource).subscribe((data: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated' });
      });
    }, 500);
    this.dataSource = [...this.dataSource];
  }


  ngAfterViewInit() {
    if (this.data != null && this.data.feed != null || this.data != null && this.data.feed_link != null) {
      this.isFeed = true;
      if (this.data.feed_link != null) {
        this.isFeedLink = true;
      } else if (this.data.feed.meta.tags) {
        for (let i = 0; i < this.data.feed.meta.tags.length; i++) {
          this.tags.push({ value: this.data.feed.meta.tags[i] });
        }
      }
    }

    if (this.data.meta && this.data.meta.tags) {
      for (let i = 0; i < this.data.meta.tags.length; i++) {
        this.tags.push({ value: this.data.meta.tags[i] });
      }
    }

    this.refreshColums();

    merge(this.updateWatch)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.selection.clear();
          this.isLoadingResults = true;
          return this.doRequest().pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;

          data.results.map((item: any, index: number) => {
            item = this.modifyData(item);
          });

          data.results.map((item: any, index: number) => {
            item.select = false;
          });

          return data.results;
        }),
      )
      .subscribe(data => (this.dataSource = data));
  }

  onPageChange(event: any) {
    this.pageIndex = event.page;
    this.pageSize = event.rows;
    this.updateWatch.emit({});
  }

  doRequest(): Observable<any> {
    return this.apiService.findRows(this.authService.selectedAccountId, this.data.id, {
      query: this.searchQuery,
      limit: this.pageSize,
      start: this.pageIndex * this.pageSize,
      order: 'DESC',
      orderField: 'order'
    });
  }

  applyFilter(event: Event) {
    this.searchTimeout && clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const filterValue = (event.target as HTMLInputElement).value;
      this.searchQuery = filterValue.trim().toLowerCase();
      this.updateWatch.emit({});
    }, 500);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  columnNames(name: string) {

    return name;
  }

  columnFilter(colums: string[]): string[] {
    return colums;
  }

  modifyData(data: any) {

  }

  columnOrder(colums: string[]): string[] {


    return colums;
  }

  /*
  * Actions
  */
  create() {
    let row: any = [];
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].index != 'select' && this.columns[i].index != 'actions') {
        row.push('test ' + i);
      }
    }
    this.apiService.createRow(this.authService.selectedAccountId, this.data.id, {
      data: row,
      order: this.resultsLength + 1
    }).subscribe((data: any) => {
      this.updateWatch.emit({});
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Created' });
    });
  }

  edit() {
    this.selected.selected = true;
  }

  delete(row: any) {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Delete item',
        message: 'Are you sure you want to delete this Item?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.deleteRow(this.authService.selectedAccountId, this.data.id, row._id).subscribe((data: any) => {
          this.updateWatch.emit({});
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted' });
        });
      }
    });
  }

  deleteSelected() {
    this.dialog.open(PopupDialog, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Delete item',
        message: 'Are you sure you want to delete this Item?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.selection.selected.forEach(async (row: any) => {
          await this.apiService.deleteRow(this.authService.selectedAccountId, this.data.id, row._id).toPromise();
        });
        this.updateWatch.emit({});
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted' });
      }
    });
  }

  onEdit(row: DataRowChange) {
    this.refreshColums();
    this.updateWatch.emit({});
    if (row.dataChangeEvent == DataChangeEvent.onSave) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        this.apiService.updateRow(this.authService.selectedAccountId, this.data.id, row.dataRow).subscribe((data: any) => {
          this.updateWatch.emit({});
          this.selected.selected = false;
        });
      }, 500);
    } else if (row.dataChangeEvent == DataChangeEvent.onCancel) {
      this.selected.selected = false;
    }
  }

  close() {
    this.onDataChange.emit({
      dataChangeEvent: DataChangeEvent.onCancel,
      data: this.data
    });
  }

  onSelectMedia(data: Data) {
    this.editorService.overlay.drive = false;
    this.row.element.data[this.row.index] = data;
    this.apiService.updateRow(this.authService.selectedAccountId, this.data.id, this.row.element).subscribe((data: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated' });
    });
  }

  onCloseMedia() {
    this.editorService.overlay.drive = false;
  }

  getLastUpdateHuman(datetime) {
    let date = new Date(datetime);
    let now = new Date();
    let seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    //convert to human readable
    if (seconds > 31536000) {
      return Math.floor(seconds / 31536000) + ' years ago';
    } else if (seconds > 2419200) {
      return Math.floor(seconds / 2419200) + ' months ago';
    } else if (seconds > 604800) {
      return Math.floor(seconds / 604800) + ' weeks ago';
    } else if (seconds > 86400) {
      return Math.floor(seconds / 86400) + ' days ago';
    } else if (seconds > 3600) {
      return Math.floor(seconds / 3600) + ' hours ago';
    } else if (seconds > 60) {
      return Math.floor(seconds / 60) + ' minutes ago';
    }
    return 'just now';
  }

  save() {
    if (this.isFeed && !this.isFeedLink && this.data.feed) {
      this.data.feed.meta.tags = [];
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].value != '')
          this.data.feed.meta.tags.push(this.tags[i].value);
      }
    } else {
      if (Array.isArray(this.data.meta)) {
        this.data.meta = {};
      }
      this.data.meta.tags = [];
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].value != '')
          this.data.meta.tags.push(this.tags[i].value);
      }
    }

    this.apiService.updateFeed(this.authService.selectedAccountId, this.data).subscribe(
      data => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated' });
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
      }
    );
  }

  addTag() {
    this.tags.push({ value: '' });
  }
}
