import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableComponent } from '../../components/data-table/data-table.component';
import { DataFolderComponent } from '../../components/data-folder/data-folder.component';
import { DataImageComponent } from '../../components/data/data-image/data-image.component';
import { DataVideoComponent } from '../../components/data/data-video/data-video.component';
import { DataFeedComponent } from '../../components/data/data-feed/data-feed.component';
import { DataFeedEditComponent } from '../../components/data/data-feed-edit/data-feed-edit.component';
import { EditComponent } from '../../components/edit/edit.component';
import { NewComponent } from '../../components/new/new.component';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { InputfieldComponent } from '../../components/inputfield/inputfield.component';
import { PlaylistComponent } from '../../components/playlist/playlist.component';
import { DataSelectorComponent } from '../../components/playlist/data-selector/data-selector.component';
import { DesignSelectorComponent } from '../../components/playlist/design-selector/design-selector.component';
import { DeviceInfoComponent } from '../../components/device-info/device-info.component';
import { ConnectDeviceComponent } from '../../components/connect-device/connect-device.component';
import { DisplaySelectorComponent } from '../../components/playlist/display-selector/display-selector.component';
import { ByteThisNgxGoogleFontsModule } from '@tdonselaar/ngx-google-fonts2';
import { TextStyleEditorComponent } from '../../components/text-style-editor/text-style-editor.component';
import { RouterModule } from '@angular/router';
import { PartnerListComponent } from '../../components/partner-list/partner-list.component';
import { AccountListComponent } from '../../components/account-list/account-list.component';
import { MediaSelectComponent } from '../../account/editor/components/media-select/media-select.component';
import { MoveDataComponent } from '../../components/move-data/move-data.component';
import { FolderTreeComponent } from '../../components/folder-tree/folder-tree.component';
import { WebDeviceComponent } from '../../components/web-device/web-device.component';
import { PixabayComponent } from '../../components/pixabay/pixabay.component';
import { AccountsComponent } from '../../partner/components/accounts/accounts.component';
import { SettingsComponent } from '../../partner/components/settings/settings.component';
import { AccountSettingsComponent } from '../../partner/components/account-settings/account-settings.component';
import { UploadComponent } from '../../components/upload/upload.component';
import { DataWebviewComponent } from '../../components/data/data-webview/data-webview.component';
import { UserProfileComponent } from '../../components/user-profile/user-profile.component';
import { CopyDataComponent } from '../../components/copy-data/copy-data.component';
import { CopyDesignComponent } from '../../components/copy-design/copy-design.component';
import { MoveDisplayComponent } from '../../components/move-display/move-display.component';
import { OnboardingComponent } from '../../components/onboarding/onboarding.component';
import { PopupDialog } from '../../popups/confirm/dialog';
import { PlaylistAccountComponent } from '../../account/components/playlist/playlist.component';
import { AnimationEditorComponent } from '../../components/playlist/animation-editor/animation-editor.component';

//material design
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';

//others
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxChartsModule } from '@swimlane/ngx-charts';

//primeNG
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { PopoverModule } from 'primeng/popover';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AutoFocusModule } from 'primeng/autofocus';
import { TreeModule } from 'primeng/tree';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputOtpModule } from 'primeng/inputotp';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipModule } from 'primeng/chip';

//icons
import { NgIconsModule } from '@ng-icons/core';
import { 
  saxPlayCircleOutline, 
  saxFolder2Outline, 
  saxPathSquareOutline,
  saxMonitorMobbileOutline,
  saxSetting2Outline,
  saxProfileCircleOutline,
  saxArrowUp2Outline,
  saxArrowDown1Outline,
  saxKeyOutline,
  saxPeopleOutline,
  saxLogoutOutline,
  saxCpuOutline,
  saxAddOutline,
  saxSearchNormal1Outline,
  saxMoreOutline,
  saxTrashOutline,
  saxCopyOutline,
  saxDocumentForwardOutline,
  saxFolderOutline,
  sax3squareOutline,
  saxVideoPlayOutline,
  saxImageOutline,
  saxGlobalSearchOutline,
  saxDocumentUploadOutline,
  saxEditOutline,
  saxEdit2Outline,
  saxMinusOutline,
  saxArrowRight3Outline,
  saxFolderOpenOutline,
  saxTextBlockOutline,
  saxFatrowsOutline,
  saxArrowDown2Outline,
  saxTickCircleOutline,
  saxAdditemOutline,
  saxMenu1Outline,
  saxArrowLeft2Outline,
  saxUndoOutline,
  saxRedoOutline,
  saxTextalignJustifyleftOutline,
  saxTextalignCenterOutline,
  saxTextalignRightOutline
 } from '@ng-icons/iconsax/outline';


@NgModule({
  declarations: [
    DataTableComponent,
    DataFolderComponent,
    DataImageComponent,
    DataVideoComponent,
    DataFeedComponent,
    DataFeedEditComponent,
    EditComponent,
    NewComponent,
    BreadcrumbsComponent,
    InputfieldComponent,
    PlaylistComponent,
    DataSelectorComponent,
    DesignSelectorComponent,
    DeviceInfoComponent,
    ConnectDeviceComponent,
    DisplaySelectorComponent,
    TextStyleEditorComponent,
    PartnerListComponent,
    AccountListComponent,
    MediaSelectComponent,
    MoveDataComponent,
    FolderTreeComponent,
    WebDeviceComponent,
    PixabayComponent,
    AccountsComponent,
    SettingsComponent,
    AccountSettingsComponent,
    UploadComponent,
    DataWebviewComponent,
    UserProfileComponent,
    CopyDataComponent,
    CopyDesignComponent,
    MoveDisplayComponent,
    OnboardingComponent,
    PopupDialog,
    PlaylistAccountComponent,
    AnimationEditorComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    ByteThisNgxGoogleFontsModule,
    RouterModule,
    //material design
    MatProgressBarModule,
    MatPaginatorModule,
    MatIconModule,
    MatTreeModule,
    MatTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSliderModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    //primeNG
    ConfirmDialogModule,
    DialogModule,
    DynamicDialogModule,
    MenuModule,
    DividerModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    TableModule,
    InputGroupModule,
    InputGroupAddonModule,
    ProgressBarModule,
    CheckboxModule,
    PaginatorModule,
    PopoverModule,
    IconFieldModule,
    InputIconModule,
    SelectButtonModule,
    AutoFocusModule,
    TreeModule,
    FloatLabelModule,
    InputOtpModule,
    MultiSelectModule,
    ChipModule,
    //others
    ColorPickerModule,
    NgxChartsModule,
    //icons
    NgIconsModule,
    NgIconsModule.withIcons({
      saxPlayCircleOutline, 
      saxFolder2Outline, 
      saxPathSquareOutline,
      saxMonitorMobbileOutline,
      saxSetting2Outline,
      saxProfileCircleOutline,
      saxArrowUp2Outline,
      saxArrowDown1Outline,
      saxKeyOutline,
      saxPeopleOutline,
      saxLogoutOutline,
      saxCpuOutline,
      saxAddOutline,
      saxSearchNormal1Outline,
      saxMoreOutline,
      saxTrashOutline,
      saxCopyOutline,
      saxDocumentForwardOutline,
      saxFolderOutline,
      sax3squareOutline,
      saxVideoPlayOutline,
      saxImageOutline,
      saxGlobalSearchOutline,
      saxDocumentUploadOutline,
      saxEditOutline,
      saxEdit2Outline,
      saxMinusOutline,
      saxArrowRight3Outline,
      saxFolderOpenOutline,
      saxTextBlockOutline,
      saxFatrowsOutline,
      saxArrowDown2Outline,
      saxTickCircleOutline,
      saxAdditemOutline,
      saxMenu1Outline,
      saxArrowLeft2Outline,
      saxUndoOutline,
      saxRedoOutline,
      saxTextalignJustifyleftOutline,
      saxTextalignCenterOutline,
      saxTextalignRightOutline
    }),
  ],
  exports: [
    UserProfileComponent,
    DisplaySelectorComponent,
    ConnectDeviceComponent,
    DeviceInfoComponent,
    DataTableComponent,
    DataFolderComponent,
    DataImageComponent,
    DataVideoComponent,
    DataFeedComponent,
    DataFeedEditComponent,
    WebDeviceComponent,
    EditComponent,
    NewComponent,
    PlaylistComponent,
    BreadcrumbsComponent,
    InputfieldComponent,
    DataSelectorComponent,
    DesignSelectorComponent,
    TextStyleEditorComponent,
    MediaSelectComponent,
    MoveDataComponent,
    FolderTreeComponent,
    PixabayComponent,
    PlaylistAccountComponent,
    PopupDialog,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    ByteThisNgxGoogleFontsModule,
    RouterModule,
    PartnerListComponent,
    AccountListComponent,
    AccountsComponent,
    SettingsComponent,
    AccountSettingsComponent,
    UploadComponent,
    OnboardingComponent,
    AnimationEditorComponent,
    //material design
    MatProgressBarModule,
    MatPaginatorModule,
    MatIconModule,
    MatTreeModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSliderModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    //primeNG
    PopoverModule,
    ConfirmDialogModule,
    DialogModule,
    DynamicDialogModule,
    MenuModule,
    DividerModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    TableModule,
    InputGroupModule,
    InputGroupAddonModule,
    ProgressBarModule,
    CheckboxModule,
    IconFieldModule,
    InputIconModule,
    SelectButtonModule,
    AutoFocusModule,
    TreeModule,
    FloatLabelModule,
    MultiSelectModule,
    ChipModule,
    //others
    ColorPickerModule,
    NgIconsModule
  ],
  providers: [

  ]
})
export class SharedModule { }
