import { StackNode } from "./stack-node";
export class Stack {
  constructor() {
    this.head = null;
    this._length = 0;
  }
  get count() {
    return this._length;
  }
  push(item) {
    const stackNode = new StackNode(item);
    stackNode.next = this.head;
    this.head = stackNode;
    this._length++;
  }
  peek() {
    var _a;
    return ((_a = this.head) === null || _a === void 0 ? void 0 : _a.value) || null;
  }
  pop() {
    if (this.head === null) {
      return null;
    }
    this._length--;
    const nodeValue = this.head.value;
    this.head = this.head.next;
    return nodeValue;
  }
}